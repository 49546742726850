import { HttpRequest } from '@angular/common/http';
import { JWT_OPTIONS, JwtModule, JwtModuleOptions } from '@auth0/angular-jwt';

import { pomJwtTokenStorageKey, jwtTokenV2StorageKey } from './constants/constants';
import { JwtStorageService } from '@core/authentication/jwt/jwt-storage.service';

export function jwtOptionsFactory(jwtStorageService: JwtStorageService) {
    return {
        tokenGetter: (request: HttpRequest<any>): string => {
            try {
                if (request.url.indexOf('equity-bff') !== -1 || request.url.indexOf('client-logging') !== -1) {
                    return jwtStorageService.get(jwtTokenV2StorageKey);
                }

                return JSON.parse(jwtStorageService.get(pomJwtTokenStorageKey));
            } catch (error) {
                return null;
            }
        },
        allowedDomains: ['localhost:4200'],
        disallowedRoutes: ['localhost:4200/auth/']
    }
}

export const JWT_MODULE_CONFIG: JwtModuleOptions = {
    jwtOptionsProvider: {
        provide: JWT_OPTIONS,
        useFactory: jwtOptionsFactory,
        deps: [JwtStorageService]
    }
};

export const JWT_MODULE_CONFIGURED = JwtModule.forRoot(JWT_MODULE_CONFIG);
