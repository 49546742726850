import { Injectable } from '@angular/core';
import { Permissions } from '@core/permissions/permissions.model';
import { Store } from '@ngrx/store';
import * as fromRoot from '../../../../reducers';
import { WebsocketBaseListener } from './websocket-base-listener';
import { BspConfiguration } from '../../../configuration/bsp-configuration';
import { BspWebsocketGatewayService } from '@core/websockets/bsp-websocket-gateway.service';

@Injectable({providedIn: 'root'})
export class WebsocketLiveUpdateListener extends WebsocketBaseListener {

    constructor(
        websocketGatewayService: BspWebsocketGatewayService,
        store: Store<fromRoot.State>
    ) {
        super(websocketGatewayService, store);
    }

    protected getChannelName(configuration: BspConfiguration): string {
        return configuration.fixedIncomeLiveUpdateChannelName;
    }

    protected isPermitted(permissions: Permissions): boolean {
        return permissions.canViewFixedIncomeDealMonitor
            || permissions.canViewOrders
            || permissions.canViewActivityStream;
    }

    protected onUpdate(payload: any): void {
        console.log('[Live Update Received] Fixed Income', payload);

        this.store.dispatch({
           type: 'Live update received',
           payload
        });
    }
}
