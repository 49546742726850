<div class="dynamic-dropdown" [attr.data-tooltip]="value === '' ? OE_REQUIRED_MSG : null" [ngClass]="{
        'loading--not-loaded': loadingStatus === LoadingStatus.NotLoaded,
        'loading--in-progress': loadingStatus === LoadingStatus.Loading,
        'loading--success': loadingStatus === LoadingStatus.Success,
        'loading--failed': loadingStatus === LoadingStatus.Failed
    }">
    <select class="c-input" [disabled]="loadingStatus !== LoadingStatus.Success"
        [ngClass]="{'c-bd-danger-8': value === ''}" [ngModel]="value" (ngModelChange)="change.next($event)">
        <option class="c-bg-white" [ngValue]="null" hidden></option>
        <option *ngFor="let option of options; trackBy: trackBy" [value]="option.value">
            {{ option.text }}
        </option>
    </select>
    <div class="c-full-page-loader"></div>
    <span class="load-failed-icon">
        <i class="fa fa-exclamation-circle c-text-danger"></i>
    </span>
</div>