import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { Observable } from 'rxjs';
import { UserSettingsHttpModel } from '../models/user-settings-http.model';
import { API } from '@core/constants/api';
import { OperationalEntityHttpModel } from '../models/fixedincome-user-settings.model';
import { noErrorHandlingForHeader } from '@core/constants/http-headers';
import { map } from 'rxjs/operators';
import { excludeViewOnlyOperationalEntities } from './operational-entity-helper.service';

@Injectable({
    providedIn: 'root'
})
export class HttpUserSettingsPageService {

    constructor(
        private http: HttpClient
    ) { }

    public getUserSettings(): Observable<UserSettingsHttpModel> {
        return <any>this.http.get(API.userSettings.settings);
    }

    public updateUserSetting(userSettings: UserSettingsHttpModel, settingName: string, fieldName: string, value: any): Observable<any> {
        const payload = this.mapUserSettingsPayload(userSettings, settingName, fieldName, value);

        return this.http.put(API.userSettings.settings + '/' + settingName, payload);
    }

    public updateUserSettingShared(settingName: string, fieldName: string, value: any): Observable<any> {
        const payload = { [fieldName]: value };
        return this.http.put(API.userSettings.settings + '/' + settingName, payload);
    }

    public getOperationalEntities(baseUrl: string): Observable<OperationalEntityHttpModel[]> {
        const headers = new HttpHeaders({
            [noErrorHandlingForHeader]: '*'
        });

        return this.http.get<OperationalEntityHttpModel[]>(`${baseUrl}/v1/operational-entity/user-operational-entities`, { headers })
            .pipe(map(excludeViewOnlyOperationalEntities));
    }

    private mapUserSettingsPayload(userSettings: UserSettingsHttpModel, settingName: string, fieldName: string, value: any): any {
        const groupedSettings = [
            'app_dealMonitor1RequestSettings',
            'app_emailNotificationsMessageTypes',
            'app_desktopNotificationsMessageTypes',
            'app_emailBookStateNotificationsTypes',
            'app_desktopBookStateNotificationsTypes',
            'app_dealDetailsNotificationTypes',
            'app_dealDetailsEmailNotificationTypes',
            'app_orderEmailNotificationTypes',
            'app_orderNotificationTypes',
            'app_tradingNotificationTypes',
            'app_userSettings'
        ];

        if (groupedSettings.includes(settingName)) {
            return userSettings[settingName];
        } else {
            return { [fieldName]: value };
        }
    }

}
