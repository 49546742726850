import { Injectable } from '@angular/core';
import { Permissions } from '@core/permissions/permissions.model';
import { Store } from '@ngrx/store';
import * as fromRoot from '../../../../reducers';
import { WebsocketBaseListener } from './websocket-base-listener';
import { BspConfiguration } from '@core/configuration/bsp-configuration';
import { LoggerActions } from '@core/@state/actions';
import { ClientLogLevel } from '@core/logger/log.model';
import { BspWebsocketGatewayService } from '@core/websockets/bsp-websocket-gateway.service';

@Injectable({providedIn: 'root'})
export class WebsocketTestMessageListener extends WebsocketBaseListener {

    constructor(
        websocketGatewayService: BspWebsocketGatewayService,
        store: Store<fromRoot.State>
    ) {
        super(websocketGatewayService, store);
    }

    protected getChannelName(configuration: BspConfiguration): string {
        return configuration.pingMessageChannelName;
    }

    protected isPermitted(permissions: Permissions): boolean {
        return true;
    }

    protected onUpdate(payload: any): void {
        console.log('[Test Live Update Received]', payload);

        this.store.dispatch(new LoggerActions.LogMessages(
            {
                logs: [{
                    entryDate: new Date(),
                    message: `[WEBSOCKET] Test Live Update Received`,
                    level: ClientLogLevel.Info,
                    extraInfo: payload
                }],
                addExtraInfo: true
            })
        );
    }
}
