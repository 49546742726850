import {
    MuniUserSettingHttpModel,
    SettingType,
} from 'src/app/modules/user-settings/models/muni-user-settings-http.model';
import {
    MuniUserSettingsModel,
    MuniUserSettingModel,
    BenchmarkCurve,
    FlexColumn,
    DealManagerViewSettingModel,
    DealManagerView,
    CalendarRatingsView,
} from 'src/app/modules/user-settings/models/muni-user-settings.model';
import { MuniUserSettings, MuniUserSetting } from './muni-user-settings.model';

export class MuniUserSettingsHelper {
    public static createMuniSettingsModel(
        muniUserSettings: MuniUserSettingHttpModel[],
        hasMmdDataPermission: boolean
    ): MuniUserSettingsModel {
        const defaultSettings = MuniUserSettingsHelper.getDefaultMuniUserSettings(
            hasMmdDataPermission
        );

        return <MuniUserSettingsModel>{
            defaultMarginalTaxRate:
                MuniUserSettingsHelper.getMuniUserSetting(
                    muniUserSettings,
                    'defaultMarginalTaxRate'
                ) || defaultSettings.defaultMarginalTaxRate,

            defaultBenchmarkCurve:
                MuniUserSettingsHelper.getMuniUserSetting(
                    muniUserSettings,
                    'defaultBenchmarkCurve'
                ) || defaultSettings.defaultBenchmarkCurve,

            defaultFlexColumn:
                MuniUserSettingsHelper.getMuniUserSetting(
                    muniUserSettings,
                    'defaultFlexColumn'
                ) || defaultSettings.defaultFlexColumn,

            defaultCalendarFollowAssignmentShortcut:
                MuniUserSettingsHelper.getMuniUserSetting(
                    muniUserSettings,
                    'defaultCalendarFollowAssignmentShortcut'
                ) || defaultSettings.defaultCalendarFollowAssignmentShortcut,
            defaultDealManagerView:
                MuniUserSettingsHelper.getMuniUserSetting(
                    muniUserSettings,
                    'defaultDealManagerView'
                ) || defaultSettings.defaultDealManagerView,
            defaultCalendarRatingsView:
                MuniUserSettingsHelper.getMuniUserSetting(
                    muniUserSettings,
                    'defaultCalendarRatingsView'
                ) || defaultSettings.defaultCalendarRatingsView
        };
    }

    public static buildMuniSettings(
        muniUserSettings: MuniUserSettingHttpModel[],
        hasMmdDataPermission: boolean
    ): MuniUserSettings {
        const defaultUserSettings = MuniUserSettingsHelper.buildDefaultMuniSettings(
            hasMmdDataPermission
        );
        const userSettings = MuniUserSettingsHelper.filterBySettingType(
            muniUserSettings,
            SettingType.UserSetting
        );

        const muniSettings = defaultUserSettings.map((setting) => {
            const settingIndex = userSettings.findIndex(
                (s) => s.userPreference.name === setting.userPreference.name
            );

            return settingIndex === -1 ? setting : userSettings[settingIndex];
        });

        return <MuniUserSettings>{
            settings: muniSettings,
            filters: MuniUserSettingsHelper.filterBySettingType(
                muniUserSettings,
                SettingType.Filter
            ),
        };
    }

    public static updateMuniSettings(
        muniUserSettings: MuniUserSetting[],
        setting: MuniUserSettingHttpModel
    ): MuniUserSetting[] {
        const updatedSettingIndex = muniUserSettings.findIndex(
            (s) => s.userPreference.name === setting.userPreference.name
        );
        const updatedSetting = MuniUserSettingsHelper.createMuniUserSetting(
            setting
        );
        muniUserSettings = Object.assign([], muniUserSettings);

        muniUserSettings[updatedSettingIndex] = updatedSetting;

        return muniUserSettings;
    }

    private static filterBySettingType(
        muniUserSettings: MuniUserSettingHttpModel[],
        settingType: SettingType
    ): MuniUserSetting[] {
        return muniUserSettings
            .filter((s) => s.settingType === settingType)
            .map((setting) =>
                MuniUserSettingsHelper.createMuniUserSetting(setting)
            );
    }

    private static getMuniUserSetting<T>(
        muniUserSettings: MuniUserSettingHttpModel[],
        name: string
    ): MuniUserSettingModel<T> {
        return muniUserSettings
            .filter(
                (s) =>
                    s.settingType === SettingType.UserSetting &&
                    s.userPreference.name === name
            )
            .map(
                (setting) =>
                    <MuniUserSettingModel<T>>{
                        id: setting.id,
                        value: <T>setting.userPreference.value,
                    }
            )[0];
    }

    private static createMuniUserSetting(
        setting: MuniUserSettingHttpModel
    ): MuniUserSetting {
        return <MuniUserSetting>{
            id: setting.id,
            userPreference: setting.userPreference,
        };
    }

    private static buildDefaultMuniSettings(
        hasMmdDataPermission: boolean
    ): MuniUserSetting[] {
        const defaultSettings = MuniUserSettingsHelper.getDefaultMuniUserSettings(
            hasMmdDataPermission
        );

        return [
            MuniUserSettingsHelper.map(
                defaultSettings.defaultMarginalTaxRate,
                'defaultMarginalTaxRate'
            ),
            MuniUserSettingsHelper.map(
                defaultSettings.defaultBenchmarkCurve,
                'defaultBenchmarkCurve'
            ),
            MuniUserSettingsHelper.map(
                defaultSettings.defaultFlexColumn,
                'defaultFlexColumn'
            ),
            MuniUserSettingsHelper.map(
                defaultSettings.defaultCalendarFollowAssignmentShortcut,
                'defaultCalendarFollowAssignmentShortcut'
            ),
            MuniUserSettingsHelper.map(
                defaultSettings.defaultDealManagerView,
                'defaultDealManagerView'
            ),
            MuniUserSettingsHelper.map(
                defaultSettings.defaultCalendarRatingsView,
                'defaultCalendarRatingsView'
            )
        ];
    }

    private static map<T>(
        model: MuniUserSettingModel<T>,
        name: string
    ): MuniUserSetting {
        return <MuniUserSetting>{
            id: model.id,
            userPreference: {
                name,
                value: model.value,
            },
        };
    }

    private static getDefaultMuniUserSettings(hasMmdDataPermission: boolean): MuniUserSettingsModel {
        return <MuniUserSettingsModel>
            {
                defaultMarginalTaxRate: <MuniUserSettingModel<number>>{
                    id: '', value: 35
                },
                defaultBenchmarkCurve: <MuniUserSettingModel<BenchmarkCurve>>{
                    id: '',
                    value: hasMmdDataPermission ?
                        BenchmarkCurve.MmdInterpolated :
                        BenchmarkCurve.MacInterpolated
                },
                defaultFlexColumn: <MuniUserSettingModel<FlexColumn>>{
                    id: '', value: FlexColumn.Documents
                },
                defaultCalendarFollowAssignmentShortcut: <MuniUserSettingModel<boolean>>{ id: '', value: true },
                defaultDealManagerView: <MuniUserSettingModel<DealManagerViewSettingModel>>{
                    id: '',
                    value: <DealManagerViewSettingModel>{
                        type: DealManagerView.Structure,
                        options: { trackingOptions: false, assignments: false }
                    }
                },
                defaultCalendarRatingsView: <MuniUserSettingModel<CalendarRatingsView>>{
                    id: '', value: CalendarRatingsView.Deal
                },
            };
    }
}
