import { LoggerActions } from '../actions';
import { Action, createFeatureSelector, UPDATE } from '@ngrx/store';

export interface State {
    ngrxActions: string[];
    elementsClicked: string[];
    httpRequests: string[];
    authInfo: {
        loggedInAt: Date,
        type: 'Logged In' | 'Session Restored'
    };
}

export const initialState: State = {
    ngrxActions: [],
    elementsClicked: [],
    httpRequests: [],
    authInfo: {
        loggedInAt: null,
        type: null
    }
};

const MAX_NGRX_ACTIONS_COUNT = 25;
const MAX_CLIENT_ACTIONS_COUNT = 5;
const MAX_HTTP_REQUESTS_COUNT = 5;

export function logsReducer(
    state: State = initialState,
    action: LoggerActions.LogActionsUnion
): State {
    switch (action.type) {
        case LoggerActions.LogActionTypes.AddElementClicked:
            return {
                ...state,
                elementsClicked: addLogEntry(state.elementsClicked, action.payload.elementClicked, MAX_CLIENT_ACTIONS_COUNT)
            };

        case LoggerActions.LogActionTypes.AddLoggedInTimestampAction:
            return {
                ...state,
                authInfo: action.payload,
                ngrxActions: addLogEntry(state.ngrxActions, action.type, MAX_NGRX_ACTIONS_COUNT)
            };

        case LoggerActions.LogActionTypes.AddHttpResponseStatus:
            return {
                ...state,
                httpRequests: addLogEntry(state.httpRequests, action.payload.path, MAX_HTTP_REQUESTS_COUNT)
            };

        case UPDATE as any:
            return state;


        default:
            return {
                ...state,
                ngrxActions: addLogEntry(state.ngrxActions, (action as Action).type, MAX_NGRX_ACTIONS_COUNT)
            };
    }
}

function addLogEntry(entries: string[], newEntry: string, maxLogEntriesCount: number): string[] {
    const newEntryWithTimeStamp = `${newEntry} | ${new Date().toISOString()}`;

    if (entries.length >= maxLogEntriesCount) {
        const result = [...entries];
        result.shift();
        result.push(newEntryWithTimeStamp);

        return result;
    }

    return [...entries, newEntryWithTimeStamp];
}

export const getLogsState = createFeatureSelector<State>('logs');
