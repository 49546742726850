import { Action } from '@ngrx/store';
import { EquityUserSettingsModel } from '../../../modules/user-settings/models/equity-user-settings.model';
import { MuniUserSettingHttpModel } from '../../../modules/user-settings/models/muni-user-settings-http.model';
import { UserSettingsHttpModel } from '../../../modules/user-settings/models/user-settings-http.model';
import { OperationalEntityHttpModel } from '../../../modules/user-settings/models/fixedincome-user-settings.model';
import { EquitySettingType } from '@core/user-settings/equity-user-settings-mapper.service';

export enum UserSettingsPageActions {
    FixedIncomeLoad          = '[UserSettings FixedIncomeUserSettingsPage] Load',
    FixedIncomeLoadSuccess   = '[UserSettings FixedIncomeUserSettingsPage] Load Success',
    FixedIncomeLoadFailed    = '[UserSettings FixedIncomeUserSettingsPage] Load Failed',
    FixedIncomeUpdate        = '[UserSettings FixedIncomeUserSettingsPage] Update Setting',
    FixedIncomeUpdateFailed  = '[UserSettings FixedIncomeUserSettingsPage] Update Setting Failed',
    FixedIncomeOeLoad        = '[UserSettings FixedIncomeUserSettingsPage] Load Operational Entities',
    FixedIncomeOeLoadSuccess = '[UserSettings FixedIncomeUserSettingsPage] Load Operational Entities Success',
    FixedIncomeOeLoadFailed  = '[UserSettings FixedIncomeUserSettingsPage] Load Operational Entities Failed',

    EquityLoad               = '[UserSettings EquityUserSettingsPage] Load',
    EquityLoadSuccess        = '[UserSettings EquityUserSettingsPage] Load Success',
    EquityLoadFailed         = '[UserSettings EquityUserSettingsPage] Load Failed',
    EquityUpdate             = '[UserSettings EquityUserSettingsPage] Update Setting',
    EquityHomePageUpdate     = '[UserSettings EquityUserSettingsPage] Update HomePage Setting',
    EquityUpdateSuccess      = '[UserSettings EquityUserSettingsPage] Update Setting Success',
    EquityUpdateFailed       = '[UserSettings EquityUserSettingsPage] Update Setting Failed',

    MuniLoad                 = '[UserSettings MuniUserSettingsPage] Load',
    MuniLoadSuccess          = '[UserSettings MuniUserSettingsPage] Load Success',
    MuniLoadFailed           = '[UserSettings MuniUserSettingsPage] Load Failed',
    MuniUpdate               = '[UserSettings MuniUserSettingsPage] Update Setting',
    MuniUpdateUIOnly         = '[UserSettings MuniUserSettingsPage] Update Setting on UI Only',
    MuniUpdateSuccess        = '[UserSettings MuniUserSettingsPage] Update Setting Success',
    MuniUpdateFailed         = '[UserSettings MuniUserSettingsPage] Update Setting Failed'
}

export class FixedIncomeLoad implements Action {
    public readonly type = UserSettingsPageActions.FixedIncomeLoad;

    constructor() {}
}

export class FixedIncomeOeLoad implements Action {
    public readonly type = UserSettingsPageActions.FixedIncomeOeLoad;

    constructor() {}
}

export class MuniLoad implements Action {
    public readonly type = UserSettingsPageActions.MuniLoad;

    constructor() {}
}

export class EquityLoad implements Action {
    public readonly type = UserSettingsPageActions.EquityLoad;

    constructor() {}
}

export class FixedIncomeLoadSuccess implements Action {
    public readonly type = UserSettingsPageActions.FixedIncomeLoadSuccess;

    constructor(public payload: UserSettingsHttpModel) {}
}

export class FixedIncomeLoadFailed implements Action {
    public readonly type = UserSettingsPageActions.FixedIncomeLoadFailed;

    constructor(public payload: any) {}
}

export class FixedIncomeOeLoadSuccess implements Action {
    public readonly type = UserSettingsPageActions.FixedIncomeOeLoadSuccess;

    constructor(public payload: OperationalEntityHttpModel[]) {}
}

export class FixedIncomeOeLoadFailed implements Action {
    public readonly type = UserSettingsPageActions.FixedIncomeOeLoadFailed;

    constructor(public payload: any) {}
}

export class EquityLoadSuccess implements Action {
    public readonly type = UserSettingsPageActions.EquityLoadSuccess;

    constructor(public payload: EquityUserSettingsModel) {}
}

export class EquityLoadFailed implements Action {
    public readonly type = UserSettingsPageActions.EquityLoadFailed;

    constructor(public payload: any) {}
}

export class MuniLoadSuccess implements Action {
    public readonly type = UserSettingsPageActions.MuniLoadSuccess;

    constructor(public payload: { muniSettings: MuniUserSettingHttpModel[], hasMmdDataPermission: boolean }) {}
}

export class MuniLoadFailed implements Action {
    public readonly type = UserSettingsPageActions.MuniLoadFailed;

    constructor(public payload: any) {}
}

export class FixedIncomeUpdate implements Action {
    public readonly type = UserSettingsPageActions.FixedIncomeUpdate;

    constructor(public payload: {value: any, settingName: string, fieldName: string}) {}
}

export class FixedIncomeUpdateFailed implements Action {
    public readonly type = UserSettingsPageActions.FixedIncomeUpdateFailed;

    constructor(public payload: any) {}
}

export class EquityUpdate implements Action {
    public readonly type = UserSettingsPageActions.EquityUpdate;

    constructor(public payload: {settingName: keyof EquityUserSettingsModel, settingValue: EquitySettingType}) {}
}

export class EquityHomePageUpdate implements Action {
    public readonly type = UserSettingsPageActions.EquityHomePageUpdate;

    constructor(public payload: {value: any, settingName: string, fieldName: string}) {}
}

export class EquityUpdateSuccess implements Action {
    public readonly type = UserSettingsPageActions.EquityUpdateSuccess;

    constructor(public payload: {settingName: keyof EquityUserSettingsModel, settingValue: EquitySettingType}) {}
}

export class EquityUpdateFailed implements Action {
    public readonly type = UserSettingsPageActions.EquityUpdateFailed;

    constructor(public payload: any) {}
}

export class MuniUpdate implements Action {
    public readonly type = UserSettingsPageActions.MuniUpdate;

    constructor(public payload: {id: string, settingName: string, value: any}) {}
}

export class MuniUpdateUIOnly implements Action {
    public readonly type = UserSettingsPageActions.MuniUpdateUIOnly;

    constructor(public payload: {id: string, settingName: string, value: any}) {}
}

export class MuniUpdateSuccess implements Action {
    public readonly type = UserSettingsPageActions.MuniUpdateSuccess;

    constructor(public payload: MuniUserSettingHttpModel) {}
}

export class MuniUpdateFailed implements Action {
    public readonly type = UserSettingsPageActions.MuniUpdateFailed;

    constructor(public payload: any) {}
}

export type UserSettingsPageActionsUnion =
    | FixedIncomeLoad
    | FixedIncomeLoadSuccess
    | FixedIncomeLoadFailed
    | FixedIncomeOeLoad
    | FixedIncomeOeLoadSuccess
    | FixedIncomeOeLoadFailed
    | EquityLoad
    | EquityLoadSuccess
    | EquityLoadFailed
    | MuniLoad
    | MuniLoadSuccess
    | MuniLoadFailed
    | FixedIncomeUpdate
    | FixedIncomeUpdateFailed
    | EquityUpdate
    | EquityUpdateSuccess
    | EquityUpdateFailed
    | MuniUpdate
    | MuniUpdateUIOnly
    | MuniUpdateSuccess
    | MuniUpdateFailed;
