import { createFeatureSelector, createSelector } from '@ngrx/store';

import { EquityUserSettingsModel } from '../../../modules/user-settings/models/equity-user-settings.model';
import { FixedincomeUserSettingsModel } from '../../../modules/user-settings/models/fixedincome-user-settings.model';
import { LoadingStatus } from '@core/models/loading-status.model';
import { MuniUserSettingsHelper } from '@core/user-settings/muni-user-settings.helper';
import { MuniUserSettingsModel } from '../../../modules/user-settings/models/muni-user-settings.model';
import { UserSettingsPageActions } from '../actions';

export interface State {
    fixedIncomeModel: FixedincomeUserSettingsModel;
    equityModel: EquityUserSettingsModel;
    muniModel: MuniUserSettingsModel;
    isLoading: boolean;
    isError: boolean;
}

export const initialState: State = {
    fixedIncomeModel: {
        httpModel: null,
        operationalEntity: {
            httpModel: [],
            loadingStatus: LoadingStatus.NotLoaded
        }
    },
    equityModel: {
        numberFormat: null,
        dateFormat: null,
        timeFormat: null,
        timezone: null,
        equityBookState: null,
        equityBookStateNotificationSetting: null,
        equityDealState: null,
        equityDealStateNotificationSetting: null,
        equityDealDetailsState: null,
        equityDealDetailsNotificationSetting: null,
        equityBookStateEmail: null,
        equityBookStateEmailNotificationSetting: null,
        equityDealStateEmail: null,
        equityDealStateEmailNotificationSetting: null,
        equityDealDetailsStateEmail: null,
        equityDealDetailsEmailNotificationSetting: null
   },
    muniModel: {
        defaultBenchmarkCurve: null,
        defaultFlexColumn: null,
        defaultCalendarFollowAssignmentShortcut: null,
        defaultMarginalTaxRate: null,
        defaultDealManagerView: null,
        defaultCalendarRatingsView: null
    },
    isLoading: true,
    isError: false
};

export function userSettingsPageReducer(
    state: State = initialState,
    action: UserSettingsPageActions.UserSettingsPageActionsUnion
): State {
    switch (action.type) {
        case UserSettingsPageActions.UserSettingsPageActions.FixedIncomeLoad:
        case UserSettingsPageActions.UserSettingsPageActions.EquityLoad:
            return {
                ...state,
                isLoading: true,
                isError: false
            };

        case UserSettingsPageActions.UserSettingsPageActions.FixedIncomeLoadSuccess:
            return {
                ...state,
                fixedIncomeModel: {
                    ...state.fixedIncomeModel,
                    httpModel: {
                        ...action.payload
                    }
                },
                isLoading: false,
                isError: false
            };

        case UserSettingsPageActions.UserSettingsPageActions.EquityLoadSuccess:
            return {
                ...state,
                equityModel: { ...action.payload },
                isLoading: false,
                isError: false
            };

        case UserSettingsPageActions.UserSettingsPageActions.MuniLoadSuccess:
            return {
                ...state,
                muniModel: MuniUserSettingsHelper.createMuniSettingsModel(action.payload.muniSettings, action.payload.hasMmdDataPermission),
                isLoading: false,
                isError: false
            };

        case UserSettingsPageActions.UserSettingsPageActions.FixedIncomeLoadFailed:
        case UserSettingsPageActions.UserSettingsPageActions.MuniLoadFailed:
        case UserSettingsPageActions.UserSettingsPageActions.EquityUpdateFailed:
            return {
                ...state,
                isLoading: false,
                isError: true
            };

        case UserSettingsPageActions.UserSettingsPageActions.FixedIncomeUpdate:
            return {
                ...state,
                fixedIncomeModel: {
                    ...state.fixedIncomeModel,
                    httpModel: {
                        ...state.fixedIncomeModel.httpModel,
                        [action.payload.settingName]: {
                            ...state.fixedIncomeModel.httpModel[action.payload.settingName],
                            [action.payload.fieldName]: action.payload.value
                        }
                    }
                },
                isError: false
            };

        case UserSettingsPageActions.UserSettingsPageActions.EquityUpdate:
            return {
                ...state,
                equityModel: {
                    ...state.equityModel,
                    [action.payload.settingName]: action.payload.settingValue
                },
                isError: false
            };

        case UserSettingsPageActions.UserSettingsPageActions.MuniUpdateUIOnly:
            return {
                ...state,
                muniModel: {
                    ...state.muniModel,
                    [action.payload.settingName]: {
                        id: action.payload.id,
                        value: action.payload.value
                    }
                }
            };

        case UserSettingsPageActions.UserSettingsPageActions.MuniUpdateSuccess:
            return {
                ...state,
                muniModel: {
                    ...state.muniModel,
                    [action.payload.userPreference.name]: {
                        id: action.payload.id,
                        value: action.payload.userPreference.value
                    }
                },
                isError: false
            };

        case UserSettingsPageActions.UserSettingsPageActions.FixedIncomeUpdateFailed:
        case UserSettingsPageActions.UserSettingsPageActions.MuniUpdateFailed:
        case UserSettingsPageActions.UserSettingsPageActions.EquityUpdateFailed:
            return {
                ...state,
                isError: true
            };

        case UserSettingsPageActions.UserSettingsPageActions.FixedIncomeOeLoad:
            return {
                ...state,
                fixedIncomeModel: {
                    ...state.fixedIncomeModel,
                    operationalEntity: {
                        ...state.fixedIncomeModel.operationalEntity,
                        loadingStatus: LoadingStatus.Loading
                    }
                }
            };

        case UserSettingsPageActions.UserSettingsPageActions.FixedIncomeOeLoadFailed:
            return {
                ...state,
                fixedIncomeModel: {
                    ...state.fixedIncomeModel,
                    operationalEntity: {
                        ...state.fixedIncomeModel.operationalEntity,
                        loadingStatus: LoadingStatus.Failed
                    }
                }
            };

        case UserSettingsPageActions.UserSettingsPageActions.FixedIncomeOeLoadSuccess:
            return {
                ...state,
                fixedIncomeModel: {
                    ...state.fixedIncomeModel,
                    operationalEntity: {
                        ...state.fixedIncomeModel.operationalEntity,
                        httpModel: [...action.payload],
                        loadingStatus: LoadingStatus.Success
                    }
                }
            };

        default:
            return state;
    }
}

export const getUserSettingsPageState = createFeatureSelector<State>('userSettingsPage');
export const getFixedIncomeUserSettings = createSelector(getUserSettingsPageState, state => state.fixedIncomeModel.httpModel);
export const getOperationalEntitySettings = createSelector(getUserSettingsPageState, state => state.fixedIncomeModel.operationalEntity);
export const getMuniUserSettings = createSelector(getUserSettingsPageState, state => state.muniModel);
export const getEquityUserSettings = createSelector(getUserSettingsPageState, state => state.equityModel);
export const getIsUserSettingsPageLoading = createSelector(getUserSettingsPageState, (state) =>  state.isLoading);
export const getIsUserSettingsPageError = createSelector(getUserSettingsPageState, (state) =>  state.isError);
export const getIsNotificationsAllowed = createSelector(getFixedIncomeUserSettings, (userSettings) => {
    if (!userSettings) {
        return false;
    }

    return userSettings.app_internalCommentNotifications.enable
        && userSettings.app_desktopNotifications.enable
        && userSettings.app_singleInAppEmailNotifications.enable;
});
