<div class="c-text-md password-strength">
    <div class="password-strength__hints-col">
        <app-password-criteria
            [criteriaName]="passwordStrengthMsg.minCharacters"
            [isFullfiled]="passwordStrengthCriteria.minCharacters"
        ></app-password-criteria>

        <app-password-criteria
            [criteriaName]="passwordStrengthMsg.upperCaseChar"
            [isFullfiled]="passwordStrengthCriteria.uppercaseCharacter"
        ></app-password-criteria>

        <app-password-criteria
            [criteriaName]="passwordStrengthMsg.lowerCaseChar"
            [isFullfiled]="passwordStrengthCriteria.lowercaseCharacter"
        ></app-password-criteria>
    </div>
    <div class="password-strength__hints-col">
        <app-password-criteria
            [criteriaName]="passwordStrengthMsg.numberPresent"
            [isFullfiled]="passwordStrengthCriteria.numbersPresent"
        ></app-password-criteria>

        <app-password-criteria
            [criteriaName]="passwordStrengthMsg.specialChar"
            [isFullfiled]="passwordStrengthCriteria.allowedSpecialCharacters"
        ></app-password-criteria>

    </div>
</div>
