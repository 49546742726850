import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { IJwtTokenProvider } from '../jwt.lifetime.processor';
import { noErrorHandlingForHeader } from '@core/constants/http-headers';
import { select, Store } from '@ngrx/store';
import * as fromRoot from '../../../../reducers';
import { take } from 'rxjs/operators';
import { API } from '@core/constants/api';

@Injectable({
    providedIn: 'root'
})
export class WebsocketGatewayJwtTokenProvider implements IJwtTokenProvider {

    constructor(
        private http: HttpClient,
        private store: Store<fromRoot.State>
    ) {}

    public retryTimeout = 10000;

    public async fetchToken(): Promise<string> {
        const headers = new HttpHeaders().set(noErrorHandlingForHeader, '*');

        const appCode = await this.store.pipe(select(fromRoot.getIpreoSocketsAppCode), take(1)).toPromise();

        return await this.http.get<string>(`${API.tokens.websocketGatewayJwtToken}/${appCode}`, { headers }).toPromise();
    }
}
