<div class="policy">
    <div class="c-text-center c-m-bottom-md c-m-top-md">
        <h1 class="c-header-sm c-m-bottom-0 policy__e2e--title">Regulatory Terms And Disclosures</h1>
    </div>

    <div class="policy__text policy__e2e--text c-bg-white c-text-sm c-text-gray-9">
        <p>
            By entering into the investment services we provide, you are deemed to accept and agree to the terms and disclosures which we, as an authorised firm, are required to provide to you in accordance with the Financial Conduct Authority’s rules and the Markets in Financial Instruments Regulation ("<strong>MiFIR</strong>"), the recast Markets in Financial Instruments Directive ("<strong>MiFID II</strong>") and their implementing laws and regulations. Such terms and disclosures form an integral part of the agreement between you and us.
        </p>
        <h3> 1. Information About Ipreo</h3>
        <p>
            1.1. Ipreo Limited ("<strong>Ipreo</strong>") is authorised and regulated by the  FCA, and has its principal place of business at 1<sup>st</sup> Floor, Castle House, 37-45 Paul Street, London, EC2A 4LS. Our FCA registration number is 775369. The FCA has its head office at 25 The North Colonnade London E14 5HS.
        </p>
        <h3> 2. Our Services </h3>
        <p>
            2.1. As set out further in our standard agreement, we may, as part of the services we provide, facilitate the arrangement of deals in investments in relation to transferable securities (including equity securities and debt securities). We are a provider of infrastructure and not a distributor of financial instruments. Please refer to the Appendix below for a summary of the nature of, and principal risks associated with the financial instruments in relation to which we may provide services.
        </p>
        <p>
            2.2. We will not:
        </p>
        <p>
            2.2.1. hold cash or securities on your behalf;
        </p>
        <p>
            2.2.2. recommend or market instruments to you; or
        </p>
        <p>
            2.2.3. provide investment advice in relation to any instrument or as regards to your financial position generally.
        </p>
        <p>
            2.3. You agree that we may fulfil any requirements to which we may be subject under applicable law in relation to any service provided, including (but not limited to) any reporting requirements, recordkeeping requirements and any requirements to monitor and prevent market abuse.
        </p>
        <p>
            2.4. You agree to provide any information which we may require to meet our obligations under applicable law and regulation, including your legal entity identifier and any other information we may require to record, transmit or report in accordance with applicable laws and regulations. Any such information must be provided promptly upon request in such format as we may prescribe and must be accurate and complete. You warrant that you have full authority and all consents necessary to permit us to receive and use such information as contemplated by this clause.
        </p>
        <h3> 3. Your Capacity </h3>
        <p>
            3.1. We will treat you alone as our client for all purposes related to the investment services we provide, even if you are acting as an agent.
        </p>
        <h3> 4. Know Your Client ("KYC") Requirements </h3>
        <p>
            4.1. The services we provide to you will be covered by certain applicable laws relating to client identification and money laundering which may require us to seek further evidence and confirmation of your identify and the business that you propose to undertake with us.
        </p>
        <p>
            4.2. If satisfactory evidence of your identity has not been obtained within a reasonable period, we reserve the right not to provide or to cease providing services to you.
        </p>
        <h3> 5. Client Categorisation </h3>
        <p>
            5.1. As an entity regulated by the FCA, we are required to classify our clients as either Retail Clients, Professional Clients or Eligible Counterparties (each as defined in the FCA’s handbook of rules and guidance). Based on the information available to us, we have categorised you as a Professional Client for the purposes of your business relationship with us. A professional client is a client who possesses the experience, knowledge, and expertise to make its own investment decisions and properly assess the risks that it incurs. You will therefore benefit from the regulatory protections afforded by applicable laws, rules and regulations to this category of clients.
        </p>
        <p>
            5.2. As a Professional Client, you do not benefit from the additional regulatory protections afforded by applicable laws and regulations to Retail Clients including those:
        </p>
        <p>
            5.2.1. requiring us not to assume you have the necessary experience and knowledge in order to understand the risks involved in relation to the products or investment services offered or demanded when assessing whether an investment service is appropriate for you; and
        </p>
        <p>
            5.2.2. requiring that where you are provided with information about a financial instrument that is the subject of a current offer to the public and a prospectus has been published in connection with that offer, we inform you, in good time before the provision of investment services, where that prospectus is made available to the public.
        </p>
        <p>
            5.3. Your classification as a Professional Client is not permanent. You are responsible for informing us of any change to your status or situation which could affect your classification, and we will notify you of any new classification if it results from information available to us that you no longer comply with the criteria applicable for being treated as a Professional Client.
        </p>
        <h3> 6. Requesting a Different Categorisation </h3>
        <p>
            6.1. You have the right to request a different classification as an Eligible Counterparty or a Retail Client, in general or for specific financial instruments or services. You may make such a request via email at <a class="c-a" href="mailto:ukcompliance@ipreo.com">ukcompliance@ipreo.com</a>.
        </p>
        <p>
            6.2. If you request to be treated as a Retail Client or if we consider, based on information available to us, that you should be treated as a Retail Client, we will no longer be able to provide services to you.
        </p>
        <p>
            6.3. If you request to be treated as an Eligible Counterparty, we may accept your request if you satisfy the relevant conditions to qualify as an Eligible Counterpart, and you provide written confirmation that you are aware of the consequences of the protections you may lose. If we agree to your request, you will no longer benefit from the regulatory protections afforded by applicable law and regulations to Professional Clients, including those resulting from the requirement for us to:
        </p>
        <p>
            6.3.1. act in accordance with your best interests;
        </p>
        <p>
            6.3.2. assess the appropriateness of services proposed to you or requested by you; and
        </p>
        <p>
            6.3.3. take all sufficient steps for obtaining the best possible result for the reception and transmission of your orders, where applicable;
        </p>
        <h3> 7. Execution Policy </h3>
        <p>
            7.1. In accordance with our obligations under applicable laws and regulations we have implemented an execution policy to ensure we meet our regulatory obligation to take all sufficient steps to obtain the best possible result for our clients when receiving and transmitting orders.
        </p>
        <p>
            7.2. Our services are non-discretionary: we do not exercise discretion as to the terms of any order or as to the basis on which orders are transmitted. Accordingly, our policy is to facilitate the specific instructions of our clients in receiving and transmitting orders. Where you provide instructions in relation to an order, our systems will facilitate that instruction so far as is reasonably practicable when receiving and transmitting the order. By facilitating your specific instruction, we will have satisfied our obligation to obtain the best possible result in relation to that transaction.
        </p>
        <p>
            7.3. If you would have a requirement to receive information about the entities to which we transmit orders, please email us at <a class="c-a" href="mailto:ukcompliance@ipreo.com">ukcompliance@ipreo.com</a>.
        </p>
        <h3> 8. Conflicts of Interest </h3>
        <p>
            8.1. We are committed to identifying and managing actual or potential conflicts of interests which may arise in the normal course of business. A conflict of interest is a situation where, within the course of our activities, our interests and/or the interests of our clients, managers, employees, agents or any person directly or indirectly controlling Ipreo compete, whether directly or indirectly.
        </p>
        <p>
            8.2. We have a conflict of interests policy in place to identify, manage and/or prevent conflicts of interest. The policy sets out guidance on the identification of conflicts, and the organisational or administrative arrangements we have in place to manage actual or potential conflicts of interest. Where such arrangements are not able to ensure that the risk of a conflict having an impact on you will be prevented, we will disclose the source and nature of the conflict to you as soon as reasonably possible as well as the steps taken to mitigate those risks prior to providing services to you. A copy of our policy can be provided on request by emailing <a class="c-a" href="mailto:ukcompliance@ipreo.com">ukcompliance@ipreo.com</a>.
        </p>
        <h3> 9. Communications </h3>
        <p>
            9.1. By accepting our services, you agree that we may monitor and retain records of communications (including electronic communications) for the purposes of ensuring compliance with our legal and regulatory obligations, and internal policies. You agree to obtain any necessary consent and give any necessary notices to your relevant personnel relating to our monitoring and retaining communications.
        </p>
        <p>
            9.2. A copy of any communications we record relating to your orders will be available on request from the date of the communication for a period of five years, and, where requested by the FCA, for a period of up to seven years.
        </p>
        <p>
            9.3. You agree, to the extent permitted or required by applicable law and regulations, that records may be submitted as evidence in any dispute or other proceedings in relation to these terms and disclosures or the services we provided, including in relation to any enquiry by the FCA. Such records will be our sole property, and will be conclusive evidence of the request or communication, and may be used as evidence in the event of a dispute.
        </p>
        <p>
            9.4. You consent to our providing communications to you by means of our website.
        </p>
        <h3> 10. Reports </h3>
        <p>
            10.1. Where required by applicable laws and regulations, we will provide you with periodic reports on the services we offer you, taking into account the type and complexity of the instruments involved and the nature of the service provided.
        </p>
        <h3> 11. Complaints </h3>
        <p>
            11.1. We have a written complaints management policy and procedure for handling complaints. We intend to handle all complaints fairly, promptly and in a consistent manner, free of charge. Further details on our complaints management policy can be provided on request by emailing <a class="c-a" href="mailto:ukcompliance@ipreo.com">ukcompliance@ipreo.com</a>.
        </p>
        <p>
            11.2. If you have a complaint about us or are dissatisfied with any aspect of the service you have received, you should first raise it with your usual point of contact at Ipreo. If you are not satisfied with the response of the Ipreo contact, or if you prefer not to raise the matter with your Ipreo contact, you may email the complaints management function at <a class="c-a" href="mailto:ukcompliance@ipreo.com">ukcompliance@ipreo.com</a>.
        </p>
        <h2> APPENDIX </h2>
        <h2> INSTRUMENTS AND ASSOCIATED RISKS </h2>
        <p>
            This Appendix summarises the nature of, and principal risks associated with, financial instruments in relation to which we may provide services. The summary below takes into account your categorisation as a Professional Client.
        </p>
        <p>
            This Appendix cannot disclose all the risks and other significant aspects of a financial instrument. You should not transact in a financial instrument unless you understand its nature and risks and the extent of your exposure to risk. You should also be satisfied that the instrument is suitable for you in light of your circumstances and financial position. There are risks that will apply generally to any investment. These include (but are not limited to):
        </p>
        <p>
            a) Currency risk: if investments are denominated in a currency other than that in which the investor’s initial investment was made, returns could be reduced, or losses incurred, due to currency fluctuations.
        </p>
        <p>
            b) Change in law risk: if there is a change in law which affects an investment, or the manner in which it is traded or held, additional costs might be incurred or, in extreme circumstances, investments lost.
        </p>
        <p>
            c) Tax risk: a change in tax law to impose a new tax on the transfer or holding of an instrument could result in costs being incurred when realising one’s investment.
        </p>
        <p>
            d) Third party risk: certain investments may need third parties to act in relation to investments traded or held by you (e.g. custodians, settlement agents, exchanges). Your investments may be at risk in the event of failure and/or fraud in respect of one of these third parties.
        </p>
        <p>
            e) Fraud risk: if there is a fraud in relation to investments which you hold, you may be at risk of losing your investment.
        </p>
        <p>
            f) Legal risk in other jurisdictions: Some markets investments or the holding of each may be subject to different or diminished investor protection.
        </p>
        <h3> 1. Equity Securities </h3>
        <p>
            1.1. Shares can increase and decrease in price leading to potential gains and/or losses for shareholders. Changes in price are often unpredictable and volatile, reflecting market appraisal of the company’s performance and prospects. The value of equity investments can further be influenced by wider market conditions and changes in the economic, political and regulatory environment. Adverse market conditions may also affect the ability of shareholders to effect transactions in shares, determine a value or fair price, or to liquidate all or part of their investments.
        </p>
        <p>
            1.2. The issuing of dividends to shareholders by companies is not guaranteed. The company may not have sufficient profits to issue a dividend or may be otherwise restricted from doing so. Further, if profits are limited the company may choose not to make a dividend payment. As owners of the company, shareholders also risk losing their principal investment if the company becomes insolvent.
        </p>
        <p>
            1.3. Companies occasionally raise funds through equity finance. Such financing may or may not be conducted on a pre-emptive basis or existing shareholders may decide not to participate in the financing. Shareholders could accordingly experience a dilution of their shareholdings.
        </p>
        <h3> 2. Debt Securities </h3>
        <p>
            2.1. The payment of principal and interest on debt securities is subject to the risk that that the issuer of the debt security may be unable to fulfil interest rate payments or pay the principal amount due. The value of the security may also decline if the market views the issuer as unlikely to meet repayment obligations. Any change in the credit ratings of a debt security itself or the issuer can further influence market value.
        </p>
        <p>
            2.2. Market assessment of the value of debt securities can be affected by other factors, including interest rate changes, which may be unpredictable. Adverse market conditions may also affect the ability of shareholders to effect transactions in debt securities, determine a value or fair price, or to liquidate all or part of their investments.
        </p>
        <h3> 3. Warrants </h3>
        <p>
            3.1. A warrant is a time-limited right to subscribe for shares, debentures, loan stock or government securities and is exercisable against the original issuer of the underlying securities.
        </p>
        <p>
            3.2. A relatively small movement in the price of the underlying security may result in a disproportionately large movement, unfavourable or favourable, in the price of the warrant. The prices of warrants can therefore be volatile. Purchasers of warrants must be prepared to sustain a loss of the money invested plus any commission or other transaction charges.
        </p>
        <p>
            3.3. It is essential for anyone who is considering purchasing warrants to understand that the right to subscribe which a warrant confers is invariably limited in time with the consequence that if the investor fails to exercise this right within the predetermined timescale then the investment becomes worthless.
        </p>
        <p>
            3.4. You should not buy a warrant unless you are prepared to sustain a total loss of the money you have invested plus any commission or other transaction charges.
        </p>
    </div>
</div>
