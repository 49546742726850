import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';

import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';

import { select, Store } from '@ngrx/store';
import * as fromRoot from '../../reducers';
import { AuthApiActions } from '@core/@state/actions';

@Injectable({
    providedIn: 'root',
})
export class AuthGuard implements CanActivate {

    constructor(private store: Store<fromRoot.State>) {
    }

    public canActivate(): Observable<boolean> {
        return this.store.pipe(
            select(fromRoot.getAuthState),
            map(auth => auth.isLoggedIn),
            tap(isLoggedIn => {
                if (!isLoggedIn) {
                    this.store.dispatch(new AuthApiActions.TerminateSession({ isSessionTimedOut: false }));
                }
            })
        );
    }
}
