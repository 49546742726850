import { UserContextEmailModel } from '@core/usercontext/user-context-http.model';
import { DefaultOperationalEntity } from '../../modules/user-settings/models/user-settings-http.model';

interface DateTimeFormat {
    key: string;
    example: string;
    format: string;
    kendoFormat: string;
    momentFormat: string;
}

export interface TimeFormats {
    [key: string]: DateTimeFormat;
}

export interface DateFormats {
    [key: string]: DateTimeFormat;
}

export interface NumberFormats {
    [key: string]: NumberFormat;
}

export interface UserSettings {
    language: string;
    currency: string;
    dateFormat: string;
    timeFormat: string;
    timeZone: string;
    numberFormat: NumberFormat;
    profile: UserProfile;
    containers: Container[];
    companies: Company[];
    pricedDealsWindowDays: number;
    companySortOrder: number[];
    isAccessingFromUS: boolean;
    hideEmptyFields: boolean;
    highlightChanges: boolean;
    maxLevelAmount: string;
    orderEntryFormat: OrderEntryFormat;
    orderHedgeType: OrderHedgeType;
    showChineseDeals: boolean;
    defaultOperationalEntities: DefaultOperationalEntity[];
    singleInAppEmailNotifications: boolean;
    orderHedgeSettlement: string;
}

export enum OrderEntryFormat {
    Cumulative = 'Cumulative',
    Additive = 'Additive'
}

export enum OrderHedgeType {
    DurationWeighted = 'Duration Weighted',
    Cash = 'Cash/Cash',
    Notional = 'Notional/Notional',
    AssetSwap = 'Asset Swap'
}

export interface NumberFormat {
    BigNumberFormat: {
        decimalSeparator: '.' | ',',
        groupSeparator: ',' | ' ' | '' | '\u00a0',
        groupSize: number
    };
    format: {
        DECIMAL_SEP: '.' | ',',
        GROUP_SEP: ',' | ' ' | '' | '\u00a0',
        PATTERNS: [
            {
                gSize: number,
                lgSize: number,
                maxFrac: number,
                minFrac: number,
                minInt: number,
                negPre: string,
                negSuf: string,
                posPre: string,
                posSuf: string,
            },
            {
                gSize: number,
                lgSize: number,
                maxFrac: number,
                minFrac: number,
                minInt: number,
                negPre: string,
                negSuf: string,
                posPre: string,
                posSuf: string
            }
            ];
    };
    key: 'Format0' | 'Format1' | 'Format2';
    name: '1,000,000.99' | '1 000 000,99' | '1000000,99';
}


export interface UserProfile {
    city: string;
    country: string;
    createdBy: string;
    creationDate: string;
    email: string;
    emails: UserContextEmailModel[];
    firstName: string;
    id: string;
    lastName: string;
    phoneNumber: string;
    phones: any[];
    title: string;
    userName: string;
    acceptTermsOfUseRequired: boolean;
    passwordChangeRequired: boolean;
    passwordChangeDate: string;
    homePage: string;
}

export interface Container {
    id: string;
    isTestContainer: boolean;
    name: string;
    status: string;
    isAdmin: boolean;
}

export interface Company {
    containerId: string;
    id: string;
    name: string;
    orionId: number;
    parentCompanyId: string;
}

export interface OrderHedgeSettlement {
    code: string;
    name: string;
}

export const OrderHedgeSettlementValues: OrderHedgeSettlement[] = [
    {
        code:'Match',
        name:'Match'
    },
    {
        code:'1',
        name:'T+1'
    },
    {
        code:'2',
        name:'T+2'
    },
    {
        code:'3',
        name:'T+3'
    },
    {
        code:'4',
        name:'T+4'
    },
    {
        code:'5',
        name:'T+5'
    },
    {
        code:'6',
        name:'T+6'
    },
    {
        code:'7',
        name:'T+7'
    },
    {
        code:'8',
        name:'T+8'
    },
    {
        code:'9',
        name:'T+9'
    },
    {
        code:'10',
        name:'T+10'
    }
]

