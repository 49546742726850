import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { UserSettingsActions } from '@core/@state/actions';
import { catchError, map, switchMap } from 'rxjs/operators';
import { of } from 'rxjs';
import { HttpUserSettingsService } from '../../user-settings/http-user-settings.service';


@Injectable()
export class SyncUserSettingsEffect {

    constructor(
        private actions$: Actions,
        private httpUserSettingsService: HttpUserSettingsService
    ) {}


    public syncUserSettings$ = createEffect(() => this.actions$.pipe(
        ofType(
            UserSettingsActions.SettingsActionTypes.ToggleHideEmptyFields,
            UserSettingsActions.SettingsActionTypes.ToggleHighlightChanges
        ),
        switchMap(() => this.httpUserSettingsService.syncUserSetting()
            .pipe(
                map(() => new UserSettingsActions.SyncUserSettingsSuccess()),
                catchError((error: any) => of(new UserSettingsActions.SyncUserSettingsFailed(error)))
            )
        )
    ));

}
