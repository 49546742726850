import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { LoadingStatus } from '@core/models/loading-status.model';
import { DropdownItem } from '@core/models/dropdown-item.model';

@Component({
    selector: 'app-dynamic-dropdown',
    templateUrl: './dynamic-dropdown.component.html',
    styleUrls: ['./dynamic-dropdown.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class DynamicDropdownComponent {
    @Input() loadingStatus: LoadingStatus;
    @Input() value: string;
    @Input() options: DropdownItem[];
    @Output() change: EventEmitter<string> = new EventEmitter<string>();

    public readonly OE_REQUIRED_MSG = 'OE default value is required';
    public LoadingStatus = LoadingStatus;

    public trackBy(index: number, item: DropdownItem): string {
        return item.value + index;
    }

}
