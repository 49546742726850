import { Action } from '@ngrx/store';
import { Transport } from '../../types';

export enum TransportsActions {
    Load = '[northstar-alerts] Load Transports',
    LoadSuccess = '[northstar-alerts] Load Transports Success',
    LoadFail = '[northstar-alerts] Load Transports Error'
}

/* tslint:disable:max-classes-per-file */

export class TransportsLoadAction implements Action {
    public readonly type = TransportsActions.Load;
}

export class TransportsLoadSuccessAction implements Action {
    public readonly type = TransportsActions.LoadSuccess;

    constructor(public payload: Transport[]) {
    }
}

export class TransportsLoadFailAction implements Action {
    public readonly type = TransportsActions.LoadFail;

    constructor(public payload: string) {
    }
}

export type TransportsActionsUnion =
    | TransportsLoadAction
    | TransportsLoadSuccessAction
    | TransportsLoadFailAction;
