import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';

import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';

import { select, Store } from '@ngrx/store';
import * as fromRoot from '../../reducers';

@Injectable({
    providedIn: 'root',
})
export class TermsAcceptedGuard implements CanActivate {

    constructor(private store: Store<fromRoot.State>, private router: Router) {
    }

    public canActivate(): Observable<boolean> {
        return this.store.pipe(
           select(fromRoot.getUserProfile),
           map(data => !data.acceptTermsOfUseRequired),
           tap(termsAccepted => {
               if (!termsAccepted) {
                   this.router.navigate(['/accept-terms']);
               }
           })
        );
    }
}
