import { Injectable } from '@angular/core';
import { UserProfile } from '../../../core/user-settings/user-settings.model';
import { PASSWORD_MIN_LENGTH, PasswordCriteria } from '../models/password.criteria.model';

@Injectable({
    providedIn: 'root',
})
export class ValidationService {
    constructor() {}

    // eslint-disable-next-line max-len
    public emailPattern = new RegExp(
        /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
    public textInputPattern = new RegExp(/^\S[-\w\s.,"'\/$&%#@()]*\S$/);
    public phoneNumberPattern = new RegExp(/^\S[-\s\d.'()+]*\S$/);
    public isUppercasePattern = new RegExp('[A-Z]');
    public isLowercasePattern = new RegExp('[a-z]');
    public isNumberPresentPattern = new RegExp('[0-9]');
    public isSpecialCharacterPattern = new RegExp(/[`!~()_\-=\[\]{};':"\\|,.<>\/? ]/);
    public supportMessagePattern = new RegExp(/^[-\w\s.,'$&%#@()+!?"*:;]*$/);
    public consecutiveCharacterPattern = new RegExp(/^(?!.*(.)\1\1).*$/);
    public allowedSpecialCharacters = new RegExp(/[@#$%^&*+]/);

    public checkPasswordStrength(password: string): PasswordCriteria {
        return {
            minCharacters: password.length >= PASSWORD_MIN_LENGTH,
            uppercaseCharacter: this.isUppercasePattern.test(password),
            lowercaseCharacter: this.isLowercasePattern.test(password),
            numbersPresent: this.isNumberPresentPattern.test(password),
            specialCharacter: this.isSpecialCharacterPattern.test(password),
            allowedSpecialCharacters: this.allowedSpecialCharacters.test(password),
        };
    }

    public isPasswordContainsRepeatChars(password: string): boolean {
        return !this.consecutiveCharacterPattern.test(password.toLowerCase());
    }

    public isCriteriaFullfiled(criteria: PasswordCriteria): boolean {
        return Object.keys(criteria).every((key) =>
            key === 'specialCharacter' || key === 'allowedSpecialCharacters'
                ? criteria['specialCharacter'] || criteria['allowedSpecialCharacters']
                : criteria[key] === true
        );
    }

    public isPasswordContainsFirstLastName(userProfile: UserProfile, password: string): boolean {
        const nameParts = [...userProfile.firstName.split(' '), ...userProfile.lastName.split(' ')];
        return nameParts.some((part) => part.length > 2 && this.isCommonSubstringsPresent(part, password));
    }

    public isPasswordContainsUserName(userProfile: UserProfile, password: string): boolean {
        return this.isCommonSubstringsPresent(userProfile.email, password);
    }

    private isCommonSubstringsPresent(a: string, b: string): boolean {
        return Array.from({ length: a.length - 2 })
            .map((_, i) => a.substring(i, i + 3))
            .reduce((found, substring) => found || b.toLocaleLowerCase().includes(substring.toLocaleLowerCase()), false);
    }

    public isPasswordContainsAllowedSpecialCharacters(password: string): boolean {
        return this.allowedSpecialCharacters.test(password);
    }
}
