<div class="modal c-modal c-modal-info c-modal-fade c-modal-in">
    <div class="c-modal-dialog">
        <div class="c-modal-content">
            <div class="c-modal-header">
                <div class="c-modal-title">{{ header }}</div>
                <button type="button" class="c-modal-close">
                    <!--span onclick="closeDefault()" aria-hidden="true">×</span-->
                </button>
            </div>
            <div class="c-modal-body c-text-center">
                <!--i class="fa fa-question-circle c-modal-confirmation-icon"></i-->
                <span class="c-modal-confirmation-title">{{ title }}</span>
                <p class="c-modal-confirmation-text"><ng-content></ng-content></p>
                <div class="c-modal-confirmation-buttons">
                    <!--span class="close-modal" aria-hidden="true">
                        <button onclick="closeInfo()" class=" close-modal c-btn c-btn-secondary c-m-right-sm">Cancel</button>
                        <button onclick="closeInfo()" class=" close c-btn c-btn-primary">Ok</button>
                    </span-->
                </div>
            </div>
        </div>
    </div>
</div>
