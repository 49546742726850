<span (click)="isUserSettingsDropdownOpen = !isUserSettingsDropdownOpen"
      #userSettingsDropdownEl
      class="c-dropdown">
    <a class="c-header-item-link">
      <span class="c-avatar c-avatar-white" [attr.data-text]="userInitials$ | async" data-status="success">
      </span>
    </a>
    <div *ngIf="isUserSettingsDropdownOpen"
         class="c-dropdown-list c-dropdown-list-below c-dropdown-list-right c-d-block">
        <div class="c-media c-media-middle">
        <span class="c-avatar c-avatar-primary" [attr.data-text]="userInitials$ | async"></span>
        <div class="c-media-body">
          <div class="c-text-md c-text-bold">{{userName$ | async}}</div>
          <div class="c-text-sm c-text-muted">{{userEmail$ | async}}</div>
        </div>
      </div>
      <div class="c-dropdown-divider"></div>
      <a  *ngIf="!isEquityRoute() && getCanViewFixedIncomePages$ | async"
          [routerLink]="routes.userProfile"
          class="c-dropdown-item">
        <i class="c-dropdown-icon fa fa-id-badge"></i> My Profile
      </a>
      <a *ngIf="currentAssetClass && !(getIsOnlyEquityPublicDealsPermissions$ | async)"
         [routerLink]="formatUserSettingsLink()"
         class="c-dropdown-item">
        <i class="c-dropdown-icon fa fa-cog"></i>User Settings
      </a>
      <div class="c-dropdown-divider"></div>
      <a [routerLink]="formatSupportLink()"
         class="c-dropdown-item">
        <i class="c-dropdown-icon fa fa-question-circle"></i>Support
      </a>
      <div class="c-dropdown-divider"></div>
      <a href="javascript:void(0)"
         (click)="logout()"
         id="logout"
         class="c-dropdown-item">
        <i class="c-dropdown-icon fa fa-power-off"></i>Log Out
      </a>
    </div>
</span>
