import { APP_INITIALIZER } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Store } from '@ngrx/store';
import { State } from './reducers';
import {
    PermissionsActions,
    ConfigurationApiActions,
    UserSettingsActions,
    AuthApiActions,
    LoggerActions,
    MuniUserSettingsActions,
    EquityUserSettingsActions,
    CountriesActions,
    UserLoginDataActions
} from '@core/@state/actions';

import { Country } from '@core/models/country.model';
import { catchError, map } from 'rxjs/operators';
import { of } from 'rxjs';
import { API } from '@core/constants/api';

import { UserSettingsService } from '@core/user-settings/user-settings.service';
import { UserContextHttpService } from '@core/usercontext/user-context-http.service';
import { PermissionService } from '@core/permissions/permissions.service';
import { PendoService } from '@core/services/pendo.service';

import { JwtLifetimeProcessor, JwtTokenAcquisitionInfo } from '@core/authentication/jwt/jwt.lifetime.processor';

import { pomJwtTokenStorageKey, websocketGatewayTokenStorageKey, JwtTokenType, jwtTokenV2StorageKey } from '@core/constants/constants';
import { PomJwtTokenProvider } from '@core/authentication/jwt/providers/pom.jwt.token.provider';
import { WebsocketGatewayJwtTokenProvider } from '@core/authentication/jwt/providers/websocket.gateway.jwt.token.provider';
import { BuysideProductIntegrationService } from '@core/services/buyside-product-integration.service';
import { VersionCheckService } from '@core/configuration/version-check.service';
import { BspConfiguration } from '@core/configuration/bsp-configuration';
import { MuniUserSettingsHttpService } from '@core/user-settings/muni-user-settings-http.service';
import { environment } from '@environments/environment';
import { JwtTokenV2Provider } from '@core/authentication/jwt/providers/jwt.token.v2.provider';
import { UserLoginDataService } from '@core/user-login-data/user-login-data.service';
import { EquityUserSettingsService } from '@core/user-settings/equity-user-settings.service';
import { Permissions } from '@core/permissions/permissions.model';

export function loadConfigurationFactory(
    userContextHttpService: UserContextHttpService,
    muniUserSettingsService: MuniUserSettingsHttpService,
    permissionService: PermissionService,
    userSettingsService: UserSettingsService,
    jwtLifetimeProcessor: JwtLifetimeProcessor,
    pomJwtTokenProvider: PomJwtTokenProvider,
    websocketGatewayJwtTokenProvider: WebsocketGatewayJwtTokenProvider,
    jwtTokenV2Provider: JwtTokenV2Provider,
    pendoService: PendoService,
    store: Store<State>,
    productIntegrationService: BuysideProductIntegrationService,
    versionCheckService: VersionCheckService,
    http: HttpClient,
    userLoginDataService: UserLoginDataService,
    equityUserSettingsService: EquityUserSettingsService
): () => {} {
    return async () => {
        try {
            if (environment.production) {
                versionCheckService.initVersionCheck();
            }

            // Put configuration into store
            const configuration: BspConfiguration = (window as any).buySideConfiguration;
            store.dispatch(new ConfigurationApiActions.Update({ configuration }));
            pendoService.setUp(configuration.pendoEnabled, configuration.pendoApiKey);

            if (window.location.href.includes('reset-password')) {
                return;
            }

            // Load User Context
            const userHttpContext = await userContextHttpService.load();
            if (!userHttpContext) {
                return ;
            }
            productIntegrationService.setContextToStorage(userHttpContext);
            const userContext = userContextHttpService.buildUserContext(userHttpContext);

            store.dispatch(new AuthApiActions.RestoreSession({isUserProfileConfirmed: userContext.IsUserProfileConfirmed}));
            store.dispatch(new LoggerActions.AddLoggedInTimestampAction({ loggedInAt: new Date(), type: 'Session Restored' }));

            const countries = await http.get<{countries: Country[]}>(API.countries)
                .pipe(map(response => response.countries), catchError(() => of([]))).toPromise();
            store.dispatch(new CountriesActions.Update(countries));

            pendoService.initialize(userContext);

            const isUserAssignedToCompanies = userContext.Profile.Companies.length > 0;

            if (isUserAssignedToCompanies) {
                // Load POM JWT Token and schedule token updates
                await jwtLifetimeProcessor.initializeTokenAsync(<JwtTokenAcquisitionInfo>{
                    tokenType: JwtTokenType.Pom,
                    tokenStorageKey: pomJwtTokenStorageKey,
                    tokenProvider: pomJwtTokenProvider
                });

                // Load WebSocketGateway JWT Token and schedule token updates
                await jwtLifetimeProcessor.initializeTokenAsync(<JwtTokenAcquisitionInfo>{
                    tokenType: JwtTokenType.WebsocketGateway,
                    tokenStorageKey: websocketGatewayTokenStorageKey,
                    tokenProvider: websocketGatewayJwtTokenProvider
                });

                // Load AWS JWT Token and schedule token updates
                await jwtLifetimeProcessor.initializeTokenAsync(<JwtTokenAcquisitionInfo>{
                    tokenType: JwtTokenType.JwtTokenV2,
                    tokenStorageKey: jwtTokenV2StorageKey,
                    tokenProvider: jwtTokenV2Provider
                });
            }

            // Load Settings & Permissions
            const permissions = permissionService.buildPermissions(userContext);
            const settings = userSettingsService.buildSettings(userContext);

            store.dispatch(new PermissionsActions.Update({ permissions }));
            store.dispatch(new UserSettingsActions.Update({ settings }));
            store.dispatch(new UserLoginDataActions.Loaded({ loginTimestamp: userLoginDataService.getLoginTimestamp() }));

            // Load Muni Settings
            if (permissions.canViewMunicipalDealMonitor || permissions.canViewMunicipalStreetCalendar) {
                const muniSettings = await muniUserSettingsService.getUserSettingsPromise(configuration.muniBffUrl);

                store.dispatch(new MuniUserSettingsActions.LoadSuccess({
                    muniSettings,
                    hasMmdDataPermission: permissions.hasMunicipalMmdData
                }));
            }

            if (permissions.canViewEquityDealMonitor || permissions.canViewEquityDealQuery) {
                const equitySettings = await equityUserSettingsService.getSettingsPromise(configuration.equityBffUrl);
                store.dispatch(new EquityUserSettingsActions.LoadSuccess(equitySettings));
            }
        } catch (error) {
            console.error(error);
        }
    };
}

const deps: any[] = [
    UserContextHttpService,
    MuniUserSettingsHttpService,
    PermissionService,
    UserSettingsService,
    JwtLifetimeProcessor,
    PomJwtTokenProvider,
    WebsocketGatewayJwtTokenProvider,
    JwtTokenV2Provider,
    PendoService,
    Store,
    BuysideProductIntegrationService,
    VersionCheckService,
    HttpClient,
    UserLoginDataService,
    EquityUserSettingsService
];

export const APP_INITIALIZER_PROVIDER = {
    provide: APP_INITIALIZER,
    useFactory: loadConfigurationFactory,
    deps,
    multi: true
};
