import { Injectable } from '@angular/core';

import { CacheService } from './cache.service';
import { CacheItem, delayMs } from './cache-item';
import { StorageService } from '../../services/storage.service';

const storageKeyPrefix = 'deal.fixedincome.liveupdate.notifications';

@Injectable()
export class LocalStorageCacheService extends CacheService {
    constructor(private storageService: StorageService) {
        super();

        this._initCurrentItems();
    }

    private _initCurrentItems(): void {
        if (this.currentItems !== null && this.currentItems.length > 0) {
            this.cleanUpOutdatedRecords();
        } else {
            this.currentItems = [];
        }
    }

    private cleanUpOutdatedRecords(): void {
        const nowDate = new Date();
        this.currentItems = this.currentItems.filter(i => this.isCacheOutdated(i.createdDateTime, nowDate));
    }

    private isCacheOutdated(recordDate: Date, nowDate: Date): boolean {
        const outdateTime = new Date(nowDate.getMilliseconds() + delayMs);

        return recordDate >= outdateTime;
    }

    protected set currentItems(items: CacheItem[]) {
        this.storageService.setObject(storageKeyPrefix, items);
    }

    protected get currentItems(): CacheItem[] {
        return this.storageService.getObject<CacheItem[]>(storageKeyPrefix);
    }
}
