import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { first, withLatestFrom } from 'rxjs/operators';
import { environment } from '@environments/environment';
import { select, Store } from '@ngrx/store';
import * as fromRoot from '../../reducers';
import * as AuthApiActions from '@core/@state/actions/auth-api.actions';
import { WINDOW } from '@core/services/window.service';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class VersionCheckService {
    // this will be replaced by actual hash post-build.js
    public currentHash = '{{POST_BUILD_ENTERS_HASH_HERE}}';

    private readonly VERSION_CHECK_INTERVAL_MS = 300000;

    constructor(
        private http: HttpClient,
        private store: Store<fromRoot.State>,
        @Inject(WINDOW) private window: Window
    ) {}

    public initVersionCheck(frequency: number = this.VERSION_CHECK_INTERVAL_MS): void {
        setInterval(() => {
            this.checkVersion();
        }, frequency);
    }

    public getVersion(): Observable<any> {
        return this.http.get(environment.assetsURL + 'version.json' + '?t=' + new Date().getTime());
    }

    private checkVersion(): void {
        this.getVersion().pipe(
            first(),
            withLatestFrom(this.store.pipe(select(fromRoot.getIsLoggedIn)))
        )
            .subscribe(
                ([version, isLoggedIn]: [{ hash: string }, boolean]) => {
                    const hash = version.hash;
                    const hashChanged = this.hasHashChanged(this.currentHash, hash);

                    if (hashChanged && isLoggedIn) {
                        this.store.dispatch(new AuthApiActions.Logout());
                    }

                    if (hashChanged && !isLoggedIn) {
                        this.window.location.reload();
                    }
                },
                (err) => {
                    console.error(err, 'Could not get latest version');
                }
            );
    }

    public hasHashChanged(currentHash: string, newHash: string): boolean {
        if (!currentHash || currentHash === '{{POST_BUILD_ENTERS_HASH_HERE}}') {
            return false;
        }

        if (!environment.production) {
            console.groupCollapsed('UI version check');
            console.log('Browser UI version hash: ', currentHash);
            console.log('Server UI version hash: ', newHash);
            console.log('Browser UI version is up-to-date', currentHash === newHash);
            console.groupEnd();
        }

        return currentHash !== newHash;
    }
}
