import { Injectable } from '@angular/core';

import { IntlService } from '@progress/kendo-angular-intl';

import '@progress/kendo-angular-intl/locales/en/numbers';
import '@progress/kendo-angular-intl/locales/fr/numbers';
import { NumberFormat } from './models/number-format';

@Injectable()
export class NumberFormatService {

    constructor(public intl: IntlService) {
    }

    public format = (number: number, format: NumberFormat, minPrecision?: number) => {
        const precision = this.calculatePrecision(number, minPrecision);

        return this.applyFormatting(number, format, precision);
    }

    private applyFormatting(number: number, format: NumberFormat, precision: number): string {

        const options = {
            minimumFractionDigits: precision,
            maximumFractionDigits: precision,
            useGrouping: true
        };
        let localId: string;

        switch (format) {
            case NumberFormat.format1:
                // Format is 1 000 000,99
                localId = 'fr';
                options.useGrouping = true;
                break;
            case NumberFormat.format2:
                // Format is 1000000,99
                localId = 'fr';
                options.useGrouping = false;
                break;
            case NumberFormat.format0:
            default:
                // Format is 1,000,000.99
                localId = 'en';
                options.useGrouping = true;
                break;
        }

        return this.intl.formatNumber(number, options, localId);
    }

    private calculatePrecision(number: number, minPrecision?: number): number {
        const precision = this.getPrecision(number);

        if (minPrecision && precision < minPrecision) {
            return minPrecision;
        }

        return precision;
    }

    private getPrecision(number: number): number {
        const match = ('' + number).match(/(?:\.(\d+))?(?:[eE]([+-]?\d+))?$/);

        if (!match) {
            return 0;
        }

        return Math.max(
             0,
             // Number of digits right of decimal point.
             (match[1] ? match[1].length : 0)
             // Adjust for scientific notation.
             - (match[2] ? +match[2] : 0));
    }
}
