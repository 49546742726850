export enum ClientLogLevel {
    All = 'all',
    Debug = 'debug',
    Info = 'info',
    Warn = 'warn',
    Error = 'error',
    Fatal = 'fatal',
    Off = 'off'
}

export interface LogEntry {
    logId: string;
}

export interface HttpResponseLog extends LogEntry {
    entryDate: Date;
    url: string;
    method: string;
    message: string;
    status: number;
    correlationId: string;
    responseCorrelationId: string;
    response: string;
    requestUrl: string;
}

export interface ClientLogEntry<T extends ClientLogExtraInfo> {
    entryDate: Date;
    message: string;
    level: ClientLogLevel;
    extraInfo: T;
}

export interface ClientLogExtraInfo {
    stackTrace: string;
}

export interface ClientExtendedExtraInfo extends ClientLogExtraInfo {
    version: string;
    userAgent: string;
    href: string;
    ngrxActions: string[];
    elementsClicked: string[];
    httpRequests: string[];
    authInfo: {
        loggedInAt: Date;
        type: 'Logged In' | 'Session Restored'
    };
    tabId: string;
    isHidden: boolean;
    localStorage: ClientLocalStorageExtraInfo;
    pomJwtTokenExpiresAt: Date;
    wsGatewayTokenExpiresAt: Date;
    jwtTokenV2ExpiresAt: Date;
    isOnline: boolean;
}

export interface ClientLocalStorageExtraInfo {
    idleStartedAt: Date;
    isSessionTimedOut: boolean;
}
