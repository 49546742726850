import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';

import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';

import { select, Store } from '@ngrx/store';
import * as fromRoot from '../../reducers';

@Injectable({
    providedIn: 'root',
})
export class MuniStreetCalendarGuard implements CanActivate {

    constructor(private store: Store<fromRoot.State>, private router: Router) {}

    public canActivate(): Observable<boolean> {
        return this.store.pipe(
            select(fromRoot.getPermissionsState),
            tap(permissions => {
                if (!permissions.canViewMunicipalStreetCalendar && permissions.canViewMunicipalDealMonitor) {
                    this.router.navigate(['/muni-deals/manager']);
                }
            }),
            map(permissions => {
                return permissions.canViewMunicipalStreetCalendar;
            })
        );
    }

}
