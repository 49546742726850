export const correlationIdRequestHeader = 'X-CORRELATION-ID';
export const xsrfTokenRequestHeader = 'X-XSRF-TOKEN';
export const jwtTokenRequestHeader = 'Authorization';

export const noLogHeader = 'INTERCEPTOR-ONLY-NO-LOG';

/*
    Example of usage:

    - Ignore all codes
        new HttpHeaders().set(noErrorHandlingForHeader, '*');

    - Ignore only 400 error code
        new HttpHeaders().set(noErrorHandlingForHeader, '400');

    - Ignore only 403 and 405 error code
        new HttpHeaders().set(noErrorHandlingForHeader, ['403', '405']);
*/
export const noErrorHandlingForHeader = 'INTERCEPTOR-ONLY-NO-ERROR-HANDLING-FOR';
