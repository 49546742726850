import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { AuthApiActions, UserSettingsActions } from '@core/@state/actions';
import { UserProfile } from '@core/user-settings/user-settings.model';
import { environment } from '@environments/environment';
import { select, Store } from '@ngrx/store';
import { AutoUnsubscribe } from 'ngx-auto-unsubscribe';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import * as fromRoot from '../../../../reducers';
import { WarningType } from '../../../login/models/warning-types.model';
import { ChangePasswordCredentials, UserCredentials } from '../../../policies/models/user-credentials.model';
import { PasswordCriteria } from '../../models/password.criteria.model';
import { ValidationService } from '../../services/validation.service';
import { StorageService } from '@core/services/storage.service';
import { UserContextHttpService } from '@core/usercontext/user-context-http.service';
import { UserSettingsService } from '@core/user-settings/user-settings.service';

@AutoUnsubscribe()
@Component({
    selector: 'app-change-password-form',
    templateUrl: './change-password-form.component.html',
    styleUrls: ['./change-password-form.component.scss'],
})
export class ChangePasswordFormComponent implements OnInit, OnDestroy {
    @Input() public isModal: boolean;
    @Output() public cancel = new EventEmitter();

    @ViewChild('changePasswordForm', { static: true })
    public changePasswordForm: HTMLFormElement;

    public isLoading$: Observable<boolean>;
    public warningType$: Observable<WarningType>;
    public assetsURL = environment.assetsURL;
    public userSettings: UserProfile;

    public userData = {
        currentPassword: '',
        newPassword: '',
        confirmNewPassword: '',
    } as UserCredentials;

    public warningTypes = WarningType;
    public passwordStrengthCriteria = {
        minCharacters: false,
        uppercaseCharacter: false,
        lowercaseCharacter: false,
        numbersPresent: false,
        specialCharacter: false,
    } as PasswordCriteria;

    constructor(
        private store: Store<fromRoot.State>,
        private validationService: ValidationService,
        private storageService: StorageService,
        private userContextHttpService: UserContextHttpService,
        private userSettingsService: UserSettingsService
    ) {}

    public ngOnInit(): void {
        this.isLoading$ = this.store.pipe(
            select(fromRoot.getAuthState),
            tap((data) => {
                if (data.changePassword.isSuccess) {
                    this.cancelPasswordChange();
                    this.updateUserProfileAfterPasswordChange();
                }
            }),
            map((data) => data.changePassword.isLoading)
        );
        this.warningType$ = this.store.pipe(
            select(fromRoot.getAuthState),
            map((data) => data.changePassword.warningType)
        );
        this.store.select(fromRoot.getUserProfile).subscribe((user) => {
            this.userSettings = user;
        });
    }

    public onCurrentPasswordChange(password: string): void {
        this.setFormErrors();
    }

    public onPasswordChange(password: string): void {
        this.passwordStrengthCriteria = this.validationService.checkPasswordStrength(password);
        this.setFormErrors();
    }

    public onConfirmPasswordChange(password: string): void {
        this.setFormErrors();
    }

    public onSubmit(): void {
        const credentials = {
            newPassword: this.userData.newPassword,
            oldPassword: this.userData.currentPassword,
        } as ChangePasswordCredentials;

        this.store.dispatch(new AuthApiActions.ChangePassword(credentials));
    }

    public setFormErrors(): void {
        if (this.userData.newPassword !== this.userData.confirmNewPassword) {
            this.changePasswordForm.form.controls['confirmNewPassword'].setErrors({ notMatch: true });
        } else {
            this.changePasswordForm.form.controls['confirmNewPassword'].updateValueAndValidity();
        }

        if (this.userData.newPassword === this.userData.currentPassword) {
            this.changePasswordForm.form.controls['newPassword'].setErrors({
                currentPasswordMatch: true,
            });
        } else {
            this.changePasswordForm.form.controls['newPassword'].updateValueAndValidity();
        }

        if (!this.validationService.isCriteriaFullfiled(this.passwordStrengthCriteria)) {
            this.changePasswordForm.form.controls['newPassword'].setErrors({
                PasswordCrieteriaNotFullfiled: true,
            });
        } else if (!this.passwordStrengthCriteria.allowedSpecialCharacters || this.passwordStrengthCriteria.specialCharacter) {
            this.changePasswordForm.form.controls['newPassword'].setErrors({
                PasswordContainsRestrictedSpecialChar: true,
            });
        } else if (this.validationService.isPasswordContainsRepeatChars(this.userData.newPassword)) {
            this.changePasswordForm.form.controls['newPassword'].setErrors({
                PasswordRepeatedCharacters: true,
            });
        } else if (this.validationService.isPasswordContainsFirstLastName(this.userSettings, this.userData.newPassword)) {
            this.changePasswordForm.form.controls['newPassword'].setErrors({
                PasswordContainsUserName: true,
            });
        } else if (this.validationService.isPasswordContainsUserName(this.userSettings, this.userData.newPassword)) {
            this.changePasswordForm.form.controls['newPassword'].setErrors({
                PasswordContainsUserIdOrEmail: true,
            });
        }
    }

    public async updateUserProfileAfterPasswordChange() {
        const userHttpContext = await this.userContextHttpService.load();
        if (!userHttpContext) {
            return;
        }
        const userContext = this.userContextHttpService.buildUserContext(userHttpContext);
        const settings = this.userSettingsService.buildSettings(userContext);
        this.store.dispatch(new UserSettingsActions.UpdateProfile(settings.profile));
    }

    public cancelPasswordChange(): void {
        this.cancel.emit();
    }

    public ngOnDestroy(): void {
        this.store.dispatch(new AuthApiActions.ChangePasswordStateReset());
    }
}
