<div *ngIf="isLoading"  class="c-loader-full-page-backdrop c-loader-visible">
    <div class="c-full-page-loader"></div>
</div>
<div *ngIf="!isLoading && acceptTermsOfUseRequired" class="accept-terms policy">
    <div class="c-text-center c-m-bottom-md c-m-top-md">
        <h1 class="c-header-sm c-m-bottom-0 policy__e2e--title">IHS Markit Terms Of Use</h1>
        <h2 class="c-header-sm c-m-bottom-0 policy__e2e--subtitle" *ngIf="acceptTermsOfUseRequired">Please read and acknowledge the following terms</h2>
    </div>

    <div [innerHTML]="termsOfUseText"
        class="policy__text policy__e2e--text c-bg-white c-text-sm c-text-gray-9"></div>

    <form class="accept-terms__form">
        <div class="c-row">
            <div class="c-col-12 accept-terms__form-terms">
                <div class="c-text-sm c-text-gray-9 accept-terms__form-checkbox">
                    <div class="c-checkbox c-checkbox-primary">
                        <input type="checkbox" #accept id="acceptCheckBox" class="accept-terms__e2e--accept-checkbox">
                        <label for="acceptCheckBox"></label>
                    </div>
                </div>
                <div class="c-text-sm c-text-gray-9">
                    By clicking “<strong>Accept</strong>” below, you are consenting indefinitely to
                    IHS Markit delivering any offering documents of any issuer to you through electronic delivery to any
                    website associated with IHS Markit, where you can access and print the documents. You will receive an email
                    or other alert notifying you of any such delivery. Your consent covers each of your accounts with IHS Markit and will be
                    effective until you withdraw it. You may revoke your consent to electronic delivery, in whole but not in part, at any time by contacting an
                    IHS Markit customer service representative (<a [attr.href]="supportGroupLink" class="c-a">{{supportGroupMail}}</a>)
                    You represent and warrant that you have all required authority, either in your individual capacity (if you are acting as an individual)
                    or on behalf of your company (if you are acting on behalf of your company), to provide this consent and that you have agreed to the above terms and conditions.
                    You should print or otherwise retain a copy of this consent for your records.<br/><br/>Also, by clicking “<strong>Accept</strong>” below,
                    you are consenting to the use of cookies as described in our
                    <a href="https://ihsmarkit.com/legal/privacy-policy.html" class="c-a" rel="noreferrer" target="_blank">Privacy Policy</a>,
                    and your agreement to our Terms of Use.<br/><br/>
                </div>
            </div>
        </div>

        <div class="c-row">
            <div class="c-col-12">
                <button
                    type="submit"
                    class="c-btn c-btn-primary accept-terms__e2e--accept"
                    (click)="onAccept()"
                    [disabled]="!accept.checked">Accept</button>
            </div>
        </div>
    </form>

</div>
<app-change-password *ngIf="passwordChangeRequired"></app-change-password>

