import { OnDestroy, Injectable } from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import { delay } from 'rxjs/operators';

import { CacheItem, delayMs } from './cache-item';

@Injectable()
export abstract class CacheService implements OnDestroy {
    private cacheQueue: Subject<CacheItem> = new Subject();
    private readonly subscription: Subscription;

    constructor() {
        this.subscription = this.cacheQueue.pipe(delay(delayMs))
            .subscribe(value => this._removeItem(value));
    }

    public ngOnDestroy(): void {
        if (!!this.subscription) {
            this.subscription.unsubscribe();
        }
    }

    public isValueInCache(key: string): boolean {
        return this.currentItems.find(i => i.value === key) !== undefined;
    }

    public push(key: string): void {
        const notification: CacheItem = {
            value: key,
            createdDateTime: new Date()
        };

        this.cacheQueue.next(notification);
        this._saveToStorage(notification);
    }

    protected abstract set currentItems(items: CacheItem[]);
    protected abstract get currentItems(): CacheItem[];

    private _removeItem({ value }: CacheItem): void {
        this.currentItems = this.currentItems.filter(i => i.value !== value);
    }

    private _saveToStorage(item: CacheItem): void {
        const currentItems = this.currentItems;
        currentItems.push(item);
        this.currentItems = currentItems;
    }
}
