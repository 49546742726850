import { Component, Inject, OnDestroy } from '@angular/core';

import { Store } from '@ngrx/store';
import * as fromRoot from '../../../reducers';

import { AutoUnsubscribe } from 'ngx-auto-unsubscribe';
import * as LayoutActions from '@core/@state/actions/layout.actions';
import { WINDOW } from '@core/services/window.service';

@AutoUnsubscribe()
@Component({
  selector: 'app-disconnect-alert',
  templateUrl: './disconnect-alert.component.html',
  styleUrls: ['./disconnect-alert.component.scss']
})
export class DisconnectAlertComponent implements OnDestroy {

    constructor(
        private store: Store<fromRoot.State>,
        @Inject(WINDOW) private window: Window
    ) {}

    public close(): void {
        this.store.dispatch(new LayoutActions.HideDisconnectAlert());
    }

    public refresh(): void {
        this.window.location.reload();
    }

    public ngOnDestroy(): void {}

}
