export enum EmailUpdateWarnings {
    EmailInUse = 'Email already in use',
    InvalidFormat = 'Invalid email format',
    InternalServerError = 'Failed to add new email. An internal server error occurred.'
}

export enum PhoneNumberUpdateWarnings {
    PhoneNumberInUse = 'Phone number already in use',
    InternalServerError = 'Failed to add new phone number. An internal server error occurred.'
}

export const PROFILE_UPDATE_WARNING = 'Failed to update profile. An internal server error occurred.';
