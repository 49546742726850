<div class="modal-bg">
    <div class="modal-wrapper">
        <div class="modal-container change-password">
            <div class="c-card change-password__content">
                <div class="c-card-content">
                    <app-change-password-form
                        [isModal]="true"
                    ></app-change-password-form>
                </div>
            </div><!-- /.c-card -->
        </div><!-- /.modal-container -->
    </div><!-- /.modal-wrapper -->
</div>
