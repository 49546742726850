import { Component, Input } from '@angular/core';
import { AlertType } from './alert-type.model';

@Component({
  selector: 'app-alert',
  templateUrl: './alert.component.html',
  styleUrls: ['./alert.component.scss'],
})

export class AlertComponent {

    constructor() {}

    @Input() public type: AlertType;
    public AlertType  = AlertType;
}
