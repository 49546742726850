import { Action } from '@ngrx/store';
import { Permissions } from '../../permissions/permissions.model';

export enum PermissionsActionTypes {
    Update = '[Permissions] Update',
    Clear  = '[Permissions] Clear'
}

export class Update implements Action {
    public readonly type = PermissionsActionTypes.Update;

    constructor(public payload: { permissions: Permissions }) {}
}

export class Clear implements Action {
    public readonly type = PermissionsActionTypes.Clear;

    constructor() {}
}

export type PermissionsActionsUnion =
    | Update
    | Clear;
