import { Injectable, OnDestroy } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { AutoUnsubscribe } from 'ngx-auto-unsubscribe';
import { Subscription } from 'rxjs';
import { map, withLatestFrom, filter } from 'rxjs/operators';

import { AssetClassModel } from '@core/models/asset-class';
import { Permissions } from '@core/permissions/permissions.model';
import { PermissionsHelperService } from '@core/permissions/permissions-helper.service';

import * as fromLayout from '@core/@state/reducers/layout.reducer';
import * as layoutActions from '@core/@state/actions/layout.actions';
import * as fromRoot from '../../reducers';

@AutoUnsubscribe()
@Injectable()
export class AssetClassService implements OnDestroy {

    private routerSubscription: Subscription;

    constructor(
        private store: Store<fromRoot.State>,
        private router: Router
    ) {
    }

    public subscribeOnAssetChange(): void {
        this.routerSubscription = this.router.events.pipe(
            filter(event => event instanceof NavigationEnd),
            withLatestFrom(
                this.store.select(fromLayout.getCurrentAssetClass),
                this.store.select(fromRoot.getPermissionsState)
            ),
            filter(([event, currentAssetClass]) => {
                if (currentAssetClass === null) {
                    return true;
                }

                const { urlAfterRedirects } = event as NavigationEnd;
                const urlBasedAssetModel = this.getAssetClassBasedOnUrl(urlAfterRedirects);

                return urlBasedAssetModel !== null && currentAssetClass !== urlBasedAssetModel;
            }),
            map(([event, _, permissions]) => {
                const { urlAfterRedirects } = event as NavigationEnd;

                return this.getAssetClassBasedOnUrl(urlAfterRedirects) || this.getDefaultAssetClass(permissions);
            })
        ).subscribe(newAssetClass => {
            this.store.dispatch(new layoutActions.SetAssetClass(newAssetClass));
        });
    }

    public ngOnDestroy(): void {
    }

    private getAssetClassBasedOnUrl(url: string): AssetClassModel | null {
        const rootUrlFragment = url.split('/')[1];

        switch (rootUrlFragment) {
            case 'deal':
            case 'fixedincome':
            case 'query':
            case 'deal-query':
            case 'activity':
            case 'compliance':
            case 'admin':
                return AssetClassModel.FixedIncome;
            case 'muni-deals':
                return AssetClassModel.Municipal;
            case 'equity':
                return AssetClassModel.Equity;
            default:
                return null;
        }
    }

    private getDefaultAssetClass(permissions: Permissions): AssetClassModel | null {
        if (PermissionsHelperService.hasMuniPermissions(permissions)) {
            return AssetClassModel.Municipal;
        }

        if (PermissionsHelperService.canViewFixedIncomeAssets(permissions)) {
            return AssetClassModel.FixedIncome;
        }

        if (PermissionsHelperService.hasEquityPermissions(permissions)) {
            return AssetClassModel.Equity;
        }

        return null;
    }
}
