import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Router } from '@angular/router';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { of } from 'rxjs';

import { AuthApiActions, UserSettingsActions } from '../actions';
import { TermsOfUseService } from '../../../modules/policies/services/terms-of-use.service';

@Injectable()
export class AcceptTermsEffect {

    constructor(
        private actions$: Actions,
        private termsOfUseService: TermsOfUseService,
        private router: Router,
    ) {}


    public loadAcceptTerms$ = createEffect(() => this.actions$.pipe(
        ofType(
            AuthApiActions.AuthApiActionTypes.TermsOfUseLoad
        ),
        switchMap(() => this.termsOfUseService.getTermsOfUse()
            .pipe(
                map((data) => new AuthApiActions.TermsOfUseLoadSuccess(data)),
                catchError((error: any) => of(new AuthApiActions.TermsOfUseLoadFailed(error)))
            )
        )
    ));


    public confirmTerms$ = createEffect(() => this.actions$.pipe(
        ofType(
            AuthApiActions.AuthApiActionTypes.TermsOfUseConfirm
        ),
        switchMap((action: any) => this.termsOfUseService.acceptTermsOfUse(action.payload)
            .pipe(
                map(() => new AuthApiActions.TermsOfUseConfirmSuccess()),
                catchError((error: any) => of(new AuthApiActions.TermsOfUseConfirmFailed(error)))
            )
        )
    ));


    public confirmTermsSuccess$ = createEffect(() => this.actions$.pipe(
        ofType(
            AuthApiActions.AuthApiActionTypes.TermsOfUseConfirmSuccess
        ),
        switchMap(() =>
            of(new UserSettingsActions.TermsOfUseConfirmed())
        ),
        tap(() => {
            this.router.navigate(['/login/confirmation']);
        })
    ));

}
