<div
    class="password-criteria c-text-md"
    [ngClass]="{
        'c-text-gray-6': !isFullfiled,
        'c-text-success': isFullfiled
    }"
>
    <i class="fa fa-circle password-criteria__icon c-m-right-sm"
        [ngClass]="{
            'fa-circle': !isFullfiled,
            'fa-check-circle': isFullfiled
        }"
    ></i><span>&nbsp;{{criteriaName}}</span>
</div>
