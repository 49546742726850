import { Injectable } from '@angular/core';
import { createEffect, Actions, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { of } from 'rxjs';
import { concatMap, map, catchError, withLatestFrom } from 'rxjs/operators';
import {
    getMessagesStatusStoreState,
    getConfigStoreState,
    State,
} from '../reducer';
import { AlertsService } from '../../services/alerts.service';
import {
    MessagesStatusActions,
    MessagesStatusLoadAction,
    MessagesStatusLoadFailAction,
    MessagesStatusLoadSuccessAction,
} from './actions';
import {
    MessagesActions,
    MessagesLoadSuccessAction,
} from '../messages/actions';
import { MessagesStatusState } from './state';

interface NOOP {
    type: 'EMPTY';
}

@Injectable()
export class MessagesStatusEffects {
    public loadStatus$ = createEffect(() =>
        this.actions$.pipe(
            ofType(MessagesStatusActions.Load),
            withLatestFrom(this.store.pipe(select(getConfigStoreState))),
            concatMap(([, config]) => {
                return this.alertsService.getStatus(config).pipe(
                    map((payload) => new MessagesStatusLoadSuccessAction(payload)),
                    catchError((error) => of(new MessagesStatusLoadFailAction(error)))
                );
            })
        )
    );

    public updateStatus$ = createEffect(() =>
        this.actions$.pipe(
            ofType(MessagesActions.LoadSuccess),
            withLatestFrom(this.store.pipe(select(getMessagesStatusStoreState))),
            map(([action, status]: [MessagesLoadSuccessAction, MessagesStatusState]) => {
                const newMessages = action.payload.messages;
                const numUnread = newMessages.filter((message) => !message.isRead).length;

                if (status.loaded && !status.loading && numUnread > status.unreadCount) {
                    return new MessagesStatusLoadAction();
                } else {
                    return { type: 'EMPTY' } as NOOP;
                }
            })
        )
    );

    constructor(
        private actions$: Actions,
        private alertsService: AlertsService,
        private store: Store<State>
    ) {
    }
}
