import { ChangeDetectionStrategy, Component, ViewChild, ElementRef, HostListener, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Observable, Subscription } from 'rxjs';

import * as fromRoot from '../../../../reducers';
import * as fromLayout from '@core/@state/reducers/layout.reducer';
import * as AuthApiActions from '@core/@state/actions/auth-api.actions';
import { AssetClassModel, ASSET_CLASSES } from '@core/models/asset-class';
import { RouterLinks, RoutingHelperService } from '@core/services/routing-helper.service';
import { map } from 'rxjs/operators';


@Component({
    selector: 'app-user-menu',
    templateUrl: './user-menu.component.html',
    styleUrls: [ './user-menu.component.scss' ],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class UserMenuComponent implements OnInit {
    private currentAssetClassSubscription: Subscription;

    public isUserSettingsDropdownOpen = false;
    public userName$: Observable<string>;
    public userEmail$: Observable<string>;
    public getCanViewFixedIncomePages$: Observable<boolean>;
    public getIsOnlyEquityPublicDealsPermissions$: Observable<boolean>;
    public currentAssetClass: AssetClassModel;
    public routes: RouterLinks;
    public userInitials$: Observable<string>;

    constructor(
        private store: Store<fromRoot.State>
    ) {}

    @ViewChild('userSettingsDropdownEl', { static: true })
    private userSettingsDropdownEl: ElementRef;

    @HostListener('document:click', ['$event'])
    public documentClick(event: Event): void {
        if (!this.userSettingsDropdownEl.nativeElement.contains(event.target)) {
            this.isUserSettingsDropdownOpen = false;
        }
    }

    public ngOnInit(): void {
        this.routes = RoutingHelperService.ROUTER_LINKS;

        this.userName$ = this.store.pipe(select(fromRoot.getUserName));
        this.userEmail$ = this.store.pipe(select(fromRoot.getUserEmail));

        this.getCanViewFixedIncomePages$ = this.store.pipe(
            select(fromRoot.getCanViewFixedIncomePages)
        );

        this.getIsOnlyEquityPublicDealsPermissions$ = this.store.pipe(
            select(fromRoot.getIsOnlyEquityPublicDealsPermissions)
        );

        this.currentAssetClassSubscription = this.store.select(fromLayout.getCurrentAssetClass)
            .subscribe(assetClassModel => {
                const assetClass = ASSET_CLASSES.find(ac => ac.model === assetClassModel);

                if (!assetClass) {
                    console.error('Cannot find asset class');
                    console.error(assetClassModel, this.currentAssetClass);
                }

                this.currentAssetClass = assetClass ? assetClass.model : ASSET_CLASSES[0].model;
            });

        this.userName$ = this.store.pipe(
            select(fromRoot.getUserProfile),
            map(profile => {
                return `${profile.firstName} ${profile.lastName}`
            })
        );

        this.userInitials$ = this.store.pipe(
            select(fromRoot.getUserProfile),
            map(profile => {
                if (profile.lastName.includes(' ')) {
                    const lastNameSplit = profile.lastName.split(' ');

                    return profile.firstName[0].toUpperCase() + lastNameSplit[lastNameSplit.length - 1][0].toUpperCase();
                } else {
                    return profile.firstName[0].toUpperCase() + profile.lastName[0].toUpperCase()
                }
            })
        );
    }

    public logout(): void {
        this.store.dispatch(new AuthApiActions.Logout());
    }

    public formatUserSettingsLink(): string {
        return RoutingHelperService.getAssetClassLink(this.currentAssetClass) + this.routes.userSettings;
    }

    public formatSupportLink(): string {
        return RoutingHelperService.getAssetClassLink(this.currentAssetClass) + this.routes.support;
    }

    public isEquityRoute(): boolean {
        return this.currentAssetClass === AssetClassModel.Equity;
    }
}
