<div class="policy">
    <div class="c-text-center c-m-bottom-md c-m-top-md">
        <h1 class="c-header-sm c-m-bottom-0 policy__e2e--title">Disclaimers</h1>
    </div>

    <div class="policy__text policy__e2e--text c-bg-white c-text-sm c-text-gray-9">
        <p>
            Ipreo is not registered as a broker or dealer with the U.S. Securities and Exchange Commission (SEC) nor is it a member of a self-regulatory organization such as the Financial Industry Regulatory Authority, Inc. (FINRA) or of the Securities Investor Protection Corporation (SIPC). Outside of the UK, Ipreo does not engage in any activities that will require it to register as a broker or dealer in the jurisdiction in which such services are performed. Ipreo Limited is authorized and regulated by the UK Financial Conduct Authority under reference number 775369 on the Financial Services Register at <a class="c-a" href="http://www.fca.org.uk/register/" target="_blank">http://www.fca.org.uk/register/</a>.
        </p>
    </div>
</div>
