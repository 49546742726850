import { Component, OnInit, OnDestroy } from '@angular/core';

import { Subscription } from 'rxjs';
import { map } from 'rxjs/operators';

import { select, Store } from '@ngrx/store';
import * as fromRoot from '../../../../reducers';
import * as fromLayout from '@core/@state/reducers/layout.reducer';
import { AuthApiActions } from '@core/@state/actions';

import {RouterLinks, RoutingHelperService} from '@core/services/routing-helper.service';
import { AssetClassModel } from '@core/models/asset-class';
import { AcceptTermsCredentials } from '../../models/user-credentials.model';

import { AutoUnsubscribe } from 'ngx-auto-unsubscribe';

@AutoUnsubscribe()
@Component({
    selector: 'app-accept-terms',
    templateUrl: './accept-terms.component.html',
    styleUrls: ['./accept-terms.component.scss']
})
export class AcceptTermsComponent implements OnInit, OnDestroy {

    public acceptTermsOfUseRequired: boolean;
    public passwordChangeRequired: boolean;
    public termsOfUseText: string;
    public currentAssetClass: AssetClassModel;
    public supportGroupLink: string;
    public supportGroupMail: string;
    public routes: RouterLinks;
    public userId: string;
    public isLoading: boolean;

    private userProfileSubscription: Subscription;
    private currentAssetClassSubscription: Subscription;
    private termsOfUseSubscription: Subscription;
    private supportGroupSubscription: Subscription;
    private termsOfUseId: string;

    constructor(private store: Store<fromRoot.State>) {
    }

    public ngOnInit(): void {
        this.routes = RoutingHelperService.ROUTER_LINKS;

        this.store.dispatch(new AuthApiActions.TermsOfUseLoad());

        this.termsOfUseSubscription = this.store.pipe(
            select(fromRoot.getAuthState),
            map(data => data.termsOfUse)
        ).subscribe((data) => {
            this.termsOfUseId = data.id;
            this.termsOfUseText = data.text;
            this.isLoading = data.isLoading;
        });

        this.currentAssetClassSubscription = this.store.pipe(select(fromLayout.getCurrentAssetClass))
            .subscribe(assetClass => this.currentAssetClass = assetClass);

        this.supportGroupSubscription = this.store.pipe(
            select(fromRoot.getSupportGroupEmail)
        ).subscribe((data) => {
            this.supportGroupMail = data;
            this.supportGroupLink = 'mailto:' + data;
        });

        this.userProfileSubscription = this.store.pipe(
            select(fromRoot.getUserProfile)
        ).subscribe((data) => {
            this.acceptTermsOfUseRequired = data.acceptTermsOfUseRequired;
            this.passwordChangeRequired = data.passwordChangeRequired;
            this.userId = data.id;
        });
    }

    public onAccept(): void {
        const userCredentials = {
            userId: this.userId,
            termsOfUseId: this.termsOfUseId
        } as AcceptTermsCredentials;

        this.store.dispatch(new AuthApiActions.TermsOfUseConfirm(userCredentials));
    }

    public ngOnDestroy(): void { }
}
