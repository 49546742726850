export enum DealUpdateType {
    Message = 'Message',
    BookState = 'BookState',
    InvestorAccess = 'InvestorAccess',
    Order = 'Order',
    HedgeBookState = 'HedgeBookState',
    FinalTradeTerms = 'FinalTraceTerms',
    DealComment = 'DealComment',
    NsOrder = 'NsOrder',
    CurrentLevel = 'CurrentLevel',
    InternalOrder = 'InternalOrder',
    DistributionBookState = 'DistributionBookState',
    UnderwritingBookState = 'UnderwritingBookState',
    BnD = 'BnD'
}

export enum EquityDealUpdateType {
    BookState = 'BookState',
    DealState = 'DealState',
    DealDetails = 'DealDetails'
}
