import { Injectable } from '@angular/core';
import {
    HttpEvent,
    HttpHandler,
    HttpInterceptor,
    HttpRequest
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { timeout } from 'rxjs/operators';

@Injectable()
export class TimeoutInterceptor implements HttpInterceptor {
    private readonly DEFAULT_TIMEOUT_MS = 120000;

    constructor() {}

    public intercept(
        req: HttpRequest<any>,
        next: HttpHandler
    ): Observable<HttpEvent<any>> {
        const timeoutValue = Number(req.headers.get('timeout')) || this.DEFAULT_TIMEOUT_MS;

        return next.handle(req).pipe(timeout(timeoutValue));
    }
}
