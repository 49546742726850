import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { select, Store } from '@ngrx/store';
import * as fromRoot from '../../reducers';

@Injectable({
    providedIn: 'root',
})
export class MuniDealMonitorGuard implements CanActivate {

    constructor(private store: Store<fromRoot.State>) {
    }

    public canActivate(): Observable<boolean> {
        return this.store.pipe(
           select(fromRoot.getPermissionsState),
           map(permissionsState => permissionsState.canViewMunicipalDealMonitor)
        );
    }
}
