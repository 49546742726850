import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule } from '@angular/forms';

import { NorthstarAlertsModule } from '../../modules/northstar-alerts/module';
import { FooterComponent } from './footer/footer.component';
import { HeaderComponent } from './header/header.component';
import { UserMenuComponent } from './header/user-menu/user-menu.component';
import { ProductSwitcherComponent } from './header/product-switcher/product-switcher.component';
import { HeaderTabsComponent } from './header/header-tabs/header-tabs.component';
import { AlertComponent } from './shared/alert/alert.component';
import { ModalComponent } from './shared/modal/modal.component';
import { DynamicDropdownComponent } from './dynamic-dropdown/dynamic-dropdown.component';
import { DisconnectAlertComponent } from '@core/core-components/disconnect-alert/disconnect-alert.component';
import { FooterModule } from '@ipreo/ngx-sprinkles';

@NgModule({
    imports: [
        CommonModule,
        RouterModule,
        FormsModule,
        NorthstarAlertsModule,
        FooterModule
    ],
    declarations: [
        FooterComponent,
        HeaderComponent,
        HeaderTabsComponent,
        AlertComponent,
        ModalComponent,
        UserMenuComponent,
        ProductSwitcherComponent,
        DynamicDropdownComponent,
        DisconnectAlertComponent
    ],
    exports: [
        FooterComponent,
        HeaderComponent,
        HeaderTabsComponent,
        AlertComponent,
        ModalComponent,
        DynamicDropdownComponent,
        DisconnectAlertComponent
    ]
})
export class CoreComponentsModule { }
