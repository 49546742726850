import { Action } from '@ngrx/store';

import {UserProfile, UserSettings} from '../../user-settings/user-settings.model';

export enum SettingsActionTypes {
    Update                  = '[UserSettings] Update',
    UpdateSetting           = '[UserSettings] Update Setting',
    UpdateHomePage          = '[UserSettings] Update Home Page',
    UpdateIsAccessingFromUS = '[UserSettings] Update Is Accessing From US',

    ToggleHideEmptyFields   = '[UserSettings] Toggle Hide Empty Fields',
    ToggleHighlightChanges  = '[UserSettings] Toggle Highlight Changes',

    SyncUserSettings        = '[UserSettings] Sync',
    SyncUserSettingsSuccess = '[UserSettings] Sync Success',
    SyncUserSettingsFailed  = '[UserSettings] Sync Failed',

    TermsOfUseConfirmed     = '[UserSettings] Terms Of Use Confirmed',

    PasswordChange          = '[UserSettings] Password Change',

    Clear                   = '[UserSettings] Clear',

    UpdateProfile           = '[UserSettings] Update profile'
}

export class Update implements Action {
    public readonly type = SettingsActionTypes.Update;

    constructor(public payload: { settings: UserSettings }) {}
}

export class UpdateSetting implements Action {
    public readonly type = SettingsActionTypes.UpdateSetting;

    constructor(public payload: { setting: any }) {}
}

export class UpdateHomePage implements Action {
    public readonly type = SettingsActionTypes.UpdateHomePage;

    constructor(public payload: { homePage: string }) {}
}

export class UpdateIsAccessingFromUS implements Action {
    public readonly type = SettingsActionTypes.UpdateIsAccessingFromUS;

    constructor(public payload: { isAccessingFromUS: boolean }) {}
}

export class ToggleHideEmptyFields implements Action {
    public readonly type = SettingsActionTypes.ToggleHideEmptyFields;

    constructor(public payload: { hideEmptyFields: boolean }) {}
}

export class ToggleHighlightChanges implements Action {
    public readonly type = SettingsActionTypes.ToggleHighlightChanges;

    constructor(public payload: { showChanges: boolean }) {}
}

export class SyncUserSettings implements Action {
    public readonly type = SettingsActionTypes.SyncUserSettings;

    constructor() {}
}

export class SyncUserSettingsSuccess implements Action {
    public readonly type = SettingsActionTypes.SyncUserSettingsSuccess;

    constructor() {}
}

export class SyncUserSettingsFailed implements Action {
    public readonly type = SettingsActionTypes.SyncUserSettingsFailed;

    constructor(public payload: any) {}
}

export class TermsOfUseConfirmed implements Action {
    public readonly type = SettingsActionTypes.TermsOfUseConfirmed;

    constructor() {}
}

export class PasswordChange implements Action {
    public readonly type = SettingsActionTypes.PasswordChange;

    constructor() {}
}

export class Clear implements Action {
    public readonly type = SettingsActionTypes.Clear;

    constructor() {}
}

export class UpdateProfile implements Action {
    public readonly type = SettingsActionTypes.UpdateProfile;

    constructor(public payload: UserProfile) {}
}

export type SettingsActionsUnion =
    | Update
    | UpdateSetting
    | UpdateHomePage
    | UpdateIsAccessingFromUS
    | ToggleHideEmptyFields
    | ToggleHighlightChanges
    | SyncUserSettings
    | SyncUserSettingsSuccess
    | SyncUserSettingsFailed
    | TermsOfUseConfirmed
    | PasswordChange
    | Clear
    | UpdateProfile;
