import { HttpHandler, HttpInterceptor, HttpRequest, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { LoggerActions } from '@core/@state/actions';
import { Store } from '@ngrx/store';
import { Observable, throwError } from 'rxjs';
import { catchError, retryWhen } from 'rxjs/operators';
import * as fromRoot from '../../reducers';
import { correlationIdRequestHeader, noErrorHandlingForHeader, noLogHeader } from '../constants/http-headers';
import { HttpResponseLog } from '../logger/log.model';
import { ModalWindowService } from '../services/error-modal-window.service';
import { generateGUID, isRelativeBffUrl } from '../utils/utils';
import { retryStrategy } from '@core/utils/retry-strategy';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {

    constructor(
        private store: Store<fromRoot.State>,
        private modalWindowService: ModalWindowService) {
    }

    public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<any> {
        const noErrorHandling = req.headers.getAll(noErrorHandlingForHeader);
        const isNoLog = req.headers.has(noLogHeader);

        if (!this._shouldRetryAndProcessError(req.url)) {
            return next.handle(req).pipe(
                catchError((error: HttpErrorResponse) => {
                    if (!isNoLog) {
                        this._logHttpErrorResponse(req, error, null);
                    }

                    return throwError(error);
                }));

        }

        const retryAttemptsMap = <{ [key: string]: number }>{};

        return next.handle(req.clone({
            headers: req.headers.delete(noErrorHandlingForHeader).delete(noLogHeader)
        })).pipe(
            retryWhen(retryStrategy({
                request: req, retryAttemptsMap
            })),
            catchError((error: HttpErrorResponse) => {
                if (!isNoLog) {
                    this._logHttpErrorResponse(req, error, retryAttemptsMap);
                }

                if (this._shouldShowErrorPopup(noErrorHandling, error)) {
                    this.modalWindowService.showError(req.headers.get(correlationIdRequestHeader));
                }

                return throwError(error);
            })
        );
    }

    private _shouldRetryAndProcessError(url: string): boolean {
        if (isRelativeBffUrl(url)) {
            return true;
        }

        return url.indexOf('equity-bff') !== -1;
    }

    private _shouldShowErrorPopup(noErrorHandling: string[], error: HttpErrorResponse): boolean {
        if (noErrorHandling === null || noErrorHandling.length === 0) {
            return true;
        }

        if (noErrorHandling.indexOf('*') !== -1) {
            return false;
        }

        return noErrorHandling.indexOf(error.status.toString()) === -1;
    }

    private _logHttpErrorResponse(req: HttpRequest<any>, error: HttpErrorResponse, retryAttemptsMap: { [key: string]: number }): void {
        try {
            const correlationId = req.headers.get(correlationIdRequestHeader);
            const retriesCount = retryAttemptsMap ? retryAttemptsMap[correlationId] : 0;
            const responseLog = this._createHttpResponseLog(req.url, req.method, correlationId, retriesCount, error);
            this.store.dispatch(new LoggerActions.LogHttpResponse(responseLog));
        }
        catch (error){
            console.error('unknown error happened', error);
        }
    }

    private _createHttpResponseLog(requestUrl: string, method: string, correlationId: string, retriesCount: number, error: HttpErrorResponse): HttpResponseLog {

        return {
            entryDate: new Date(),
            logId: generateGUID(),
            method,
            message: error.message,
            response: `${error.status}; ${error.statusText}; ` +
                `Is Retried: ${!!retriesCount}; Retry Count: ${retriesCount}; ` +
                `Error: ${JSON.stringify(error.error || '')}`,
            correlationId,
            responseCorrelationId: error.headers ? error.headers.get(correlationIdRequestHeader) : null,
            status: error.status,
            url: error.url,
            requestUrl
        };
    }
}
