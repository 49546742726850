import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, switchMap } from 'rxjs/operators';
import { of } from 'rxjs';
import { AuthService } from '../../authentication/auth.service';
import { AuthApiActions, UserSettingsActions } from '../actions';

@Injectable()
export class ChangePasswordEffect {

    constructor(
        private actions$: Actions,
        private authService: AuthService
    ) {}


    public changePassword$ = createEffect(() => this.actions$.pipe(
        ofType(AuthApiActions.AuthApiActionTypes.ChangePassword),
        switchMap((action: any) => {
            return this.authService.changePassword(action.payload).pipe(
                switchMap(() => of(new AuthApiActions.ChangePasswordSuccess())),
                catchError((error: HttpErrorResponse) => {
                    return of(new AuthApiActions.ChangePasswordFailed(error));
                })
            );
        })
    ));


    public changePasswordSuccess$ = createEffect(() => this.actions$.pipe(
        ofType(AuthApiActions.AuthApiActionTypes.ChangePasswordSuccess),
        switchMap(() => {
            return of(new UserSettingsActions.PasswordChange());
        })
    ));
}
