import { BspConfiguration } from '../configuration/bsp-configuration';
import { Permissions } from '../permissions/permissions.model';

export enum AssetClassModel {
    FixedIncome = 'Fixed Income',
    Equity = 'Equity',
    Municipal = 'Municipal'
}

export interface AssetPage {
    route: string;
    isPermissioned: (permissions: Permissions, configuration: BspConfiguration) => boolean;
    label: string;
}

export interface AssetClass {
    model: AssetClassModel;
    assetPages: AssetPage[];
    enabled?: boolean;
}

export const ASSET_CLASSES: AssetClass[] = [
    {
        model: AssetClassModel.FixedIncome,
        assetPages: [
            {
                label: 'DealMonitor',
                route: '/fixedincome',
                isPermissioned: permissions => permissions.canViewFixedIncomeDealMonitor
            },
            {
                label: 'Deal Query',
                route: '/deal-query',
                isPermissioned: permissions => permissions.canViewDealQuery
            },
            {
                label: 'Activity Stream',
                route: '/activity',
                isPermissioned: permissions => permissions.canViewActivityStream
            },
            {
                label: 'Compliance',
                route: '/compliance',
                isPermissioned: permissions => permissions.canViewCompliance
            },
            {
                label: 'Analytics',
                route: '/fixedincome/analytics',
                isPermissioned: (permissions, configuration) => permissions.canViewAnalytics && configuration.analyticsQuicksightEnabled
            },
            {
                label: 'Connections',
                route: '/company-connections',
                isPermissioned: permissions => !permissions.canViewAdminTab && permissions.canViewOrders
            },
            {
                label: 'Admin',
                route: '/admin',
                isPermissioned: permissions => permissions.canViewAdminTab
            }
        ]
    },
    {
        model: AssetClassModel.Municipal,
        assetPages: [
            {
                label: 'Street Calendar',
                route: '/muni-deals/calendar',
                isPermissioned: permissions => permissions.canViewMunicipalStreetCalendar
            },
            {
                label: 'Deal Manager',
                route: '/muni-deals/manager',
                isPermissioned: permissions => permissions.canViewMunicipalDealMonitor
            }
        ]
    },
    {
        model: AssetClassModel.Equity,
        assetPages: [
            {
                label: 'DealMonitor',
                route: '/equity/deal-monitor',
                isPermissioned: permissions => permissions.canViewEquityDealMonitor
            },
            {
                label: 'Deal Query',
                route: '/equity/deal-query',
                isPermissioned: permissions => permissions.canViewEquityDealQuery
            },
            {
                label: 'Equity Public Deals',
                route: '/equity/public-deals',
                isPermissioned: permissions => permissions.canViewEquityPublicDeals
            },
            {
                label: 'Analytics',
                route: '/equity/analytics',
                isPermissioned: (permissions, configuration) => permissions.canViewEquityAnalytics && configuration.equityAnalyticsEnabled
            }
        ]
    }
];
