import { Injectable, Inject } from '@angular/core';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { tap, switchMap, catchError, finalize } from 'rxjs/operators';
import { AuthApiActions } from '../actions';

import { AuthService } from '../../authentication/auth.service';
import { Router } from '@angular/router';
import { WINDOW } from '@core/services/window.service';
import { RoutingHelperService } from '@core/services/routing-helper.service';
import { of } from 'rxjs';

@Injectable()
export class LogoutEffect {

    constructor(
        private actions$: Actions,
        private authService: AuthService,
        private router: Router,
        @Inject(WINDOW) private window: Window
    ) {}


    public logout$ = createEffect(() => this.actions$.pipe(
        ofType(
            AuthApiActions.AuthApiActionTypes.Logout
        ),
        switchMap(() =>
            this.authService.logout().pipe(
                finalize(() => {
                    this.logout();
                })
            )
        ),
        catchError((error) => of({...error, success: false}))
    ), { dispatch: false });


    public terminateSession$ = createEffect(() => this.actions$.pipe(
        ofType(
            AuthApiActions.AuthApiActionTypes.TerminateSession
        ),
        tap(() => {
            this.terminateSession();
        })
    ), { dispatch: false });

    private logout(): void {
        this.authService.clearSession();
        this.window.location.reload();
    }

    private terminateSession(): void {
        this.authService.clearSession();

        const isAnonymousAccessAllowed = RoutingHelperService.isAnonymousAccessAllowed(this.window.location.href);

        if (!isAnonymousAccessAllowed) {
            this.router.navigate(['/login']);
        } else {
            return;
        }
    }

}
