import { Component, Input, OnDestroy } from '@angular/core';

import { AlertType } from '@core/core-components/shared/alert/alert-type.model';
import { WarningType } from '../../../login/models/warning-types.model';

import { AutoUnsubscribe } from 'ngx-auto-unsubscribe';

@AutoUnsubscribe()
@Component({
    selector: 'app-change-password-warnings',
    templateUrl: './change-password-warnings.component.html',
    styleUrls: ['./change-password-warnings.component.scss']
})

export class ChangePasswordWarningsComponent implements OnDestroy {

    @Input() public warning: WarningType = null;

    public WarningType = WarningType;
    public AlertType = AlertType;

    constructor() {}

    public ngOnDestroy(): void {}
}
