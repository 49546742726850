import { Component, OnInit, OnDestroy } from '@angular/core';
import { AutoUnsubscribe } from 'ngx-auto-unsubscribe';

@AutoUnsubscribe()
@Component({
    selector: 'app-regulatory-disclosures',
    templateUrl: './regulatory-disclosures.component.html',
    styleUrls: ['./regulatory-disclosures.component.scss']
})
export class RegulatoryDisclosuresComponent implements OnInit, OnDestroy {
    public ngOnInit(): void { }

    public ngOnDestroy(): void { }
}
