import { Component, OnDestroy, Input } from '@angular/core';
import { AutoUnsubscribe } from 'ngx-auto-unsubscribe';

@AutoUnsubscribe()
@Component({
  selector: 'app-password-criteria',
  templateUrl: './password-criteria.component.html',
  styleUrls: ['./password-criteria.component.scss']
})
export class PasswordCriteriaComponent implements OnDestroy {

    @Input() public criteriaName: string;
    @Input() public isFullfiled: boolean;

    constructor() {}

    public ngOnDestroy(): void {}
}
