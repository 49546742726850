import { Action } from '@ngrx/store';
import { EquitySettingType } from '@core/user-settings/equity-user-settings-mapper.service';
import { EquityUserSettingsHttpModel, EquityUserSettingsModel } from '../../../modules/user-settings/models/equity-user-settings.model';

export enum EquitySettingsActionTypes {
    Load        = '[EquityUserSettings] Load',
    LoadSuccess = '[EquityUserSettings] Load Success',
    LoadFailed  = '[EquityUserSettings] Load Failed',

    Update      = '[EquityUserSettings] Update'
}

export class Load implements Action {
    public readonly type = EquitySettingsActionTypes.Load;

    constructor() {}
}

export class LoadFailed implements Action {
    public readonly type = EquitySettingsActionTypes.LoadFailed;

    constructor(public payload: any) {}
}

export class LoadSuccess implements Action {
    public readonly type = EquitySettingsActionTypes.LoadSuccess;

    constructor(public payload: EquityUserSettingsHttpModel[]) {}
}

export class Update implements Action {
    public readonly type = EquitySettingsActionTypes.Update;

    constructor(public payload:  {settingName: keyof EquityUserSettingsModel, settingValue: EquitySettingType}) {}
}

export type EquitySettingsActionsUnion =
    | Load
    | LoadSuccess
    | Update;
