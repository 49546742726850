import { Action } from '@ngrx/store';
import { DesktopNotification } from '@core/desktop-notifications/desktop-notification';

export enum DesktopNotificationActionTypes {

    DesktopNotificationArrived = '[DesktopNotification] DesktopNotification arrived',

}

export class DesktopNotificationArrived implements Action {
    public readonly type = DesktopNotificationActionTypes.DesktopNotificationArrived;

    constructor(public payload: DesktopNotification) {}
}
