import { Action } from '@ngrx/store';

export enum UserLoginDataActionTypes {
    Loaded = '[LoginTimestamp] Loaded'
}

export class Loaded implements Action {
    public readonly type = UserLoginDataActionTypes.Loaded;

    constructor(public payload: { loginTimestamp: number }) {}
}
export type UserLoginDataActionsUnion =
    | Loaded;
