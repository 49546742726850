import * as fromRoot from '../../reducers';

import { Injectable, OnDestroy } from '@angular/core';

import { CupcakeModalService } from '@ipreo/cupcake-components';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class ModalWindowService implements OnDestroy {
    private subscription: Subscription;

    constructor(
        private store: Store<fromRoot.State>,
        private modalService: CupcakeModalService
    ) {
    }

    public ngOnDestroy(): void {
        if (!!this.subscription) {
            this.subscription.unsubscribe();
        }
    }

    public showError(correlationId: string): void {
        this.subscription = this.store.select(fromRoot.getSupportGroupEmail)
            .subscribe(email => this._showErrorPopup(email, correlationId));
    }

    private _showErrorPopup(email: string, correlationId: string): void {
        const errorText =
            `Communication with the server failed. If this problem continues, ` +
            `please contact <a class="c-a" href="mailto:${email}">${email}</a>` +
            `<br /><br />CorrelationId = ${correlationId}`;

        this.modalService.open({
            mode: 'component',
            type: 'danger',
            title: 'An unexpected error occurred.',
            rootCssClass: 'c-modal-visible c-d-flex',
            context: {
                text: errorText,
                buttons: [
                {
                    title: 'Ok',
                    action: 'ok'
                }]
            },
            contentWidth: 600
        });
    }
}
