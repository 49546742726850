import { SupportActions } from '../actions';

export interface State {
    isLoading: boolean;
    isError: boolean;
    messageSent: boolean;
}

export const initialState: State = {
    isLoading: false,
    isError: false,
    messageSent: false
};

export function supportReducer(
    state: State = initialState,
    action: SupportActions.SupportActionsUnion
): State {
    switch (action.type) {
        case SupportActions.SupportActionTypes.SendMuniMessage:
        case SupportActions.SupportActionTypes.SendEquityMessage:
            return {
                ...state,
                isLoading: true,
                isError: false,
                messageSent: false
            };
        case SupportActions.SupportActionTypes.SendBuysideMessage:
            return {
                ...state,
                isLoading: true,
                isError: false,
                messageSent: false
            };
        case SupportActions.SupportActionTypes.SendMessageSuccess:
            return {
                ...state,
                isLoading: false,
                isError: false,
                messageSent: true
            };
        case SupportActions.SupportActionTypes.SendMessageFailed:
            return {
                ...state,
                isLoading: false,
                isError: true,
                messageSent: false
            };
        case SupportActions.SupportActionTypes.ClearMessageState:
            return {
                ...initialState
            };
        default:
            return state;
    }
}
