import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';

import { Observable } from 'rxjs';

import { generateGUID } from '../utils/utils';
import { correlationIdRequestHeader } from '../constants/http-headers';

@Injectable()
export class CorrelationIdInterceptor implements HttpInterceptor {

    public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (req.headers.has(correlationIdRequestHeader)) {

            return next.handle(req);
        }

        const newHeaders = req.headers.set(correlationIdRequestHeader, generateGUID());

        return next.handle(req.clone({
            headers: newHeaders
        }));
    }

}
