export interface PasswordCriteria {
    minCharacters: boolean;
    uppercaseCharacter: boolean;
    lowercaseCharacter: boolean;
    numbersPresent: boolean;
    specialCharacter: boolean;
    allowedSpecialCharacters?: boolean;
}

export const PASSWORD_MIN_LENGTH = 9;

export const PasswordStrengthMessage = {
    minCharacters: `${PASSWORD_MIN_LENGTH} characters minimum`,
    upperCaseChar: 'One uppercase character',
    lowerCaseChar: 'One lowercase character',
    numberPresent: 'One number',
    specialChar: 'One special character',
};

export const PasswordCrieteriaBackendValidationErrors = [
    'PasswordTooShort',
    'PasswordRequiresNonAlphanumeric',
    'PasswordRequiresDigit',
    'PasswordRequiresUpper',
];
