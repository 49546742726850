import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { TermsOfUse } from '../models/terms-of-use.model';
import { AcceptTermsCredentials } from '../models/user-credentials.model';
import { API } from '@core/constants/api';
 @Injectable({
    providedIn: 'root'
})
export class TermsOfUseService {
     constructor(
        private http: HttpClient
    ) { }

    public getTermsOfUse(): Observable<TermsOfUse> {
        return this.http.get<TermsOfUse>(API.termsOfUse.published);
    }

    public acceptTermsOfUse(credentials: AcceptTermsCredentials): Observable<TermsOfUse> {
        return this.http.post<TermsOfUse>(API.termsOfUse.accept, credentials);
    }
}
