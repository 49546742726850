import { Injectable } from '@angular/core';
import {
    HttpEvent,
    HttpHandler,
    HttpInterceptor,
    HttpRequest,
    HttpResponse
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, tap } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import * as fromRoot from '../../reducers';
import { AddHttpResponseStatus } from '@core/@state/actions/logger.actions';
import { correlationIdRequestHeader } from '../constants/http-headers';

@Injectable()
export class LoggingInterceptor implements HttpInterceptor {
    constructor(private store: Store<fromRoot.State>) {}

    public intercept(
        req: HttpRequest<any>,
        next: HttpHandler
    ): Observable<HttpEvent<any>> {
        return next.handle(req).pipe(
            filter((event: HttpEvent<any>) => event instanceof HttpResponse),
            tap((event: HttpResponse<any>) => {
                const responseCorrelationId = event.headers.get(
                    correlationIdRequestHeader
                );

                if (responseCorrelationId === null) {
                    return;
                }

                console.log({
                    eventName: 'serviceCall: HttpResponse',
                    url: event.url,
                    correlationId: responseCorrelationId,
                    status: event.status
                });

                const path = event.url.replace(/.*\/\/[^\/]*/, '');
                this.store.dispatch(new AddHttpResponseStatus({ path: `${path} ${event.status}` }));
            })
        );
    }
}
