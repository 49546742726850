import { TermsOfUse } from '../../../modules/policies/models/terms-of-use.model';
import { AcceptTermsCredentials, ChangePasswordCredentials } from '../../../modules/policies/models/user-credentials.model';
import { Action } from '@ngrx/store';
import { UserCredentials } from '../../../modules/login/models/user-credentials.model';
import { ProfileConfirmationModel } from '../../../modules/login/models/profile-confirmation.model';
import { HttpErrorResponse } from '@angular/common/http';
import { ResetPasswordToken, ResetPasswordCredentials } from '../../../modules/reset-password/models/user-credentials.model';

export enum AuthApiActionTypes {
    Login                           = '[Auth] Login',
    LoginSuccess                    = '[Auth] Login Success',
    LoginFailed                     = '[Auth] Login Failed',

    TokenFetchSuccess               = '[Auth] Token Fetch Success',
    TokenFetchFailed                = '[Auth] Token Fetch Failed',

    CheckAppVersion                 = '[Auth] Check App Version',

    ResetPasswordRequest            = '[Auth] Reset Password Requested',
    ResetPasswordRequestSuccess     = '[Auth] Reset Password Request Success',
    ResetPasswordRequestFailed      = '[Auth] Reset Password Request Failed',
    ResetPassword                   = '[Auth] Reset Password',
    ResetPasswordSuccess            = '[Auth] Reset Password Success',
    ResetPasswordFailed             = '[Auth] Reset Password Failed',
    ResetPasswordInvalid            = '[Auth] Reset Password Invalid',

    RestoreSession                  = '[Auth] Restore Session',
    TerminateSession                = '[Auth] Terminate Session',
    Logout                          = '[Auth] [Shared] Logout',

    ConfirmProfile                  = '[Auth] Confirm Profile',
    ConfirmProfileSuccess           = '[Auth] Confirm Profile Success',
    ConfirmProfileFailed            = '[Auth] Confirm Profile Failed',

    ResetPasswordStart              = '[Auth] Reset Password Started',
    VerifyResetPasswordToken        = '[Auth] Verify Reset Password Token',
    VerifyResetPasswordTokenSuccess = '[Auth] Verify Reset Password Token Success',
    VerifyResetPasswordTokenFailed  = '[Auth] Verify Reset Password Token Failed',

    ChangePassword                  = '[Auth] Change password',
    ChangePasswordSuccess           = '[Auth] Change Password Success',
    ChangePasswordFailed            = '[Auth] Change Password Failed',
    ChangePasswordStateReset        = '[Auth] Change Password State Reset',

    TermsOfUseLoad                  = '[Auth] Terms Of Use Load',
    TermsOfUseLoadSuccess           = '[Auth] Terms Of Use Load Success',
    TermsOfUseLoadFailed            = '[Auth] Terms Of Use Load Failed',
    TermsOfUseConfirm               = '[Auth] Terms Of Use Confirmation',
    TermsOfUseConfirmSuccess        = '[Auth] Terms Of Use Confirmation Success',
    TermsOfUseConfirmFailed         = '[Auth] Terms Of Use Confirmation Failed',
}

export class Login implements Action  {
    public readonly type = AuthApiActionTypes.Login;

    constructor(public payload: { userCredentials: UserCredentials, correlationId: string }) {}
}

export class LoginSuccess implements Action {
    public readonly type = AuthApiActionTypes.LoginSuccess;

    constructor(public payload: {isUserAssignedToCompanies: boolean}) {}
}

export class LoginFailed implements Action {
    public readonly type = AuthApiActionTypes.LoginFailed;

    constructor(public payload: HttpErrorResponse) {}
}

export class TokenFetchSuccess implements Action {
    public readonly type = AuthApiActionTypes.TokenFetchSuccess;

    constructor() {}
}

export class TokenFetchFailed implements Action {
    public readonly type = AuthApiActionTypes.TokenFetchFailed;

    constructor(public payload: HttpErrorResponse) {}
}

export class CheckAppVersion implements Action {
    public readonly type = AuthApiActionTypes.CheckAppVersion;

    constructor() {}
}

export class RestoreSession implements Action {
    public readonly type = AuthApiActionTypes.RestoreSession;

    constructor(public payload: { isUserProfileConfirmed: boolean }) {}
}

export class ResetPasswordRequest implements Action {
    public readonly type = AuthApiActionTypes.ResetPasswordRequest;

    constructor(public payload: string) {}
}

export class ResetPasswordRequestSuccess implements Action {
    public readonly type = AuthApiActionTypes.ResetPasswordRequestSuccess;

    constructor() {}
}

export class ResetPasswordRequestFailed implements Action {
    public readonly type = AuthApiActionTypes.ResetPasswordRequestFailed;

    constructor(public payload: HttpErrorResponse) {}
}

export class ResetPasswordInvalid implements Action {
    public readonly type = AuthApiActionTypes.ResetPasswordInvalid;

    constructor(public payload: HttpErrorResponse) {}
}
export class ResetPassword implements Action {
    public readonly type = AuthApiActionTypes.ResetPassword;

    constructor(public payload: { resetPasswordCredentials: ResetPasswordCredentials, correlationId: string }) {}
}

export class ResetPasswordSuccess implements Action {
    public readonly type = AuthApiActionTypes.ResetPasswordSuccess;

    constructor() {}
}

export class ResetPasswordFailed implements Action {
    public readonly type = AuthApiActionTypes.ResetPasswordFailed;

    constructor(public payload: HttpErrorResponse) {}
}
export class TerminateSession implements Action {
    public readonly type = AuthApiActionTypes.TerminateSession;

    constructor(public payload: { isSessionTimedOut: boolean }) {}
}

export class Logout implements Action {
    public readonly type = AuthApiActionTypes.Logout;

    constructor() {}
}

export class ConfirmProfile implements Action  {
    public readonly type = AuthApiActionTypes.ConfirmProfile;

    constructor(public payload: ProfileConfirmationModel) {}
}

export class ConfirmProfileSuccess implements Action {
    public readonly type = AuthApiActionTypes.ConfirmProfileSuccess;

    constructor(public payload: any) {}
}

export class ConfirmProfileFailed implements Action {
    public readonly type = AuthApiActionTypes.ConfirmProfileFailed;

    constructor(public payload: HttpErrorResponse) {}
}

export class ResetPasswordStart implements Action {
    public readonly type = AuthApiActionTypes.ResetPasswordStart;

    constructor(public payload: ResetPasswordToken) {}
}

export class VerifyResetPasswordToken implements Action {
    public readonly type = AuthApiActionTypes.VerifyResetPasswordToken;

    constructor(public payload: ResetPasswordToken) {}
}

export class VerifyResetPasswordTokenSuccess implements Action {
    public readonly type = AuthApiActionTypes.VerifyResetPasswordTokenSuccess;

    constructor() {}
}

export class VerifyResetPasswordTokenFailed implements Action {
    public readonly type = AuthApiActionTypes.VerifyResetPasswordTokenFailed;

    constructor(public payload: HttpErrorResponse) {}
}

export class ChangePassword implements Action {
    public readonly type = AuthApiActionTypes.ChangePassword;

    constructor(public payload: ChangePasswordCredentials) {}
}

export class ChangePasswordSuccess implements Action {
    public readonly type = AuthApiActionTypes.ChangePasswordSuccess;

    constructor() {}
}

export class ChangePasswordFailed implements Action {
    public readonly type = AuthApiActionTypes.ChangePasswordFailed;

    constructor(public payload: HttpErrorResponse) {}
}

export class ChangePasswordStateReset implements Action {
    public readonly type = AuthApiActionTypes.ChangePasswordStateReset;

    constructor() {}
}

export class TermsOfUseLoad implements Action {
    public readonly type = AuthApiActionTypes.TermsOfUseLoad;

    constructor() {}
}

export class TermsOfUseLoadSuccess implements Action {
    public readonly type = AuthApiActionTypes.TermsOfUseLoadSuccess;

    constructor(public payload: TermsOfUse) {}
}

export class TermsOfUseLoadFailed implements Action {
    public readonly type = AuthApiActionTypes.TermsOfUseLoadFailed;

    constructor(public payload: HttpErrorResponse) {}
}

export class TermsOfUseConfirm implements Action {
    public readonly type = AuthApiActionTypes.TermsOfUseConfirm;

    constructor(public payload: AcceptTermsCredentials) {}
}

export class TermsOfUseConfirmSuccess implements Action {
    public readonly type = AuthApiActionTypes.TermsOfUseConfirmSuccess;

    constructor() {}
}

export class TermsOfUseConfirmFailed implements Action {
    public readonly type = AuthApiActionTypes.TermsOfUseConfirmFailed;

    constructor(public payload: HttpErrorResponse) {}
}

export type AuthApiActionsUnion =
    | RestoreSession
    | ResetPasswordRequest
    | ResetPasswordRequestSuccess
    | ResetPasswordRequestFailed
    | ResetPassword
    | ResetPasswordSuccess
    | ResetPasswordFailed
    | ResetPasswordInvalid
    | TerminateSession
    | Login
    | LoginSuccess
    | LoginFailed
    | TokenFetchSuccess
    | TokenFetchFailed
    | CheckAppVersion
    | Logout
    | ConfirmProfile
    | ConfirmProfileSuccess
    | ConfirmProfileFailed
    | ResetPasswordStart
    | VerifyResetPasswordToken
    | VerifyResetPasswordTokenSuccess
    | VerifyResetPasswordTokenFailed
    | ChangePassword
    | ChangePasswordSuccess
    | ChangePasswordFailed
    | ChangePasswordStateReset
    | TermsOfUseLoad
    | TermsOfUseLoadSuccess
    | TermsOfUseLoadFailed
    | TermsOfUseConfirm
    | TermsOfUseConfirmSuccess
    | TermsOfUseConfirmFailed;

