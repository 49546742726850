<div class="ns-alerts" *ngIf="messagesStatus$ | async as status">
  <span *ngIf="status.loading || !status.loaded"
    class="ns-alerts-loading c-text-gray-5 fa fa-spin fa-circle-o-notch">
  </span>

  <a *ngIf="!status.loading && status.loaded" (click)="togglePane($event)">
      <span *ngIf="status.unreadCount > 0" class="ns-alerts__unread-msg-counter c-text-bold">
          {{ status.unreadCount > 999 ? '999+' : status.unreadCount }}
      </span>
      <span class="fa fa-bell" aria-hidden="true"></span>
  </a>
</div>
<app-northstar-alerts-pane *ngIf="paneVisible" [@fadeInOut]></app-northstar-alerts-pane>
