import { Injectable, EventEmitter } from '@angular/core';
import { Message } from '../types';

export const LOCAL_STORAGE_NOTIFICATIONS_KEY =
    'northstar-alerts-desktop-notifications-enabled';
@Injectable()
export class DesktopNotificationService {
    public enabled: boolean;
    public supported: boolean;
    public permission: string;
    public change = new EventEmitter();

    constructor() {
        if (!('Notification' in window)) {
            console.warn('Desktop Notifications not available in this browser');
            this.supported = false;
            this.enabled = false;
            this.change.emit();
        } else {
            this.supported = true;
            this.permission = Notification.permission;
            if (this.permission === 'denied') {
                this.initializeEnabled(false);
            } else {
                this.initializeEnabled(true);
            }
        }
    }

    public toggle() {
        this.enabled ? this.disable() : this.enable();
    }

    public enable() {
        switch (this.permission) {
            case 'denied':
                alert(
                    'You have denied permission for desktop notifications. Please enable notifications in your browser for this site.'
                );
                this.change.emit();
                break;
            case 'default':
                this.requestPermission();
                break;
            default:
                this.localEnable();
                break;
        }
    }

    public disable() {
        localStorage.setItem(LOCAL_STORAGE_NOTIFICATIONS_KEY, 'false');
        this.enabled = false;
        this.change.emit();
    }

    public notify(message: Message) {
        if (this.permission === 'default') {
            this.requestPermission();
        } else if (this.enabled) {
            const options = {
                body: message.body,
                icon:
                    'https://is1-ssl.mzstatic.com/image/thumb/Purple91/v4/e2/bf/e3/e2bfe3e8-eca6-2cd2-f042-dd74611e0b2b/source/256x256bb.jpg',
            };
            const notification = new Notification(message.senderName, options);
            notification.onclick = () => {
                if (message.uri) {
                    window.location.href = message.uri;
                }
            };
            setTimeout(notification.close.bind(notification), 10000);
        }
    }

    private requestPermission() {
        Notification.requestPermission((permission: string) => {
            this.permission = permission;
            if (permission === 'granted') {
                this.localEnable();
            } else {
                this.disable();
            }
        });
    }

    private localEnable() {
        localStorage.setItem(LOCAL_STORAGE_NOTIFICATIONS_KEY, 'true');
        this.enabled = true;
        this.change.emit();
    }

    private initializeEnabled(enabled) {
        const stickySetting = localStorage.getItem(LOCAL_STORAGE_NOTIFICATIONS_KEY);

        if (stickySetting) {
            if (enabled) {
                this.enabled = stickySetting === 'true';
            } else {
                this.disable();
            }
        } else {
            enabled ? this.localEnable() : this.disable();
        }
        this.change.emit();
    }
}
