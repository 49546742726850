import { isNullOrUndefined } from './../utils/utils';
import { ConfigurationService } from './../services/configuration.service';
import { Injectable } from '@angular/core';
import {
    HttpEvent, HttpInterceptor, HttpHandler, HttpRequest
} from '@angular/common/http';

import { Observable } from 'rxjs';

@Injectable()
export class CredentialsRequestInterceptor implements HttpInterceptor {

    constructor(private configService: ConfigurationService ) {
    }

    intercept(req: HttpRequest<any>, next: HttpHandler):
        Observable<HttpEvent<any>> {
            if (!isNullOrUndefined(this.configService.hostUrl) &&
                 this.configService.hostUrl !=='' &&
                 req.url.startsWith(this.configService.hostUrl)) {
            req = req.clone({
                withCredentials: true
            });
        }
        return next.handle(req);
    }
}
