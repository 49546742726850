import { MuniUserSettingHttpModel } from 'src/app/modules/user-settings/models/muni-user-settings-http.model';
import { Action } from '@ngrx/store';

export enum MuniSettingsActionTypes {
    Load        = '[MuniUserSettings] Load',
    LoadSuccess = '[MuniUserSettings] Load Success',
    LoadFailed  = '[MuniUserSettings] Load Failed',

    Update      = '[MuniUserSettings] Update'
}

export class Load implements Action {
    public readonly type = MuniSettingsActionTypes.Load;

    constructor() {}
}

export class LoadFailed implements Action {
    public readonly type = MuniSettingsActionTypes.LoadFailed;

    constructor() {}
}

export class LoadSuccess implements Action {
    public readonly type = MuniSettingsActionTypes.LoadSuccess;

    constructor(public payload: { muniSettings: MuniUserSettingHttpModel[], hasMmdDataPermission: boolean }) {}
}

export class Update implements Action {
    public readonly type = MuniSettingsActionTypes.Update;

    constructor(public payload: MuniUserSettingHttpModel) {}
}

export type MuniSettingsActionsUnion =
    | Load
    | LoadSuccess
    | Update;
