import { Inject, Injectable } from '@angular/core';
import { WINDOW } from '@core/services/window.service';

export type StorageTypeModel = 'localStorage' | 'sessionStorage';

@Injectable({
    providedIn: 'root'
})
export class StorageService {

    private static readonly DEFAULT_STORAGE_TYPE: StorageTypeModel = 'localStorage';

    private currentStorageType: StorageTypeModel = StorageService.DEFAULT_STORAGE_TYPE;

    constructor(@Inject(WINDOW) private window: Window) {}

    public get localStorage(): this {
        this.currentStorageType = 'localStorage';

        return this;
    }

    public get sessionStorage(): this {
        this.currentStorageType = 'sessionStorage';

        return this;
    }

    public setObject<T>(key: string, value: T): void {
        this.setItem(key, JSON.stringify(value));
    }

    public setItem(key: string, value: string): void {
        this.window[this.currentStorageType].setItem(key, value);

        this.currentStorageType = StorageService.DEFAULT_STORAGE_TYPE;
    }

    public getObject<T>(key: string): T | null {
        const serializedItem = this.getItem(key);
        if (serializedItem === null) {
            return null;
        }

        return <T>JSON.parse(serializedItem);
    }

    public getItem(key: string): string | null {
        const result = this.window[this.currentStorageType].getItem(key);

        this.currentStorageType = StorageService.DEFAULT_STORAGE_TYPE;

        return result;
    }

    public removeItem(...keys: string[]): void {
        keys.forEach(key => this.window[this.currentStorageType].removeItem(key));

        this.currentStorageType = StorageService.DEFAULT_STORAGE_TYPE;
    }

}
