import { Component, OnInit, OnDestroy, Input, Output, EventEmitter } from '@angular/core';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';

import * as fromRoot from '../../../../reducers';
import { AssetClassModel, ASSET_CLASSES } from '../../../../core/models/asset-class';
import { HOME_PAGES_DICTIONARY } from '../../../shared/models/home-pages.model';
import { Permissions } from '../../../../core/permissions/permissions.model';
import { BspConfiguration } from '../../../../core/configuration/bsp-configuration';


@Component({
    selector: 'app-landing-page',
    templateUrl: './landing-page.component.html',
    styleUrls: ['./landing-page.component.css']
})
export class LandingPageComponent implements OnInit, OnDestroy {
    @Input()
    public homePage: string;

    @Output()
    public valueChange = new EventEmitter();

    public homePages = HOME_PAGES_DICTIONARY;
    public permissions: Permissions;
    public configuration: BspConfiguration;

    private permissionsSubscription: Subscription;
    private configurationSubscription: Subscription;

    constructor(private store: Store<fromRoot.State>) { }

    ngOnInit(): void {
        this.permissionsSubscription = this.store.select(fromRoot.getPermissionsState)
            .subscribe((permissions) => {
                this.permissions = permissions;
            });

        this.configurationSubscription = this.store.select(fromRoot.getConfigurationState)
            .subscribe((configuration) => {
                this.configuration = configuration;
            });
    }

    public onChange(event: Event): void {
        const value = (event.target as HTMLInputElement).value;
        this.valueChange.emit(value);
    }

    public isHomePageOptionDisabled(homePage: string): boolean {
        const isPermissioned = (asset, page) => ASSET_CLASSES
            .find(assetClass => assetClass.model === asset)
            .assetPages
            .find(assetPage => assetPage.label === page)
            .isPermissioned(this.permissions, this.configuration);

        switch (homePage) {
            case 'Equity':
                return !isPermissioned(AssetClassModel.Equity, 'DealMonitor');
            case 'Equity Deal Query':
                return !isPermissioned(AssetClassModel.Equity, 'Deal Query');
            case 'Equity Public Deals':
                return !isPermissioned(AssetClassModel.Equity, 'Equity Public Deals');
            case 'Municipal':
                return !isPermissioned(AssetClassModel.Municipal, 'Street Calendar') &&
                    !isPermissioned(AssetClassModel.Municipal, 'Deal Manager');
            case 'Fixed Income':
                return !isPermissioned(AssetClassModel.FixedIncome, 'DealMonitor');
            case 'Deal Query':
                return !isPermissioned(AssetClassModel.FixedIncome, 'Deal Query');
            case 'Activity Stream':
                return !isPermissioned(AssetClassModel.FixedIncome, 'Activity Stream');
            default:
                return false;
        }
    }

    public disableSorting(): number {
        return 0;
    }

    public ngOnDestroy() {
        if (this.permissionsSubscription) {
            this.permissionsSubscription.unsubscribe();
        }

        if (this.configurationSubscription) {
            this.configurationSubscription.unsubscribe();
        }
    }

}
