import { Injectable } from '@angular/core';
import { CacheService } from './cache.service';
import { CacheItem } from './cache-item';

@Injectable()
export class ImMemoryCacheService extends CacheService {
    private items: CacheItem[] = [];

    protected set currentItems(items: CacheItem[]) {
        this.items = items;
    }

    protected get currentItems(): CacheItem[] {
        return this.items;
    }
}
