import { MILLISECONDS_IN_A_DAY, PASSWORD_EXPIRE_DAYS, SHOW_PASSWORD_EXPIRE_MESSGAE_AFTER_DAYS } from '@core/constants/constants';
import * as moment from 'moment';

export const generateGUID = (): string => {
    const s4 = (): string =>
        Math.floor((1 + Math.random()) * 0x10000)
            .toString(16)
            .substring(1);

    return s4() + s4() + '-' + s4() + '-' + s4() + '-' +
        s4() + '-' + s4() + s4() + s4();
};

export const isRelativeBffUrl = (url: string): boolean => {
    if (url === null || url === undefined) {
        return false;
    }

    return url.startsWith('api/') || url.startsWith('/api/');
};

export const composeElementSelector = (el: Element): string => {
    let selector: string = null;

    const mapClassList = classList => Array.from(classList).map(className => `.${className}`).join('');

    if (el && el.parentElement && el.parentElement.classList) {
        selector = el.parentElement.tagName + mapClassList(el.parentElement.classList) + ' > ';
    }

    if (el && el.classList) {
        selector += el.tagName + mapClassList(el.classList);
    }

    return selector;
};

export const checkIfPasswordExpiringSoon = (passwordChangeDate: string) => {
  const passwordChangeDateOnly = moment(convertDateFormat(passwordChangeDate)).startOf('day');
  const currentDateOnly = moment().utc().startOf('day');

  const daysSincePasswordChange = currentDateOnly.diff(passwordChangeDateOnly, 'days');

  return {
    isPasswordExpiringSoon: daysSincePasswordChange >= SHOW_PASSWORD_EXPIRE_MESSGAE_AFTER_DAYS,
    daysUntilPasswordExpires: Math.ceil(PASSWORD_EXPIRE_DAYS - daysSincePasswordChange)
  };
};

const convertDateFormat = dateString => {
    return moment(dateString).format('YYYY-MM-DD HH:mm:ss.SSSSS');
  };

export const isNullOrUndefined = (x: any): boolean => x === undefined || x === null;

export const deepClone = <T>(o: T): T => <T>JSON.parse(JSON.stringify(o));
