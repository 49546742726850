import { Inject, Injectable } from '@angular/core';
import { WINDOW } from '@core/services/window.service';
import { fromEvent, merge, Observable, Subject, Subscription } from 'rxjs';
import { throttleTime } from 'rxjs/operators';

export interface IdleExpiryDateTime {
    id: number;
    time: Date;
}

export interface IdlePayload {
    event: string;
    secondsLeft: number;
}

@Injectable({
    providedIn: 'root'
})
export class IdleService {

    public idleExpiryDateTime: IdleExpiryDateTime;

    private readonly INTERRUPT_EVENTS = ['click', 'keydown', 'mousemove', 'touchstart'];

    private idle: Subject<IdlePayload> = new Subject<IdlePayload>();
    private idleTerminated: Subject<void> = new Subject<void>();
    private timeout: Subject<void> = new Subject<void>();

    private idleId: any;
    private timeoutId: any;
    private idleTimeMs: number;
    private timeoutTimeMs: number;

    private isRunning: boolean;

    private readonly INTERRUPTERS_DEBOUNCE_TIME = 500;
    private interruptersSubscription: Subscription;

    public get onIdle(): Observable<IdlePayload> {
        return this.idle.asObservable();
    }

    public get onIdleTerminated(): Observable<void> {
        return this.idleTerminated.asObservable();
    }

    public get onTimeout(): Observable<void> {
        return this.timeout.asObservable();
    }

    constructor(
        @Inject(WINDOW) private window: Window
    ) {}

    public start(idleMs: number, timeoutMs: number): void {
        if (this.isRunning) {
            console.warn('Idle service is already started.');

            return;
        }

        this.idleTimeMs = idleMs;
        this.timeoutTimeMs = timeoutMs;
        this.isRunning = true;

        this.setInterrupters();
        this.watch();
    }

    public stop(): void {
        this.removeInterrupters();
        this.window.clearTimeout(this.idleId);
        this.window.clearInterval(this.timeoutId);
        this.isRunning = false;
    }

    private watch(): void {
        this.refreshExpirationDate();

        this.schedule();
    }

    private schedule(time?: number): void {
        this.window.clearTimeout(this.idleId);
        this.window.clearTimeout(this.timeoutId);

        this.idleId = setTimeout(() => {
            const dateNow = new Date(Date.now()).getTime();

            const expireDate = this.getExpirationDate();
            if (expireDate === null) {
                return;
            }

            const expireDateTime = new Date(expireDate).getTime();

            if (expireDateTime - dateNow - this.timeoutTimeMs > 0) {
                const expireDiffTime = expireDateTime - dateNow - this.timeoutTimeMs;

                this.schedule(expireDiffTime);

                return;
            }

            if (expireDateTime - dateNow > 0) {
                this.idle.next({ event: 'Idle', secondsLeft: Math.trunc((expireDateTime - dateNow) / 1000) });
                this.scheduleTimeout(Math.trunc((expireDateTime - dateNow) / 1000));

                return;
            }

            this.timedOut();
        }, time || this.idleTimeMs);
    }

    private scheduleTimeout(secondsLeft: number): void {
        this.window.clearTimeout(this.timeoutId);

        this.timeoutId = setInterval(() => {
            const dateNow = new Date(Date.now()).getTime();

            const expireDate = this.getExpirationDate();
            if (expireDate === null) {
                return;
            }

            const expireDateTime = new Date(expireDate).getTime();

            if (expireDateTime > dateNow + this.timeoutTimeMs) {
                this.window.clearTimeout(this.timeoutId);
                this.idleTerminated.next();
                this.watch();

                return;
            }

            if (secondsLeft-- <= 0) {
                this.timedOut();

                return;
            }

            this.idle.next({ event: 'Idle', secondsLeft });
        }, 1000);
    }

    private setInterrupters(): void {
        const interrupters$: Observable<Event>[] = [];

        this.INTERRUPT_EVENTS.forEach(event => interrupters$.push(fromEvent(this.window, event)));

        this.interruptersSubscription = merge(...interrupters$)
            .pipe(throttleTime(this.INTERRUPTERS_DEBOUNCE_TIME, undefined, { leading: true }))
            .subscribe(() => this.onInterrupt());
    }

    private removeInterrupters(): void {
        if (this.interruptersSubscription) {
            this.interruptersSubscription.unsubscribe();
        }
    }

    private onInterrupt = () => {
        this.refreshExpirationDate();
    }

    private refreshExpirationDate(): void {
        const newExpDate = new Date(new Date().getTime() + this.idleTimeMs + this.timeoutTimeMs);

        this.idleExpiryDateTime = {
            id: this.idleExpiryDateTime ? this.idleExpiryDateTime.id : Math.trunc(Math.random() * Math.pow(10, 14)),
            time: newExpDate
        };
    }

    private getExpirationDate(): Date {
        if (!this.idleExpiryDateTime) {
            this.timedOut();

            return null;
        }

        return this.idleExpiryDateTime.time;
    }

    private timedOut(): void {
        this.stop();
        this.timeout.next();
    }

}
