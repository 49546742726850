import { Injectable, Inject } from '@angular/core';
import { NotificationService } from './notification.service';
import { disableDesktopNotificationsKey } from '@core/constants/constants';
import { WINDOW } from '@core/services/window.service';

declare let Notification: any;

export enum NotificationPermission {
    Default = 'default',
    Denied = 'denied',
    Granted = 'granted'
}

@Injectable()
export class NativeNotificationService extends NotificationService {
    public static readonly NOTIFICATION_BLOCKED_WARNING = 'Desktop notifications are blocked by client\'s browser';

    private static readonly CLOSE_NOTIFICATION_TIMEOUT_MS = 4000;

    constructor(@Inject(WINDOW) private window: Window) {
        super();
    }

    public showNotification(title: string, notificationOptions: NotificationOptions, onclick?: () => any): void {
        this.checkPermissions().then(isPermissioned => {
            if (!isPermissioned) {
                return;
            }

            const notification = new Notification(title, notificationOptions);
            setTimeout(notification.close.bind(notification), NativeNotificationService.CLOSE_NOTIFICATION_TIMEOUT_MS);

            if (!!onclick) {
                notification.onclick = onclick;
            }
        });
    }

    public checkPermissions(): Promise<boolean> {
        if (this.window[disableDesktopNotificationsKey]) {
            return Promise.resolve(false);
        }

        if (
            Notification.permission === NotificationPermission.Denied ||
            Notification.permission === NotificationPermission.Default
        ) {
            console.warn(NativeNotificationService.NOTIFICATION_BLOCKED_WARNING);

            return Promise.resolve(false);
        }

        return Notification.requestPermission().then(() => {

            if (Notification.permission !== NotificationPermission.Granted) {
                console.warn(NativeNotificationService.NOTIFICATION_BLOCKED_WARNING);

                return false;
            }

            return true;
        });
    }
}
