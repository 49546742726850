import { Injectable } from '@angular/core';
import {
    HttpEvent,
    HttpHandler,
    HttpInterceptor,
    HttpRequest,
    HttpResponse,
    HttpErrorResponse
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap, finalize } from 'rxjs/operators';
import { environment } from '@environments/environment';

@Injectable()
export class ProfilingInterceptor implements HttpInterceptor {
    constructor() {}

    public intercept(
        req: HttpRequest<any>,
        next: HttpHandler
    ): Observable<HttpEvent<any>> {
        if (environment.production) {
            return next.handle(req);
        } else {
            const started = Date.now();
            let ok: string;

            return next.handle(req).pipe(
                tap(
                    (event: HttpEvent<any>) =>
                        (ok = event instanceof HttpResponse ? 'succeeded' : ''),
                    (error: HttpErrorResponse) => (ok = 'failed')
                ),
                // Log when response observable either completes or errors
                finalize(() => {
                    const elapsed = Date.now() - started;
                    const msg = `${req.method} "${req.urlWithParams}" ${ok} in ${elapsed} ms.`;
                    if (!environment.production) {
                        if (elapsed > 2000) {
                            console.warn(msg);
                        } else {
                            console.log(msg);
                        }
                    }
                })
            );
        }
    }
}
