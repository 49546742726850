import { EquityUserSettingsActions } from '../../actions';
import { EquityUserSettingsModel } from '../../../../modules/user-settings/models/equity-user-settings.model';
import { mapEquityUserSettingsFromHttp } from '../../../user-settings/equity-user-settings-mapper.service';

export type State = EquityUserSettingsModel;

export const initialState: State = {
    numberFormat: null,
    dateFormat: null,
    timeFormat: null,
    timezone: null,
    equityBookState: null,
    equityBookStateNotificationSetting: null,
    equityDealState: null,
    equityDealStateNotificationSetting: null,
    equityDealDetailsState: null,
    equityDealDetailsNotificationSetting: null,
    equityBookStateEmail: null,
    equityBookStateEmailNotificationSetting: null,
    equityDealStateEmail: null,
    equityDealStateEmailNotificationSetting: null,
    equityDealDetailsStateEmail: null,
    equityDealDetailsEmailNotificationSetting: null
};

export function equityUserSettingsReducer(
    state: State = initialState,
    action: EquityUserSettingsActions.EquitySettingsActionsUnion
): State {
    switch (action.type) {
        case EquityUserSettingsActions.EquitySettingsActionTypes.LoadSuccess:
            return mapEquityUserSettingsFromHttp(action.payload);

        case EquityUserSettingsActions.EquitySettingsActionTypes.Update:
            return {
                ...state,
                [action.payload.settingName]: action.payload.settingValue
            };

        default:
            return state;
    }
}
