import { HttpErrorResponse } from '@angular/common/http';
import { EmailUpdateWarnings, PhoneNumberUpdateWarnings } from '../models/user-profile-warnings.model';

export class UserProfileHelperService {

    public static mapPhoneNumbersForUpdate(phones: any[], newPhoneNumber: string, previousNumber: string): any {
        const phonesList = [ ...phones ];

        if (!phonesList.length) {
            return [{
                PhoneNumber: newPhoneNumber,
                IsPrimary: true,
                IsConfirmed: false,
            }];
        }

        if (!newPhoneNumber.length) {
           const index = phones.findIndex(phoneObj => phoneObj.PhoneNumber === previousNumber);

            phonesList.splice(index, 1);
        }

        return phonesList.map(phoneObj => {
            if (phoneObj.IsPrimary) {
                return {
                    PhoneNumber: newPhoneNumber,
                    IsPrimary: phoneObj.IsPrimary,
                    IsConfirmed: phoneObj.IsConfirmed,
                };
            } else {
                return {
                    PhoneNumber: phoneObj.PhoneNumber,
                    IsPrimary: phoneObj.IsPrimary,
                    IsConfirmed: phoneObj.IsConfirmed,
                };
            }
        });
    }

    public static getEmailUpdateWarning(error: HttpErrorResponse): EmailUpdateWarnings {
        if (error.error && error.error.ErrorCode && error.error.ErrorCode === 'DuplicateKeyException') {
            return EmailUpdateWarnings.EmailInUse;
        } else if (error.error && error.error.ModelState) {
            return EmailUpdateWarnings.InvalidFormat;
        } else {
            return EmailUpdateWarnings.InternalServerError;
        }
    }

    public static getPhoneNumberUpdateWarning(error: HttpErrorResponse): PhoneNumberUpdateWarnings {
        if (error.error && error.error.ErrorCode && error.error.ErrorCode === 'DuplicateKeyException') {
            return PhoneNumberUpdateWarnings.PhoneNumberInUse;
        } else {
            return PhoneNumberUpdateWarnings.InternalServerError;
        }
    }

}
