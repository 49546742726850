import { Injectable, Inject } from '@angular/core';
import { CupcakeNotifyService, CupcakeNotifyOptions, CupcakeNotifyTemplate } from '@ipreo/cupcake-components';
import { NotificationService } from './notification.service';
import { WINDOW } from '@core/services/window.service';

const transparent1pxIcon = 'data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7';
const customDelayKey = '__CUPCAKE_NOTIFICATION_DELAY__';

@Injectable()
export class CupcakeNotificationService extends NotificationService {
    constructor(
        private cupcakeNotify: CupcakeNotifyService,
        @Inject(WINDOW) private window: Window
    ) {
        super();
    }

    public showNotification(title: string, notificationOptions: NotificationOptions, onclick: () => any): void {
        this.checkPermissions().then(isPermissioned => {
            if (!isPermissioned) {
                return;
            }

            const customDelay: number | undefined = this.window[customDelayKey];

            const options = <CupcakeNotifyOptions>{
                position: 'top right',
                type: 'default',
                closeOnClick: false,
                delay: customDelay || 5000,
                template: this.generateTemplate(title, notificationOptions.icon, notificationOptions.tag, onclick)
            };

            this.cupcakeNotify.log(notificationOptions.body, options);
        });
    }

    public checkPermissions(): Promise<boolean> {
        return Promise.resolve(true);
    }

    private generateTemplate(title: string, icon: string, tag: string, onclick: () => any): CupcakeNotifyTemplate {
        if (icon === null || icon === undefined) {
            icon = transparent1pxIcon;
        }

        return (message: string, element: HTMLElement, options: CupcakeNotifyOptions, closeFn: any) => {
            message = message.replace(/(\r\n|\n|\r)/gm, '<br />');

            element.classList.add('c-toast-no-icon');
            element.style.padding = '19px 10px';
            element.onclick = onclick;
            element.dataset['tag'] = tag;

            this.cupcakeNotify.template(this.template(title, icon, message), element, options, (event: MouseEvent) => {
                event.stopPropagation();
                closeFn();
            });
        };
    }

    private template(title: string, icon: string, body: string): string {
        return `
<div class="c-media c-media-middle c-p-0">
    <img src="${icon}" alt=" " height="50px" width="50px" />
    <div class="c-media-body">
        <div class="c-text-md c-text-bold">${title}</div>
        <div class="c-text-sm c-p-top-xs">${body}</div>
    </div>
</div>
        `;
    }
}
