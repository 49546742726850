import { Injectable } from '@angular/core';
import { MuniUserSettingHttpModel, SettingType } from '../../modules/user-settings/models/muni-user-settings-http.model';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { select, Store } from '@ngrx/store';
import { catchError, map, switchMap } from 'rxjs/operators';
import { BspConfiguration } from '@core/configuration/bsp-configuration';
import * as fromRoot from '../../reducers';
import { noErrorHandlingForHeader } from '@core/constants/http-headers';

@Injectable({
    providedIn: 'root'
  })
  export class MuniUserSettingsHttpService {

      private readonly USER_SETTINGS_URL = '/v2/user-preferences';

      constructor(
          private http: HttpClient,
          private store: Store<fromRoot.State>
      ) {}

      public getUserSettings(muniBffUrl: string): Observable<MuniUserSettingHttpModel[]> {
        return this.http.get<MuniUserSettingHttpModel[]>(`${muniBffUrl}${this.USER_SETTINGS_URL}`, {
            headers: new HttpHeaders().set(noErrorHandlingForHeader, '*')
        }).pipe(
                catchError(() => {
                    return of([]);
                })
            );
      }

      public async getUserSettingsPromise(muniBffUrl: string): Promise<MuniUserSettingHttpModel[]> {
          let muniSettings = [];
          try {
            muniSettings = await this.getUserSettings(muniBffUrl).toPromise();
          } catch (error) {
              console.error(error);

              return muniSettings;
          }

          return muniSettings;
      }

      public updateUserSetting(payload: any): Observable<MuniUserSettingHttpModel> {
        const updatePayload = this.mapToMuniUserSettingPayload(payload);

        return this.store.pipe(
            select(fromRoot.getConfigurationState),
            map((config: BspConfiguration) => config.muniBffUrl),
            switchMap(muniBffUrl => this.http.post<MuniUserSettingHttpModel>(`${muniBffUrl}${this.USER_SETTINGS_URL}`, updatePayload)));
      }

      private mapToMuniUserSettingPayload(payload: any): MuniUserSettingHttpModel {
          return <MuniUserSettingHttpModel>
          {
              id: payload.id,
              settingType: SettingType.UserSetting.toString(),
              userPreference: {
                  name: payload.settingName,
                  value: payload.value
              }
          };
      }


  }
