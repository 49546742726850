import { Action } from '@ngrx/store';
import { MessagesStatusSuccessResponse } from '../../types';

export enum MessagesStatusActions {
    Load = '[northstar-alerts] Load Message Status',
    LoadSuccess = '[northstar-alerts] Load Messages Status Success',
    LoadFail = '[northstar-alerts] Load Messages Status Error',
    Bump = '[northstar-alerts] Bump Counts'
}

/* tslint:disable:max-classes-per-file */

export class MessagesStatusLoadAction implements Action {
    public readonly type = MessagesStatusActions.Load;
}

export class MessagesStatusLoadSuccessAction implements Action {
    public readonly type = MessagesStatusActions.LoadSuccess;

    constructor(public payload: MessagesStatusSuccessResponse) {}
}

export class MessagesStatusLoadFailAction implements Action {
  public readonly type = MessagesStatusActions.LoadFail;

  constructor(public payload: string) {}
}


export class MessagesStatusBumpAction implements Action {
    public readonly type = MessagesStatusActions.Bump;
}

export type MessagesStatusActionsUnion =
    | MessagesStatusLoadAction
    | MessagesStatusLoadSuccessAction
    | MessagesStatusLoadFailAction
    | MessagesStatusBumpAction;
