import { LayoutActions } from '../../actions';

export interface State {
    urlHistory: string[];
}

export const initialState: State = {
    urlHistory: []
};

export function routingHistoryReducer(
    state: State = initialState,
    action: LayoutActions.LayoutActionsUnion
): State {
    switch (action.type) {
        case LayoutActions.LayoutActionTypes.EndNavigation:
            return {
                urlHistory: [...state.urlHistory, action.payload.path]
            };
        default:
            return state;
    }
}
