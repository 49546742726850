/* eslint-disable */
/* eslint-disable max-len */
import { Injectable, Inject } from '@angular/core';
import { WINDOW } from '@core/services/window.service';
import { UserContextModel } from '@core/usercontext/user-context-http.model';
import { isNullOrUndefined } from '../utils/utils';

@Injectable()
export class PendoService {
    constructor(@Inject(WINDOW) private window: Window) {}

    private pendoApiKey: string;

    private get pendo(): any {
        return this.window['pendo'];
    }

    public setUp(pendoEnabled: boolean, pendoApiKey: string): void {
        if (!pendoEnabled || isNullOrUndefined(pendoApiKey)) {
            return;
        }

        this.pendoApiKey = pendoApiKey;

        (function(apiKey) {
            (function(p, e, n, d, o) {let v, w, x, y, z; o = p[d] = p[d] || {}; o._q = [];
            v = ['initialize', 'identify', 'updateOptions', 'pageLoad']; for (w = 0, x = v.length; w < x; ++w) {(function(m) {
                o[m] = o[m] || function() {o._q[m === v[0] ? 'unshift' : 'push']([m].concat([].slice.call(arguments, 0))); }; })(v[w]);
                                                                   }
                y = e.createElement(n); y.async = !0; y.src = 'https://content.trace.buyside.ia.ipreo.com/agent/static/' + apiKey + '/pendo.js';
                z = e.getElementsByTagName(n)[0]; z.parentNode.insertBefore(y, z); })(<any>window, <any>document, 'script', 'pendo');
        })(pendoApiKey);
    }

    public isSetUp(): boolean {
        return !!this.pendoApiKey;
    }

    public initialize(userContext: UserContextModel): void {
        const pendo = this.pendo;

        if (isNullOrUndefined(pendo) || !this.isSetUp()) {
            return;
        }

        const profile = userContext.Profile;
        const container = userContext.Profile.Containers[0];

        pendo.initialize({
            visitor: {
                id: profile.Id,
                name: `${profile.FirstName} ${profile.LastName}`,
                city: profile.City,
                country: profile.Country,
                email: profile.Email,
                phoneNumber: profile.PhoneNumber,
                title: profile.Title,
                companies: profile.Companies.map(c => ({
                    companyName: c.Name,
                    companyOrionId: c.OrionId
                }))
            },
            account: {
                id: container.Id,
                name: container.Name,
                status: container.Status,
                isAdmin: container.IsAdmin,
                isTestContainer: container.IsTestContainer
            }
        });
    }

    public trackEvent(name: string, data: {[key: string]: string | number} = {}): void {
        const pendo = this.pendo;

        if (isNullOrUndefined(pendo) || !this.isSetUp()) {
            return;
        }

        if (pendo.isReady && pendo.isReady()) {
            return pendo.track(name, {
                ...data,
                clientLocalDateTime: new Date().toLocaleString()
            });
        }

        setTimeout(() => {
            this.trackEvent(name, data);
        }, 500);
    }
}
