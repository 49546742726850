import { Action } from '@ngrx/store';
import { AssetClassModel } from '@core/models/asset-class';

export enum LayoutActionTypes {
    ShowDisconnectAlert = '[Layout] Show Disconnect Alert',
    HideDisconnectAlert = '[Layout] Hide Disconnect Alert',
    SetAssetClass       = '[Layout] Set Asset Class',
    StartNavigation     = '[Layout] Start Navigation',
    EndNavigation       = '[Layout] End Navigation'
}

export class ShowDisconnectAlert implements Action {
    public readonly type = LayoutActionTypes.ShowDisconnectAlert;

    constructor() {}
}

export class HideDisconnectAlert implements Action {
    public readonly type = LayoutActionTypes.HideDisconnectAlert;

    constructor() {}
}

export class SetAssetClass implements Action {
    public readonly type = LayoutActionTypes.SetAssetClass;

    constructor(public payload: AssetClassModel) {}
}

export class StartNavigation implements Action {
    public readonly type = LayoutActionTypes.StartNavigation;

    constructor() {}
}

export class EndNavigation implements Action {
    public readonly type = LayoutActionTypes.EndNavigation;

    constructor(public payload: {path: string}) {}
}

export type LayoutActionsUnion =
    | ShowDisconnectAlert
    | HideDisconnectAlert
    | SetAssetClass
    | StartNavigation
    | EndNavigation;
