/* eslint-disable max-len */
import {Injectable} from '@angular/core';
import { Store } from '@ngrx/store';
import { EMPTY, of } from 'rxjs';
import { distinctUntilChanged, pluck, tap } from 'rxjs/operators';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { LoggerActions, WebsocketConnectionActions } from '../actions';
import { State } from '../../../reducers';
import { ConnectionStatus } from '@core/live-update/websockets/platform-websockets-connector';
import { ClientLogLevel } from '@core/logger/log.model';

export interface WebsocketEvent extends Event {
    type: string;
    code: number;
    reason: string;
}

export interface WebsocketConnectionLogDetails {
    isConnected: boolean;
    connectionStatus: ConnectionStatus;
    event: WebsocketEvent;
}

@Injectable()
export class WebsocketConnectionEffect {
    constructor(
        private store: Store<State>,
        private actions$: Actions
    ) {}


    public log$ = createEffect(() => this.actions$.pipe(
        ofType(WebsocketConnectionActions.WebsocketConnectionActionTypes.ConnectionChanged),
        pluck('payload'),
        distinctUntilChanged(
            (prev: WebsocketConnectionLogDetails, curr: WebsocketConnectionLogDetails) => prev.isConnected === curr.isConnected
        ),
        tap(({ isConnected, connectionStatus, event }: WebsocketConnectionLogDetails) => {
            const logLevel = connectionStatus === ConnectionStatus.Successful
                ? ClientLogLevel.Info
                : ClientLogLevel.Warn;

            this.store.dispatch(new LoggerActions.LogMessages(
                {
                    logs: [{
                        entryDate: new Date(),
                        message: `[WEBSOCKET] Is Connected:${isConnected} Status:${connectionStatus} Code:${event && event.code || null} Type:${event && event.type || null} Reason:${event && event.reason || null}`,
                        level: logLevel,
                        extraInfo: null
                    }],
                    addExtraInfo: true
                })
            );

            return of(EMPTY);
        })
    ), { dispatch: false });
}
