import { Action } from '@ngrx/store';

export enum SupportActionTypes {
    SendMuniMessage    = '[Support] Send muni message',
    SendBuysideMessage = '[Support] Send buyside message',
    SendMessageSuccess = '[Support] Send message Success',
    SendMessageFailed  = '[Support] Send message Failed',
    SendEquityMessage  = '[Support] Send equity message',

    ClearMessageState  = '[Support] Clear message state'
}

export class SendEquityMessage implements Action {
    public readonly type = SupportActionTypes.SendEquityMessage;

    constructor(public payload: string) {}
}

export class SendMuniMessage implements Action {
    public readonly type = SupportActionTypes.SendMuniMessage;

    constructor(public payload: string) {}
}

export class SendBuysideMessage implements Action {
    public readonly type = SupportActionTypes.SendBuysideMessage;

    constructor(public payload: string) {}
}

export class SendMessageSuccess implements Action {
    public readonly type = SupportActionTypes.SendMessageSuccess;

    constructor() {}
}

export class SendMessageFailed implements Action {
    public readonly type = SupportActionTypes.SendMessageFailed;

    constructor() {}
}

export class ClearMessageState implements Action {
    public readonly type = SupportActionTypes.ClearMessageState;

    constructor() {}
}

export type SupportActionsUnion =
    | SendMuniMessage
    | SendEquityMessage
    | SendBuysideMessage
    | SendMessageSuccess
    | SendMessageFailed
    | ClearMessageState;
