import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';

import { Observable } from 'rxjs';
import { StorageService } from '../services/storage.service';
import { xsrfTokenRequestHeader } from '../constants/http-headers';

@Injectable()
export class XsrfTokenInterceptor implements HttpInterceptor {

    constructor(private storageService: StorageService) {}

    public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        let entry: { value: string };

        try {
            entry = JSON.parse(this.storageService.getItem('app_xsrf_token'));
        } catch (error) {
            console.error(error);
        }

        if (!!entry && entry.value) {
            const newHeaders = req.headers.set(xsrfTokenRequestHeader, entry.value);
            req = req.clone({
                headers: newHeaders
            });
        }

        return next.handle(req);
    }

}
