export interface Permissions {
    canViewAccountX: boolean;
    canViewChinaBondDeals: boolean;
    canViewAdminTab: boolean;
    canViewFixedIncomeDealMonitor: boolean;
    canViewEquityDealMonitor: boolean;
    canViewMunicipalDealMonitor: boolean;
    canViewMunicipalStreetCalendar: boolean;
    hasMunicipalMmdData: boolean;
    canViewDealQuery: boolean;
    canViewActivityStream: boolean;
    canViewCompliance: boolean;
    canViewEquityDealQuery: boolean;
    canViewEquityPublicDeals: boolean;
    canViewOrders: boolean;
    canViewEquityOrders: boolean;
    canViewOrdersForCompanies: number[];
    canEditOrdersForCompanies: number[];
    canViewEquityOrdersForCompanies: number[];
    canViewTrancheSizeLimitOrder: boolean;
    canViewAnalytics: boolean;
    softFirmOrders: boolean;
    primaryOrderManagement: boolean;
    manualOrdersOnly: boolean;
    pomInternalOrderEntry: boolean;
    pomAggregatedOrderManagement: boolean;
    internalDealCreation: boolean;
    internalCommentsAndAlertingOrionPermission: boolean;
    releaseFixAllocations: boolean;
    thinkFolio: boolean;
    distributionOrders: boolean;
    canViewDistributionOrdersForCompanies: number[];
    underwritingOrders: boolean;
    canViewUnderwritingOrdersForCompanies: number[];
    operationalEntities: boolean;
    companiesWithPOMEnabled: number[];
    companiesWithInternalDealCreatePermission: number[];
    companiesWithTrancheSizeLimitPermission: number[];
    companiesWithReleaseFixExecutionsFirm: number[];
    companiesWithReleaseFixExecutionsFund: number[];
    companiesWithThirdPartyIntegration: number[];
    companiesWithOperationalEntitiesPermission: number[];
    canViewEquityAnalytics: boolean;
}

export enum PermissionTypes {
    AccountX = 'AccountX',
    ViewFixedIncomeDealMonitor = 'FixedIncomeDealMonitor',
    MunicipalDealMonitor = 'MunicipalDealMonitor',
    MunicipalStreetCalendar = 'MunicipalStreetCalendar',
    MmdData = 'MunicipalMmdData',
    ViewEquityDealMonitor = 'EquityDealMonitorV2',
    ViewDealQuery = 'DealQuery',
    ViewActivityStream = 'ActivityStream',
    ViewCompliance = 'Compliance',
    ViewOrders = 'Orders',
    ViewEquityOrders = 'EquityOrders',
    SoftFirmOrders = 'SoftFirmOrders',
    PrimaryOrderManagement = 'PrimaryOrderManagement',
    ManualOrdersOnly = 'ManualOrdersOnly',
    PrimaryOrderManagementOrionPermission = 'PrimaryOrderManagementOrionPermission',
    POMInternalOrderEntry = 'InternalOrderEntryOrionPermission',
    POMAggregatedOrderManagement = 'AggregatedOrderManagementOrionPermission',
    InternalDealCreation = 'InternalDealCreateOrionPermission',
    ReleaseFixAllocations = 'ReleaseFixAllocations',
    InternalCommentsAndAlertingOrionPermission = 'InternalCommentsAndAlertingOrionPermission',
    OrdersOrionPermission = 'OrdersOrionPermission',
    OrdersReadOnlyOrionPermission = 'OrdersReadOnlyOrionPermission',
    ViewEquityDealQuery = 'EquityDealQuery',
    ViewEquityPublicDeals = 'EquityDealDatabase',
    EquityOrdersPermission = 'EquityOrdersPermission',
    TrancheSizeLimitOrder = 'TrancheSizeLimitOrder',
    Analytics = 'FixedIncomeAnalytics',
    ThinkFolio = 'ThinkFolio',
    DistributionOrders = 'DistributionOrders',
    UnderwritingOrders = 'UnderwritingOrders',
    ReleaseFixExecutionsFirm = 'ReleaseFixExecutionsFirm',
    ReleaseFixExecutionsFund = 'ReleaseFixExecutionsFund',
    ChinaBondDeals = 'ChinaBondDeals',
    ThirdPartyIntegration = 'ThirdPartyIntegration',
    OperationalEntities = 'OperationalEntities',
    EquityAnalytics = 'EquityAnalytics'
}

