import { Action } from '@ngrx/store';
import { WebsocketConnectionLogDetails } from '@core/@state/effects/websocket-connection.effect';

export enum WebsocketConnectionActionTypes {
    ConnectionChanged                       = '[WEBSOCKETS] Connection changed'
}

export class ConnectionChanged implements Action {
    public readonly type = WebsocketConnectionActionTypes.ConnectionChanged;

    constructor(public payload: WebsocketConnectionLogDetails) {}
}

export type WebsocketConnectionActionsUnion =
    | ConnectionChanged;
