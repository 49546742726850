import { UserProfileActions } from '../actions';

export interface State {
    isLoading: boolean;
    updateErrorType: string | null;
}

export const initialState: State = {
    isLoading: false,
    updateErrorType: null
};

export function userProfileReducer(
    state: State = initialState,
    action: UserProfileActions.UserProfileActionsUnion
): State {
    switch (action.type) {
        case UserProfileActions.UserProfileActionTypes.UpdateUserProfile:
        case UserProfileActions.UserProfileActionTypes.UpdateUserEmails:
        case UserProfileActions.UserProfileActionTypes.UpdateUserPhoneNumbers:
            return {
                ...state,
                isLoading: true,
                updateErrorType: null
            };
        case UserProfileActions.UserProfileActionTypes.UpdateProfileSuccess:
            return {
                ...state,
                isLoading: false,
                updateErrorType: null
            };
        case UserProfileActions.UserProfileActionTypes.UpdateProfileFailed:
            return {
                ...state,
                isLoading: false,
                updateErrorType: action.payload.errorType
            };
        case UserProfileActions.UserProfileActionTypes.ResetUserProfileState:
            return {
                ...initialState
            };
        default: {
            return state;
        }

    }
}
