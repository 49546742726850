import { Injectable } from '@angular/core';
import { ReplaySubject, Observable, Subject, Subscription } from 'rxjs';
import * as fromRoot from '../../reducers';
import { select, Store } from '@ngrx/store';
import { Sockette } from './sockette';

export enum WebsocketGatewayAuthType {
    Alerts = 'Alerts',
    Account = 'Account',
    CAS = 'CAS',
    JWT = 'JWT'
}

export class ChannelSubscription {
    public subject: Subject<Object>;

    constructor(public channelName: string) {
        this.subject = new Subject<Object>();
    }
}

@Injectable()
export class BspWebsocketGatewayService {
    private url: string;
    private client: any;
    private subscriptions: ChannelSubscription[] = [];

    private PING_INTERVAL_MS = 8 * 60 * 1000;
    private pingIntervalId;

    public starting$: Observable<any>;
    public error$: Observable<any>;
    public close$: Observable<any>;
    public connection$: Observable<any>;

    private startingSubject = new ReplaySubject<any>(1);
    private errorSubject = new Subject<any>();
    private closeSubject = new Subject<any>();
    private connectionSubject = new ReplaySubject();

    private bspConfigurationSubscription: Subscription;

    constructor(
        private store: Store<fromRoot.State>
    ) {
        this.bspConfigurationSubscription = this.store.pipe(select(fromRoot.getConfigurationState))
            .subscribe(bspConfiguration => this.url = `${bspConfiguration.ipreoSocketsUrl}/connect`);

        this.starting$ = this.startingSubject.asObservable();
        this.error$ = this.errorSubject.asObservable();
        this.close$ = this.closeSubject.asObservable();
        this.connection$ = this.connectionSubject.asObservable();
    }

    public connect(authType: WebsocketGatewayAuthType, token: string): void {
        this.disconnect();

        // @ts-ignore
        this.client = new Sockette(
            `${this.url}?authType=${authType}&token=${token}`,
            {
                onopen: this.onopen.bind(this),
                onmessage: this.onmessage.bind(this),
                onmaximum: this.onmaximum.bind(this),
                onerror: this.onerror.bind(this),
                onclose: this.onclose.bind(this)
            }
        );

        this.connectionSubject.next(this.client);
    }

    public disconnect(): void {
        if (this.client) {
            this.client.close();
        }
    }

    public subscribe(channelName: string): Observable<any> {
        let channelSub = this.subscriptions.find(sub => {
            return sub.channelName === channelName;
        });

        if (channelSub) {
            return channelSub.subject.asObservable();
        }

        channelSub = new ChannelSubscription(channelName);
        this.subscriptions.push(channelSub);

        this.startingSubject.subscribe(() => {
            this.client.json({
                action: 'subscribe',
                channelName
            });
        });

        return channelSub.subject.asObservable();
    }

    public unsubscribe(channelName: string): void {
        const channelSub = this.subscriptions.find(sub => {
            return sub.channelName === channelName;
        });

        if (channelSub) {
            this.client.json({
                action: 'unsubscribe',
                channelName
            });
        }
    }

    private onmessage(messageEvent: MessageEvent): void {
        const { event, channelName, payload } = JSON.parse(messageEvent.data);

        if (event === 'error') {
            console.log('Websocket Gateway Error: ', payload);

            return;
        }

        const channelSub = this.subscriptions.find(
            sub => sub.channelName === channelName
        );

        if (channelSub) {
            switch (event) {
                case 'unsubscribed':
                    if (payload.statusCode === 200) {
                        channelSub.subject.complete();
                        this.subscriptions.splice(
                            this.subscriptions.indexOf(channelSub),
                            1
                        );
                    } else {
                        channelSub.subject.error(new Error(payload.message));
                    }
                    break;
                case 'subscribed':
                    if (payload.statusCode !== 200) {
                        channelSub.subject.error(new Error(payload.message));
                    }
                    break;
                case 'message':
                    channelSub.subject.next(payload);
                    break;
            }
        }
    }

    private onopen(event: Event): void {
        this.pingIntervalId = setInterval(() => {
            this.client.send('ping');
        }, this.PING_INTERVAL_MS);

        this.startingSubject.next(event);
    }

    private onmaximum(event: Event): void {
        this.errorSubject.next(event);
    }

    private onerror(event: Event): void {
        this.errorSubject.next(event);
    }

    private onclose(event: Event): void {
        clearInterval(this.pingIntervalId);

        this.closeSubject.next(event);
    }
}
