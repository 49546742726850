import {
    ChangeDetectionStrategy,
    Component,
    ElementRef,
    HostListener,
    OnDestroy,
    OnInit,
    ViewChild,
} from '@angular/core';
import { Store, select } from '@ngrx/store';
import { Observable, Subscription, combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';

import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import {
    applicationTitle,
    muniApplicationTitle,
} from '@core/constants/constants';
import { ASSET_CLASSES, AssetClassModel } from '@core/models/asset-class';
import { AutoUnsubscribe } from 'ngx-auto-unsubscribe';
import * as fromRoot from '../../../../reducers';
import * as fromLayout from '../../../@state/reducers/layout.reducer';

export interface AssetClassUi {
    title: string;
    route: string;
    enabled: boolean;
}

@AutoUnsubscribe()
@Component({
    selector: 'app-product-switcher',
    templateUrl: './product-switcher.component.html',
    styleUrls: ['./product-switcher.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProductSwitcherComponent implements OnInit, OnDestroy {
    public isAssetsTypeDropdownOpen = false;
    public currentAssetClass$: Observable<AssetClassUi>;
    public availableAssetClasses: AssetClassUi[] = [];

    private userContainerIdSubscription: Subscription;
    private userContainerId: string;
    private availableAssetClassesSubscription: Subscription;

    constructor(
        private store: Store<fromRoot.State>,
        private titleService: Title,
        private router: Router
    ) {}

    @ViewChild('assetsTypeDropdownEl', { static: true })
    private assetsTypeDropdownEl: ElementRef;

    @HostListener('document:click', ['$event'])
    public documentClick(event: Event): void {
        if (!this.assetsTypeDropdownEl.nativeElement.contains(event.target)) {
            this.isAssetsTypeDropdownOpen = false;
        }
    }

    public ngOnInit(): void {
        this.currentAssetClass$ = this.store.pipe(
            select(fromLayout.getCurrentAssetClass),
            map(
                (assesClassModel) =>
                    ASSET_CLASSES.find((ac) => ac.model === assesClassModel) ||
                    ASSET_CLASSES[0]
            ),
            map((assesClass) => {
                switch (assesClass.model) {
                    case AssetClassModel.Municipal:
                        this.titleService.setTitle(muniApplicationTitle);
                        break;
                    default:
                        this.titleService.setTitle(applicationTitle);
                        break;
                }

                return <AssetClassUi>{
                    title: assesClass.model,
                    route: assesClass.assetPages[0].route,
                };
            })
        );

        this.userContainerIdSubscription = this.store
            .select(fromRoot.getUserSettingsState)
            .subscribe(
                (settings) => (this.userContainerId = settings.containers[0].id)
            );

        this.availableAssetClassesSubscription = combineLatest([
            this.store.select(fromLayout.getCurrentAssetClass),
            this.store.select(fromRoot.getPermissionsState),
            this.store.select(fromRoot.getConfigurationState),
        ])
            .pipe(
                map(([currentAsset, permissions, configuration]) =>
                    [...ASSET_CLASSES]
                        .filter((as) => as.model !== currentAsset)
                        .map((as) => {
                            as.enabled =
                                as.assetPages.filter((c) =>
                                    c.isPermissioned(permissions, configuration)
                                ).length > 0;
                                return as
                        })
                        .map((ac) => {
                            const permittedPages = ac.assetPages.filter(
                                (page) =>
                                    page.isPermissioned(
                                        permissions,
                                        configuration
                                    )
                            );
                            let title: string;

                            switch (ac.model) {
                                case AssetClassModel.Municipal:
                                    title = 'Buy Side - Municipal';
                                    break;
                                case AssetClassModel.FixedIncome:
                                    title = 'InvestorAccess - Fixed Income';
                                    break;
                                case AssetClassModel.Equity:
                                    title = 'InvestorAccess - Equity';
                                    break;
                            }

                            const assetClass = <AssetClassUi>{
                                title,
                                route: permittedPages && permittedPages.length ? permittedPages[0].route : '',
                                enabled: ac.enabled,
                            };

                            if (assetClass.route.includes('admin')) {
                                assetClass.route = `${assetClass.route}/${this.userContainerId}/companies`;
                            }

                            return assetClass;
                        })
                )
            )
            .subscribe(
                (availableAssetClasses) =>
                    (this.availableAssetClasses = availableAssetClasses)
            );
    }

    public toggleDropdown(): void {
        if (this.availableAssetClasses.length === 0) {
            return;
        }

        this.isAssetsTypeDropdownOpen = !this.isAssetsTypeDropdownOpen;
    }

    public onAssetClassSelected(assetClass: AssetClassUi): void {
        if(assetClass.enabled) {
            this.router.navigate([assetClass.route]);
        }
    }

    public ngOnDestroy(): void {}
}
