import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AuthHelperService } from '@core/authentication/auth.helper.service';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, filter, switchMap, tap } from 'rxjs/operators';


import { AuthService } from '../../authentication/auth.service';
import { AuthApiActions } from '../actions';

@Injectable()
export class ResetPasswordEffect {

    constructor(
        private actions$: Actions,
        private authService: AuthService,
        private router: Router
    ) {}


    public resetPasswordRequest$ = createEffect(() => this.actions$.pipe(
        ofType(AuthApiActions.AuthApiActionTypes.ResetPasswordRequest),
        filter((action: any) => action.payload),
        switchMap(action => this.authService.resetPasswordRequest(action.payload)
            .pipe(
                switchMap(() => of(new AuthApiActions.ResetPasswordRequestSuccess())),
                catchError((error: any) => of(new AuthApiActions.ResetPasswordRequestFailed(error)))
            )
        )
    ));


    public resetPasswordStart$ = createEffect(() => this.actions$.pipe(
        ofType(AuthApiActions.AuthApiActionTypes.ResetPasswordStart),
        switchMap((action: any) => {
            return of(new AuthApiActions.VerifyResetPasswordToken(action.payload));
        }),
        tap(() => {
            this.authService.clearSession();
        })
    ));


    public resetPasswordFinished$ = createEffect(() => this.actions$.pipe(
        ofType(
            AuthApiActions.AuthApiActionTypes.VerifyResetPasswordTokenFailed,
            AuthApiActions.AuthApiActionTypes.ResetPasswordSuccess
        ),
        tap(() => {
            this.router.navigate(['/login']);
        })
    ), { dispatch: false });


    public verifyResetPasswordToken$ = createEffect(() => this.actions$.pipe(
        ofType(AuthApiActions.AuthApiActionTypes.VerifyResetPasswordToken),
        switchMap((action: any) => {
            return this.authService.verifyResetPasswordToken(action.payload).pipe(
                switchMap(() => of(new AuthApiActions.VerifyResetPasswordTokenSuccess())),
                catchError((error: HttpErrorResponse) => of(new AuthApiActions.VerifyResetPasswordTokenFailed(error)))
            );
        })
    ));



    public resetPassword$ = createEffect(() => this.actions$.pipe(
        ofType(AuthApiActions.AuthApiActionTypes.ResetPassword),
        switchMap((action: any) => {
            return this.authService.resetPassword(action.payload.resetPasswordCredentials, action.payload.correlationId).pipe(
                switchMap(() => of(new AuthApiActions.ResetPasswordSuccess())),
                catchError((error: HttpErrorResponse) => {
                    if (AuthHelperService.isResetPasswordValidationError(error)) {
                        return of(new AuthApiActions.ResetPasswordInvalid(error));
                    }

                    return of(new AuthApiActions.ResetPasswordFailed(error));
                })
            );
        })
    ));
}
