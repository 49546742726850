import { Injectable } from '@angular/core';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';

import { Observable, throwError, EMPTY } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { Store } from '@ngrx/store';
import * as fromRoot from '../../reducers';
import { AuthApiActions } from '../@state/actions';
import { isRelativeBffUrl } from '../utils/utils';
import { OfflineLogsBuffer } from '@core/utils/offline-logs-buffer.service';
import { Router } from '@angular/router';
import { API } from '@core/constants/api';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

    constructor(
        private store: Store<fromRoot.State>,
        private offlineLogsBuffer: OfflineLogsBuffer,
        private router: Router
    ) {}

    public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(req).pipe(
            catchError((error: HttpErrorResponse) => {
                this.persistLogs(req);

                if (this.isBuysideAuthErrorResponse(error.status, req.url)) {
                    const authAction = req.url === 'api/Session'
                        ? new AuthApiActions.TerminateSession({ isSessionTimedOut: false })
                        : new AuthApiActions.Logout();

                        this.store.dispatch(authAction);

                    return EMPTY;
                }

                if (this.isPomAuthErrorResponse(error.status, req.url)) {
                    return next.handle(req);
                }

                return throwError(error);
            })
        );
    }

    private isBuysideAuthErrorResponse(status: number, url: string): boolean {
        const isAuthErrorResponse = status === 401;

        return isAuthErrorResponse && isRelativeBffUrl(url);
    }

    private isPomAuthErrorResponse(status: number, url: string): boolean {
        return status === 401 && url.includes('pom-bff');
    }

    private persistLogs(req: HttpRequest<any>): void {
        if (!req.body || this.router.url === '/login') {
            return;
        }

        if (req.url.includes('client-logging')) {
            req.body.forEach(log => this.offlineLogsBuffer.add({
                ...log,
                extraInfo: {
                    ...log.extraInfo,
                    isOfflineLogs: true
                }
            }));
        }

        if (req.url.includes(API.logs.httpResponse)) {
            this.offlineLogsBuffer.add(req.body);
        }
    }

}
