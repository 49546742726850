import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { select, Store } from '@ngrx/store';
import * as fromRoot from '../../reducers';
import * as fromLayout from '@core/@state/reducers/layout.reducer';

import { AssetClassModel } from '@core/models/asset-class';

@Injectable({
    providedIn: 'root',
})
export class CurrentAssetNotEquityGuard implements CanActivate {

    constructor(private store: Store<fromRoot.State>) {}

    public canActivate(): Observable<boolean> {
        return this.store.pipe(
            select(fromLayout.getCurrentAssetClass),
            map((currentAssetClass: AssetClassModel) => {
                return currentAssetClass !== AssetClassModel.Equity;
            })
        );
    }
}
