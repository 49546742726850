import { Action } from '@ngrx/store';
import { BspConfiguration } from '../../configuration/bsp-configuration';

export enum ConfigurationActionTypes {
    Update = '[Configuration] Update'
}

export class Update implements Action {
    public readonly type = ConfigurationActionTypes.Update;

    constructor(public payload: { configuration: BspConfiguration }) {}
}

export type ConfigurationActionsUnion =
    | Update;
