import { OnDestroy } from '@angular/core';
import { Permissions } from '@core/permissions/permissions.model';
import { select, Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { map, withLatestFrom } from 'rxjs/operators';
import * as fromRoot from '../../../../reducers';
import { BspConfiguration } from '@core/configuration/bsp-configuration';
import { BspWebsocketGatewayService } from '@core/websockets/bsp-websocket-gateway.service';

export abstract class WebsocketBaseListener implements OnDestroy {
    private subscription: Subscription;
    private isSubscribedToUpdates = false;

    constructor(
        protected readonly websocketGatewayService: BspWebsocketGatewayService,
        protected readonly store: Store<fromRoot.State>
    ) {
    }

    protected abstract getChannelName(configuration: BspConfiguration): string;
    protected abstract isPermitted(permissions: Permissions): boolean;
    protected abstract onUpdate(payload: any): void;

    public ngOnDestroy = (): void => {
        if (!!this.subscription) {
            this.subscription.unsubscribe();
        }
    }

    public initialize = (): void => {
        this.subscription = this.store.pipe(
            select(fromRoot.getPermissionsState),
            map((permissions: Permissions) => this.isPermitted(permissions)),
            withLatestFrom(this.store.pipe(select(fromRoot.getConfigurationState)))
        ).subscribe(([isPermitted, configuration]) => {
            const channel = this.getChannelName(configuration);

            if (isPermitted && !this.isSubscribedToUpdates) {
                this.subscribeToUpdates(channel);
            } else if (!isPermitted && this.isSubscribedToUpdates) {
                this.unsubscribeFromUpdates(channel);
            }
        });
    }

    private unsubscribeFromUpdates = (channel: string): void => {
        this.websocketGatewayService.unsubscribe(channel);
        this.isSubscribedToUpdates = false;
        console.log(`WEBSOCKETS :: stopped listening to channel: '${channel}'`);
    }

    private subscribeToUpdates = (channel: string): void => {
        this.websocketGatewayService
            .subscribe(channel)
            .subscribe(payload => this.onUpdate(payload));

        this.isSubscribedToUpdates = true;
        console.log(`WEBSOCKETS :: started listening to channel: '${channel}'`);
    }
}
