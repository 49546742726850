import { Injectable, Inject } from '@angular/core';
import { createEffect, ofType, Actions } from '@ngrx/effects';
import { switchMap, tap, catchError } from 'rxjs/operators';
import { of } from 'rxjs';
import { AuthApiActions } from '../actions';
import { VersionCheckService } from '@core/configuration/version-check.service';
import { WINDOW } from '@core/services/window.service';

@Injectable()
export class VersionCheckEffect {
    constructor(
        private actions$: Actions,
        private versionCheckService: VersionCheckService,
        @Inject(WINDOW) private window: Window
    ) {}


    public versionCheck$ = createEffect(() => this.actions$.pipe(
        ofType(AuthApiActions.AuthApiActionTypes.CheckAppVersion),
        switchMap(() => {
            return this.versionCheckService.getVersion()
            .pipe(
                tap((version: { hash: string }) => {
                    const hash = version.hash;
                    const hashChanged = this.versionCheckService.hasHashChanged(this.versionCheckService.currentHash, hash);

                    if (hashChanged) {
                        this.window.location.reload();
                    }
                }),
                catchError((error) => of({...error, success: true}))
            );
        })), { dispatch: false });
}
