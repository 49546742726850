import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { noErrorHandlingForHeader } from '@core/constants/http-headers';

import { EquityUserSettingsHttpModel } from '../../modules/user-settings/models/equity-user-settings.model';

@Injectable({
    providedIn: 'root'
})
export class EquityUserSettingsService {

    private readonly noErrorHandlingHeaders = new HttpHeaders({
        [noErrorHandlingForHeader]: '*'
    });

    constructor(private http: HttpClient) {}

    public getSettings(equityBffUrl: string, noErrorHandling = true): Observable<EquityUserSettingsHttpModel[]> {
        return this.http.get<EquityUserSettingsHttpModel[]>(`${equityBffUrl}/user-settings`, {
            headers: noErrorHandling ? this.noErrorHandlingHeaders : undefined
        });
    }

    public async getSettingsPromise(equityBffUrl: string, noErrorHandling = true): Promise<EquityUserSettingsHttpModel[]> {
        try {
            return await this.getSettings(equityBffUrl, noErrorHandling).toPromise();
        }
        catch (error) {
            console.error(error);
            return [];
        }
    }

    public saveSettings(equityBffUrl: string, userSettings: EquityUserSettingsHttpModel): Observable<{}> {
        return this.http.put<{}>(`${equityBffUrl}/user-settings`, [userSettings]);
    }
}
