import { ClientExtendedExtraInfo, ClientLocalStorageExtraInfo, ClientLogExtraInfo } from '@core/logger/log.model';
import { Inject, Injectable } from '@angular/core';
import { StorageService } from '@core/services/storage.service';
import { JwtHelperService } from '@auth0/angular-jwt';
import { WINDOW } from '@core/services/window.service';
import {
    appSessionTimeoutStorageKey, jwtTokenV2StorageKey, pomJwtTokenStorageKey,
    websocketGatewayTokenStorageKey,
} from '@core/constants/constants';
import * as fromLogs from '../@state/reducers/logs.reducer';
import { VersionCheckService } from '@core/configuration/version-check.service';
import { IdleService } from '@core/services/idle.service';
import { generateGUID } from '@core/utils/utils';
import { DOCUMENT } from '@angular/common';
import { JwtStorageService } from '@core/authentication/jwt/jwt-storage.service';

@Injectable({
    providedIn: 'root'
})
export class LoggerHelperService {

    private readonly tabId: string;

    constructor(
        private storageService: StorageService,
        private jwtHelperService: JwtHelperService,
        private versionCheckService: VersionCheckService,
        private idleService: IdleService,
        private jwtStorageService: JwtStorageService,
        @Inject(WINDOW) private window: Window,
        @Inject(DOCUMENT) private document: Document
    ) {
        this.tabId = generateGUID();
    }

    public aggregateExtraInfo(extraInfo: ClientLogExtraInfo, logState: fromLogs.State): ClientExtendedExtraInfo {
        const pomJwtToken = this.jwtStorageService.get(pomJwtTokenStorageKey);
        const wsGatewayToken = this.jwtStorageService.get(websocketGatewayTokenStorageKey);
        const jwtTokenV2 = this.jwtStorageService.get(jwtTokenV2StorageKey);

        return {
            ...extraInfo,
            userAgent: this.window.navigator.userAgent,
            href: this.window.location.href,
            localStorage: this.getLocalStorageEntries(),
            pomJwtTokenExpiresAt: pomJwtToken ? this.jwtHelperService.getTokenExpirationDate(pomJwtToken) : null,
            wsGatewayTokenExpiresAt: wsGatewayToken ? this.jwtHelperService.getTokenExpirationDate(wsGatewayToken) : null,
            jwtTokenV2ExpiresAt: jwtTokenV2 ? this.jwtHelperService.getTokenExpirationDate(jwtTokenV2) : null,
            ngrxActions: logState.ngrxActions,
            elementsClicked: logState.elementsClicked,
            httpRequests: logState.httpRequests,
            version: this.versionCheckService.currentHash,
            authInfo: logState.authInfo,
            tabId: this.tabId,
            isHidden: this.document.hidden,
            isOnline: this.window.navigator.onLine
        };
    }

    private getLocalStorageEntries(): ClientLocalStorageExtraInfo {
        return {
            idleStartedAt: this.idleService.idleExpiryDateTime ? this.idleService.idleExpiryDateTime.time : null,
            isSessionTimedOut: this.getLocalStorageValue<boolean>(appSessionTimeoutStorageKey, 'value')
        };
    }

    private getLocalStorageValue<T>(localStorageKey: string, pluck: string = null): T {
        const valueString = this.storageService.localStorage.getItem(localStorageKey);

        if (valueString) {
            try {
                const value = JSON.parse(valueString);

                return pluck ? value[pluck] : value;
            } catch (err) {
                return null;
            }
        }

        return null;
    }

}
