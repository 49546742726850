import { WebsocketConnectionActions } from '../../actions';

export interface State {
    isConnected: boolean;
}

export const initialState: State = {
    isConnected: false
};

export function websocketConnectionReducer(
    state: State = initialState,
    action: WebsocketConnectionActions.WebsocketConnectionActionsUnion
): State {
    switch (action.type) {
        case WebsocketConnectionActions.WebsocketConnectionActionTypes.ConnectionChanged:
            return {
                ...state,
                isConnected: action.payload.isConnected
            };
        default:
            return state;
    }
}
