export enum WarningType {
    InvalidCredentials = 'Invalid Credentials',
    AccountLocked = 'Account Locked',
    ResetPassword = 'Reset Password',
    ResetPasswordFailed = 'Reset Password Failed',
    ChangePasswordFailed = 'Change Password Failed',
    ResetPasswordNoEmail = 'Reset Password No Email in Request',
    LoginFailed = 'Login Failed',
    ResetPasswordLinkExpired = 'Reset Password Link Expired',
    IpNotWhitelisted = 'Ip Not Whitelisted',
    PasswordMatchesPrevious = 'New password and Old password are the same',
    PasswordIsUsedPreviously = 'Password Is Used Previously',
    PasswordRequirementMissed = 'Password Requirement Missed',
    CurrentPasswordDoNotMatch = 'Does not match current password',
    SessionTimedOut = 'Session Timed Out',
    PasswordMismatch = 'Passwords Mismatch',
    UserNewPasswordSameAsOld = 'Previously used five passwords are not allowed. Please choose a new password.',
    PasswordCrieteriaNotFullfiled = 'Password does not meet complexity requirements.',
    PasswordRepeatedCharacters = `Password cannot contain repeated characters (e.g.,'aaa' or '111' or '$$$').`,
    PasswordContainsUserName = 'Password cannot contain your First Name/Last Name. Please choose a different password.',
    PasswordContainsUserIdOrEmail = 'Password cannot contain your username. Please choose a different password.',
    SeqCharsInPasswordValidator = 'Must not contain user account name or contiguous part of first, last name or email that exceeds two consecutive characters.',
    PasswordContainsRestrictedSpecialChar = 'Password contains forbidden characters. Please use only the following special characters: @#$%^&*+',
}
