import { Component, ChangeDetectionStrategy, OnInit, Input } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, combineLatest, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';

import { environment } from '@environments/environment';
import * as fromLayout from '@core/@state/reducers/layout.reducer';
import * as fromRoot from '../../../reducers';
import { AssetClassModel } from '../../models/asset-class';
import { RoutingHelperService } from '../../services/routing-helper.service';
import { NavigationEnd, NavigationStart, Router } from '@angular/router';
import { Location } from '@angular/common';

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class HeaderComponent implements OnInit {

    @Input() public pomToken$: Observable<string>;

    public assetClassModel = AssetClassModel;
    public assetsURL = environment.assetsURL;
    public notificationsAppCode$: Observable<string>;
    public currentAssetClass$: Observable<AssetClassModel>;
    public homePageRoute$: Observable<string>;

    private routerEventsSubscription: Subscription;
    private previousUrl = '';
    private userContainerId: string;
    private userContainerIdSubscription: Subscription;

    constructor(
        private store: Store<fromRoot.State>,
        private router: Router,
        private location: Location
    ) {}

    public ngOnInit(): void {
        this.notificationsAppCode$ = this.store.select(fromRoot.getConfigurationState)
            .pipe(map(appConfig => appConfig.ipreoSocketsAppCode));

        this.homePageRoute$ = combineLatest([
            this.store.select(fromRoot.getPermissionsState),
            this.store.select(fromRoot.getHomePage)
        ]).pipe(map(([permissions, homePage]) => RoutingHelperService.getHomePageRoute(permissions, homePage)));

        this.currentAssetClass$ = this.store.select(fromLayout.getCurrentAssetClass);

        this.userContainerIdSubscription = this.store.select(fromRoot.getUserSettingsState)
            .subscribe(settings => this.userContainerId = settings.containers[0].id);

        /**
         * Custom handling of redirection to/from Admin Page and EOF - discard changes
         * since there was no ng out-of-the-box solution
         */
        this.routerEventsSubscription = this.router.events
            .subscribe((event) => {
                if (event instanceof NavigationEnd) {
                    this.previousUrl = event.url;
                }

                if (
                    event instanceof NavigationStart
                    && (event.url === '/admin' || event.url === '/company-connections')
                    && event.navigationTrigger === 'popstate'
                ) {
                    if (this.previousUrl === `/admin/${this.userContainerId}/companies`) {
                        // necessary to correctly handle browser back navigation from admin page
                        this.location.back();
                        this.location.back();
                    } else {
                        this.router.navigateByUrl(`/admin/${this.userContainerId}/companies`);
                    }
                }
            })
    }

}
