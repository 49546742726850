import { UserSettingsActions } from '../../actions';
import { UserSettings } from '@core/user-settings/user-settings.model';

export type State = UserSettings;

export const initialState: State = null;

export function userSettingsReducer(
    state: State = initialState,
    action: UserSettingsActions.SettingsActionsUnion
): State {
    switch (action.type) {
        case UserSettingsActions.SettingsActionTypes.Update:
            return {
                ...action.payload.settings,
            };

        case UserSettingsActions.SettingsActionTypes.UpdateSetting:
            return {
                ...state,
                ...action.payload.setting
            };

        case UserSettingsActions.SettingsActionTypes.UpdateHomePage:
            return {
                ...state,
                profile: {
                    ...state.profile,
                    homePage: action.payload.homePage
                }
            };

        case UserSettingsActions.SettingsActionTypes.UpdateIsAccessingFromUS:
            return {
                ...state,
                isAccessingFromUS: action.payload.isAccessingFromUS
            };

        case UserSettingsActions.SettingsActionTypes.ToggleHideEmptyFields:
            return {
                ...state,
                hideEmptyFields: action.payload.hideEmptyFields
            };

        case UserSettingsActions.SettingsActionTypes.ToggleHighlightChanges:
            return {
                ...state,
                highlightChanges: action.payload.showChanges
            };

        case UserSettingsActions.SettingsActionTypes.TermsOfUseConfirmed:
            return {
                ...state,
                profile: {
                    ...state.profile,
                    acceptTermsOfUseRequired: false
                }
            };

        case UserSettingsActions.SettingsActionTypes.PasswordChange:
            return {
                ...state,
                profile: {
                    ...state.profile,
                    passwordChangeRequired: false
                }
            };
        case UserSettingsActions.SettingsActionTypes.Clear:
            return initialState;
        case UserSettingsActions.SettingsActionTypes.UpdateProfile: {
            return {
                ...state,
                profile: { ...action.payload }
            };
        }
        default:
            return state;
    }
}
