import { Injectable } from '@angular/core';
import { Router, CanActivate } from '@angular/router';

import { Observable, combineLatest } from 'rxjs';
import { map, tap } from 'rxjs/operators';

import { select, Store } from '@ngrx/store';
import * as fromRoot from '../../reducers';
import { RoutingHelperService } from '../services/routing-helper.service';

@Injectable({
    providedIn: 'root'
})
export class HomePageGuard implements CanActivate {
    constructor(private readonly store: Store<fromRoot.State>, private readonly router: Router) {
    }

    public canActivate(): Observable<boolean> {
        return combineLatest(
            this.store.pipe(select(fromRoot.getHomePage)),
            this.store.pipe(select(fromRoot.getPermissionsState))
        ).pipe(
            tap(([homePage, permissions]) => {
                const allowedHomePage = RoutingHelperService.getHomePageRoute(permissions, homePage);

                this.router.navigate([allowedHomePage]);
            }),
            map(_ => true)
        );
    }
}
