import { Component } from '@angular/core';

import { FooterLinks } from '@ipreo/ngx-sprinkles/lib/footer/footer.component';

import { RoutingHelperService } from '@core/services/routing-helper.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent {

    public readonly FOOTER_LINKS: FooterLinks[] = [
        {
            name: 'Regulatory Terms and Disclosures',
            href: `#${RoutingHelperService.ROUTER_LINKS.regulatoryDisclosures}`,
            openInNewTab: false
        },{
            name: 'Disclaimers',
            href: `#${RoutingHelperService.ROUTER_LINKS.disclaimers}`,
            openInNewTab: false
        },{
            name: 'Third Party Data',
            href: 'https://ihsmarkit.com/Legal/terms-of-use.html',
            openInNewTab: true
        },{
            name: 'Privacy Policy',
            href: 'https://ihsmarkit.com/legal/privacy-policy.html',
            openInNewTab: true
        },{
            name: 'Terms of Use',
            href: 'https://ihsmarkit.com/Legal/gcp-products-terms-of-use.html',
            openInNewTab: true
        }
    ];

}
