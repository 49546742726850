import { Permissions } from '@core/permissions/permissions.model';
import { HomePage } from '../../modules/shared/models/home-pages.model';
import { AssetClassModel } from '@core/models/asset-class';

export interface RouterLinks {
    readonly fixedIncomeDealMonitor: string;
    readonly equityDealMonitor: string;
    readonly equityDealQuery: string;
    readonly equityPublicDeals: string;
    readonly dealQuery: string;
    readonly activityStream: string;
    readonly municipalStreetCalendar: string;
    readonly municipalDealMonitor: string;
    readonly compliance: string;
    readonly analytics: string;
    readonly connections: string;
    readonly adminTab: string;
    readonly userSettings: string;
    readonly userProfile: string;
    readonly support: string;
    readonly login: string;
    readonly logout: string;
    readonly regulatoryDisclosures: string;
    readonly disclaimers: string;
    readonly acceptTerms: string;
    readonly equityAnalytics: string;
}

export class RoutingHelperService {

    public static ROUTER_LINKS: RouterLinks = {
        municipalStreetCalendar: '/muni-deals/calendar',
        municipalDealMonitor: '/muni-deals/manager',
        support: '/support',
        userProfile: '/user-profile',
        userSettings: '/user-settings',
        fixedIncomeDealMonitor: '/fixedincome',
        equityDealMonitor: '/equity/deal-monitor',
        equityDealQuery: '/equity/deal-query',
        equityPublicDeals: '/equity/public-deals',
        dealQuery: '/deal-query',
        activityStream: '/activity',
        compliance: '/compliance',
        analytics: '/fixedincome/analytics',
        connections: '/company-connections',
        adminTab: '/admin',
        login: '/login',
        regulatoryDisclosures: '/regulatory-disclosures',
        disclaimers: '/disclaimers',
        acceptTerms: '/accept-terms',
        logout: '/logout',
        equityAnalytics: '/equity/analytics'
    };

    public static isAnonymousAccessAllowed(url: string): boolean {
        const resetPasswordRegexp = new RegExp('reset((-)|(_))password.+(code=).+(u=)');

        return resetPasswordRegexp.test(url);
    }

    public static getHomePageRoute(
        permissions: Permissions,
        homePage: string
    ): string {
        const allowedHomePage = RoutingHelperService.getHomePage(permissions, homePage.toLowerCase());

        return RoutingHelperService.getRouterLink(allowedHomePage);
    }

    private static getRouterLink(page: string): string {
        switch (page) {
            case HomePage.default:
            case HomePage.municipal:
                return RoutingHelperService.ROUTER_LINKS.municipalStreetCalendar;
            case HomePage.fixedincome:
                return RoutingHelperService.ROUTER_LINKS.fixedIncomeDealMonitor;
            case HomePage.activitystream:
                return RoutingHelperService.ROUTER_LINKS.activityStream;
            case HomePage.compliance:
                return RoutingHelperService.ROUTER_LINKS.compliance;
            case HomePage.equity:
                return RoutingHelperService.ROUTER_LINKS.equityDealMonitor;
            case HomePage.equitydealquery:
                return RoutingHelperService.ROUTER_LINKS.equityDealQuery;
            case HomePage.equitypublicdeals:
                return RoutingHelperService.ROUTER_LINKS.equityPublicDeals;
            case HomePage.query:
                return RoutingHelperService.ROUTER_LINKS.dealQuery;
            default:
                return '';
        }
    }

    public static getHomePage(
        permissions: Permissions,
        homePage: string): string {

        if (!homePage) {
            return HomePage.default;
        }

        if (homePage !== HomePage.default && RoutingHelperService.isPageAllowed(homePage, permissions)) {

            return homePage;

        } else {
            return RoutingHelperService.getDefaultPage(permissions);
        }
    }

    private static getDefaultPage(permissions: Permissions): string {

        if (RoutingHelperService.isPageAllowed(HomePage.fixedincome, permissions)
            && RoutingHelperService.isPageAllowed(HomePage.municipal, permissions)
            && RoutingHelperService.isEquityAccess(permissions)) {
            return HomePage.fixedincome;
        }

        if (RoutingHelperService.isPageAllowed(HomePage.municipal, permissions)) {
            return HomePage.municipal;
        }

        if (RoutingHelperService.isPageAllowed(HomePage.equitypublicdeals, permissions)) {
            return HomePage.equitypublicdeals;
        }

        if (RoutingHelperService.isPageAllowed(HomePage.fixedincome, permissions)) {
            return HomePage.fixedincome;
        }

        if (RoutingHelperService.isPageAllowed(HomePage.equity, permissions)) {
            return HomePage.equity;
        }

        if (RoutingHelperService.isPageAllowed(HomePage.equitydealquery, permissions)) {
            return HomePage.equitydealquery;
        }

        if (RoutingHelperService.isPageAllowed(HomePage.query, permissions)) {
            return HomePage.query;
        }

        if (RoutingHelperService.isPageAllowed(HomePage.activitystream, permissions)) {
            return HomePage.activitystream;
        }

        if (RoutingHelperService.isPageAllowed(HomePage.compliance, permissions)) {
            return HomePage.compliance;
        }

        return HomePage.default;
    }

    private static isEquityAccess(permissions: Permissions) {
        return permissions.canViewEquityDealMonitor || permissions.canViewEquityDealQuery || permissions.canViewEquityPublicDeals;
    }

    private static isPageAllowed(page: string, permissions: Permissions): boolean {
        switch (page) {
            case HomePage.fixedincome:
                return permissions.canViewFixedIncomeDealMonitor;
            case HomePage.equity:
                return permissions.canViewEquityDealMonitor;
            case HomePage.equitydealquery:
                return permissions.canViewEquityDealQuery;
            case HomePage.equitypublicdeals:
                return permissions.canViewEquityPublicDeals;
            case HomePage.query:
                return permissions.canViewDealQuery;
            case HomePage.activitystream:
                return permissions.canViewActivityStream;
            case HomePage.compliance:
                return permissions.canViewCompliance;
            case HomePage.municipal:
                return permissions.canViewMunicipalDealMonitor || permissions.canViewMunicipalStreetCalendar;
            default:
                return false;
        }
    }

    public static getAssetClassLink(assetClass: AssetClassModel): string {
        switch (assetClass) {
            case AssetClassModel.Municipal:
                return 'muni-deals';
            case AssetClassModel.Equity:
                return 'equity';
            case AssetClassModel.FixedIncome:
            default:
                return 'fixedincome';
        }
    }
}
