import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { map, filter } from 'rxjs/operators';
import { ConfigActions, SetAlertsTokenAction } from './actions';
import { MessagesStatusLoadAction } from '../messages-status/actions';

@Injectable()
export class ConfigEffects {
    public loadMessages$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ConfigActions.SetToken),
            filter((action: SetAlertsTokenAction) => !!action.payload),
            map(() => new MessagesStatusLoadAction())
        )
    );

    constructor(private actions$: Actions) {
    }
}
