import * as fromRoot from '../../../reducers';
import * as fromUserSettingsPage from '../../../reducers/index';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { EquityUserSettingsActions, MuniUserSettingsActions, UserSettingsActions, UserSettingsPageActions } from '@core/@state/actions';
import { Store, select } from '@ngrx/store';
import { catchError, filter, map, mergeMap, switchMap, withLatestFrom } from 'rxjs/operators';
import { mapEquityUserSettingsFromHttp, mapEquityUserSettingsToHttp } from '../../user-settings/equity-user-settings-mapper.service';

import { BspConfiguration } from '../../configuration/bsp-configuration';
import { EquityUserSettingsService } from '../../user-settings/equity-user-settings.service';
import { HttpUserSettingsPageService } from '../../../modules/user-settings/services/user-settings-page.service';
import { Injectable } from '@angular/core';
import { MuniUserSettingHttpModel } from 'src/app/modules/user-settings/models/muni-user-settings-http.model';
import { MuniUserSettingsHttpService } from '@core/user-settings/muni-user-settings-http.service';
import { UserSettingsHttpModel } from '../../../modules/user-settings/models/user-settings-http.model';
import { UserSettingsService } from '@core/user-settings/user-settings.service';
import { of } from 'rxjs';
import { FixedIncomeOeLoad } from '../actions/user-settings-page.actions';

@Injectable()
export class UserSettingsPageEffect {

    constructor(
        private actions$: Actions,
        private httpUserSettingsPageService: HttpUserSettingsPageService,
        private httpMuniUserSettingsPageService: MuniUserSettingsHttpService,
        private userSettingsService: UserSettingsService,
        private store: Store<fromRoot.State>,
        private equityUserSettingsService: EquityUserSettingsService
    ) { }


    public syncUserSettings$ = createEffect(() => this.actions$.pipe(
        ofType(
            UserSettingsPageActions.UserSettingsPageActions.FixedIncomeLoad
        ),
        switchMap(() => this.httpUserSettingsPageService.getUserSettings()
            .pipe(
                map((data: UserSettingsHttpModel) => {
                    return new UserSettingsPageActions.FixedIncomeLoadSuccess(data);
                }),
                catchError((error: any) => of(new UserSettingsPageActions.FixedIncomeLoadFailed(error)))
            )
        )
    ));

    public triggerOperationEntitiesLoad$ = createEffect(() => this.actions$.pipe(
        ofType(
            UserSettingsPageActions.UserSettingsPageActions.FixedIncomeLoadSuccess
        ),
        withLatestFrom(this.store.pipe(select(fromRoot.getIsAnyOperationalEntityCompany))),
        filter(([_, isAnyOeCompany]) => isAnyOeCompany),
        map(() => new FixedIncomeOeLoad())
    ));

    public loadOperationEntitiesUserSettings$ = createEffect(() => this.actions$.pipe(
        ofType(
            UserSettingsPageActions.UserSettingsPageActions.FixedIncomeOeLoad
        ),
        withLatestFrom(this.store.pipe(select(fromRoot.getConfigurationState))),
        switchMap(([_, configuration]) =>
            this.httpUserSettingsPageService.getOperationalEntities(configuration.nsBffApiRootUrl)
                .pipe(
                    map(oeItems => new UserSettingsPageActions.FixedIncomeOeLoadSuccess(oeItems)),
                    catchError(error => of(new UserSettingsPageActions.FixedIncomeOeLoadFailed(error)))
                )
        )
    ));

    public loadEquityUserSettings$ = createEffect(() => this.actions$.pipe(
        ofType(
            UserSettingsPageActions.UserSettingsPageActions.EquityLoad
        ),
        withLatestFrom(this.store.pipe(select(fromRoot.getPermissionsState))),
        filter(([_, permissions]) => permissions.canViewEquityDealMonitor),
        withLatestFrom(this.store.pipe(select(fromRoot.getConfigurationState))),
        switchMap(([_, configuration]) => this.equityUserSettingsService.getSettings(configuration.equityBffUrl, false)
            .pipe(
                map(data => {
                    const equityUserSettings = mapEquityUserSettingsFromHttp(data);
                    return new UserSettingsPageActions.EquityLoadSuccess(equityUserSettings);
                }),
                catchError((error: any) => of(new UserSettingsPageActions.EquityLoadFailed(error)))
            )
        )
    ));

    public syncMuniUserSettings$ = createEffect(() => this.actions$.pipe(
        ofType(
            UserSettingsPageActions.UserSettingsPageActions.MuniLoad
        ),
        withLatestFrom(
            this.store.pipe(select(fromRoot.getMuniBffUrl)),
            this.store.pipe(select(fromRoot.getHasMmdDataPermission))
        ),
        switchMap(([action, muniBffUrl, hasMmdDataPermission]) => this.httpMuniUserSettingsPageService.getUserSettings(muniBffUrl)
            .pipe(
                map((muniSettings: MuniUserSettingHttpModel[]) =>
                    new UserSettingsPageActions.MuniLoadSuccess({ muniSettings, hasMmdDataPermission })),
                catchError((error: any) => of(new UserSettingsPageActions.MuniLoadFailed(error)))
            )
        )
    ));


    public updateMuniUserSettings$ = createEffect(() => this.actions$.pipe(
        ofType(
            UserSettingsPageActions.UserSettingsPageActions.MuniUpdate
        ),
        switchMap((action: UserSettingsPageActions.MuniUpdate) =>
            this.httpMuniUserSettingsPageService.updateUserSetting(action.payload)
                .pipe(
                    map((updatedUserSetting: MuniUserSettingHttpModel) => {
                        return new UserSettingsPageActions.MuniUpdateSuccess(updatedUserSetting);
                    }),
                    catchError((error: any) => of(new UserSettingsPageActions.MuniUpdateFailed(error)))
                )
        )
    ));


    public updateMuniUserSettingsInSharedStore$ = createEffect(() => this.actions$.pipe(
        ofType(
            UserSettingsPageActions.UserSettingsPageActions.MuniUpdateSuccess
        ),
        map((action: UserSettingsPageActions.MuniUpdateSuccess) => new MuniUserSettingsActions.Update(action.payload))
    ));


    public updateUserSettings$ = createEffect(() => this.actions$.pipe(
        ofType(
            UserSettingsPageActions.UserSettingsPageActions.FixedIncomeUpdate
        ),
        withLatestFrom(
            this.store.pipe(select(fromUserSettingsPage.getUserSettingsPageState))
        ),
        mergeMap(([action, userSettings]: [UserSettingsPageActions.FixedIncomeUpdate, any]) =>
            this.httpUserSettingsPageService
                .updateUserSetting(userSettings.fixedIncomeModel.httpModel, action.payload.settingName, action.payload.fieldName, action.payload.value)
                .pipe(
                    filter(() => {
                        const { settingName, fieldName } = action.payload;

                        return !!this.userSettingsService.getSettingStoreName(settingName, fieldName);
                    }),
                    map(() => {
                        const { settingName, fieldName } = action.payload;
                        const userSettingsSharedFormat = this.userSettingsService.getSettingStoreName(settingName, fieldName);

                        if (userSettingsSharedFormat === 'homePage') {
                            return new UserSettingsActions.UpdateHomePage({ homePage: action.payload.value });
                        } else if (userSettingsSharedFormat) {
                            const payload = {
                                [userSettingsSharedFormat]: action.payload.settingName === 'app_userSettings'
                                    ? this.userSettingsService.mapUserSettingValue(userSettingsSharedFormat, action.payload.value)
                                    : action.payload.value
                            };

                            return new UserSettingsActions.UpdateSetting({ setting: payload });
                        } else {
                            return undefined;
                        }
                    }),
                    catchError((error: any) => of(new UserSettingsPageActions.FixedIncomeUpdateFailed(error)))
                )
        )
    ));

    public updateUserHomePageSettings$ = createEffect(() => this.actions$.pipe(
        ofType(
            UserSettingsPageActions.UserSettingsPageActions.EquityHomePageUpdate
        ),
        mergeMap((action: UserSettingsPageActions.EquityHomePageUpdate) =>
            this.httpUserSettingsPageService
                .updateUserSettingShared(action.payload.settingName, action.payload.fieldName, action.payload.value)
                .pipe(
                    map(() => {
                        return new UserSettingsActions.UpdateHomePage({ homePage: action.payload.value });
                    }),
                    catchError((error: any) => of(new UserSettingsPageActions.FixedIncomeUpdateFailed(error)))
                )
        )
    ));

    public updateEquityUserSettings$ = createEffect(() => this.actions$.pipe(
        ofType(
            UserSettingsPageActions.UserSettingsPageActions.EquityUpdate
        ),
        withLatestFrom(this.store.pipe(select(fromRoot.getConfigurationState))),
        switchMap(([action, configuration]: [UserSettingsPageActions.EquityUpdate, BspConfiguration]) => {
            const { settingName, settingValue } = action.payload;
            const equityUserHttpSetting = mapEquityUserSettingsToHttp(settingName, settingValue);

            return this.equityUserSettingsService.saveSettings(configuration.equityBffUrl, equityUserHttpSetting).pipe(
                map(_ => new UserSettingsPageActions.EquityUpdateSuccess(action.payload)),
                catchError((error: any) => of(new UserSettingsPageActions.EquityLoadFailed(error)))
            )
        })
    ));

    public updateEquityUserSettingsInSharedStore$ = createEffect(() => this.actions$.pipe(
        ofType(
            UserSettingsPageActions.UserSettingsPageActions.EquityUpdateSuccess
        ),
        map((action: UserSettingsPageActions.EquityUpdateSuccess) => {
            return new EquityUserSettingsActions.Update(action.payload)
        })
    ));
}
