import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { IJwtTokenProvider, JwtTokenRetry } from '../jwt.lifetime.processor';
import { noErrorHandlingForHeader } from '@core/constants/http-headers';
import { API } from '@core/constants/api';

@Injectable({
    providedIn: 'root'
})
export class JwtTokenV2Provider implements IJwtTokenProvider {
    constructor(
        private http: HttpClient
    ) {}

    public readonly retryTimeout: JwtTokenRetry = JwtTokenRetry.NoRetry;

    public async fetchToken(): Promise<string> {
        const headers = new HttpHeaders().set(noErrorHandlingForHeader, '*');
        return await this.http.post<string>(API.tokens.jwtTokenV2, null, { headers }).toPromise();
    }
}
