import { isPlatformBrowser } from '@angular/common';
import { ClassProvider, FactoryProvider, InjectionToken, PLATFORM_ID, Injectable } from '@angular/core';

export const WINDOW = new InjectionToken('WindowToken');

export abstract class AbstractWindowRef {

    get window(): Window | Object {
        throw new Error('Not implemented.');
    }

}

@Injectable()
export class WindowRef extends AbstractWindowRef {

    constructor() {
        super();
    }

    get window(): Window {
        return window;
    }

}

export function windowFactory(windowRef: WindowRef, platformId: Object): Window | Object {
    if (isPlatformBrowser(platformId)) {
        return windowRef.window;
    }

    return null;
}

export const windowRefProvider: ClassProvider = {
    provide: AbstractWindowRef,
    useClass: WindowRef
};

export const windowProvider: FactoryProvider = {
    provide: WINDOW,
    useFactory: windowFactory,
    deps: [ AbstractWindowRef, PLATFORM_ID ]
};
