import { BookStateNotificationsTypes, EquityNotificationSetting, DealDetailsNotificationsTypes, DealStateNotificationsTypes, NotificationsTypes }
    from './../../modules/user-settings/models/equity-user-settings.model';
import {
    EquityUserSettingsHttpModel,
    EquityUserSettingsModel
} from '../../modules/user-settings/models/equity-user-settings.model';
import { NotificationDisplayOption } from '../../modules/shared/models/notification-options.model';
import { isNullOrUndefined } from '../utils/utils';

export type EquitySettingType = string | NotificationsTypes;

type SettingMapping = {
    [key in keyof EquityUserSettingsModel]: {
        key: string;
        default: EquitySettingType;
    };
}

interface SettingValue<T> {
    version: '1',
    value: T;
}

export const EquitySettingMappings: SettingMapping = {
    numberFormat: {
        key: 'UserSettingsNumberFormat',
        default: 'Format0'
    },
    dateFormat: {
        key: 'UserSettingsDateFormat',
        default: 'DD MMM YY'
    },
    timeFormat: {
        key: 'UserSettingsTimeFormat',
        default: 'HH:mm'
    },
    timezone: {
        key: 'UserSettingsTimezone',
        default: 'EST'
    },
    equityBookState: {
        key: 'EquityBookState',
        default:
             {
                open: NotificationDisplayOption.Always,
                closed: NotificationDisplayOption.Always,
                allocated: NotificationDisplayOption.Never
             }
    },
    equityBookStateNotificationSetting: {
        key: 'EquityBookStateNotificationSetting',
        default: {
            enable: true
        }
    },
    equityDealState: {
        key: 'EquityDealState',
        default: {
            announced: NotificationDisplayOption.Always,
            priced: NotificationDisplayOption.Always
        }
    },
    equityDealStateNotificationSetting: {
        key: 'EquityDealStateNotificationSetting',
        default: {
            enable: true
        }
    },
    equityDealDetailsState: {
        key: 'EquityDealDetailsState',
        default: {
            changeInFilePriceOrFileRange: NotificationDisplayOption.Always
        }
    },
    equityDealDetailsNotificationSetting: {
        key: 'EquityDealDetailsNotificationSetting',
        default: {
            enable: true
        }
    },
    equityBookStateEmail: {
        key: 'EquityBookStateEmail',
        default: {
            open: NotificationDisplayOption.Never,
            closed: NotificationDisplayOption.Never,
            allocated: NotificationDisplayOption.Never
        }
    },
    equityBookStateEmailNotificationSetting: {
        key: 'EquityBookStateEmailNotificationSetting',
        default: {
            enable: false
        }
    },
    equityDealStateEmail: {
        key: 'EquityDealStateEmail',
        default: {
            announced: NotificationDisplayOption.Never,
            priced: NotificationDisplayOption.Never
        }
    },
    equityDealStateEmailNotificationSetting: {
        key: 'EquityDealStateEmailNotificationSetting',
        default: {
            enable: false
        }
    },
    equityDealDetailsStateEmail: {
        key: 'EquityDealDetailsStateEmail',
        default: {
            changeInFilePriceOrFileRange: NotificationDisplayOption.Never
        }
    },
    equityDealDetailsEmailNotificationSetting: {
        key: 'EquityDealDetailsEmailNotificationSetting',
        default: {
            enable: false
        }
    }
};

const toStringifyValue = <T>(value: T): string => {
    const settingValue = <SettingValue<T>> { value, version: '1' };
    return JSON.stringify(settingValue);
}

const fromStringifyValue = <T>(value: string): T => {
    if (isNullOrUndefined(value)) {
        return null;
    }

    try {
        const settingValue = JSON.parse(value) as SettingValue<T>;
        return settingValue?.value;

    }
    catch (error) {
        console.error(`Error reading value from equity user settings. Value should be valid JSON object. Actual value: "${value}"`);
        return null;
    }
}

const getMappedValue = <T>(equityUserSettings: EquityUserSettingsHttpModel[], key: keyof EquityUserSettingsModel): T => {
    const valueStr = equityUserSettings.find(s => s.type === EquitySettingMappings[key].key)?.value;
    const value = fromStringifyValue<T>(valueStr);
    if (value === null) {
        return EquitySettingMappings[key].default as unknown as T;
    }

    if (typeof(value) === 'string') {
        return value;
    }

    return Object.keys(EquitySettingMappings[key].default)
        .reduce<T>((acc, curr) => ({
            ...acc,
            [curr]: value[curr] ?? EquitySettingMappings[key].default[curr]
        }), <T>{});
}

export const mapEquityUserSettingsFromHttp = (settings: EquityUserSettingsHttpModel[]): EquityUserSettingsModel => (
    {
        numberFormat: getMappedValue<string>(settings, 'numberFormat'),
        dateFormat: getMappedValue<string>(settings, 'dateFormat'),
        timeFormat: getMappedValue<string>(settings, 'timeFormat'),
        timezone: getMappedValue<string>(settings, 'timezone'),
        equityBookStateNotificationSetting: getMappedValue<EquityNotificationSetting>(settings,'equityBookStateNotificationSetting'),
        equityBookState: getMappedValue<BookStateNotificationsTypes>(settings,'equityBookState'),
        equityDealState: getMappedValue<DealStateNotificationsTypes>(settings, 'equityDealState'),
        equityDealStateNotificationSetting: getMappedValue<EquityNotificationSetting>(settings, 'equityDealStateNotificationSetting'),
        equityDealDetailsState: getMappedValue<DealDetailsNotificationsTypes>(settings, 'equityDealDetailsState'),
        equityDealDetailsNotificationSetting: getMappedValue<EquityNotificationSetting>(settings, 'equityDealDetailsNotificationSetting'),
        equityBookStateEmail: getMappedValue<BookStateNotificationsTypes>(settings,'equityBookStateEmail'),
        equityBookStateEmailNotificationSetting: getMappedValue<EquityNotificationSetting>(settings,'equityBookStateEmailNotificationSetting'),
        equityDealStateEmail: getMappedValue<DealStateNotificationsTypes>(settings,'equityDealStateEmail'),
        equityDealStateEmailNotificationSetting: getMappedValue<EquityNotificationSetting>(settings,'equityDealStateEmailNotificationSetting'),
        equityDealDetailsStateEmail: getMappedValue<DealDetailsNotificationsTypes>(settings,'equityDealDetailsStateEmail'),
        equityDealDetailsEmailNotificationSetting: getMappedValue<EquityNotificationSetting>(settings,'equityDealDetailsEmailNotificationSetting')
    });

export const mapEquityUserSettingsToHttp = (settingKey: keyof EquityUserSettingsModel, settingValue: EquitySettingType): EquityUserSettingsHttpModel => ({
    type: EquitySettingMappings[settingKey].key,
    value: toStringifyValue(settingValue)
});
