import { EquitySupportContact } from './equity-support-contact.model';

export interface EquitySupport {
    emailPurpose: EmailPurpose;
    emailModel: EquitySupportContact;
}

export enum EmailPurpose {
    SupportRequest,
}
