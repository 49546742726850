import {
    AuthApiActions,
    LoggerActions,
    PermissionsActions,
    UserSettingsActions,
    MuniUserSettingsActions,
    CountriesActions,
    UserLoginDataActions
} from '../actions';
import { AuthService } from '../../authentication/auth.service';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { PermissionService } from '../../permissions/permissions.service';
import { UserSettingsService } from '../../user-settings/user-settings.service';
import { UserContextHttpService } from '../../usercontext/user-context-http.service';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of, forkJoin, from, combineLatest } from 'rxjs';
import { tap, withLatestFrom, catchError, map, switchMap  } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { Store, select } from '@ngrx/store';
import * as fromRoot from '../../../reducers';
import { PendoService } from '@core/services/pendo.service';
import { BuysideProductIntegrationService } from '@core/services/buyside-product-integration.service';
import { JwtLifetimeProcessor, JwtTokenAcquisitionInfo } from '@core/authentication/jwt/jwt.lifetime.processor';
import {
    JwtTokenType,
    jwtTokenV2StorageKey,
    pomJwtTokenStorageKey,
    websocketGatewayTokenStorageKey,
    xsrfTokenStorageKey
} from '@core/constants/constants';
import { MuniUserSettingsHttpService } from '@core/user-settings/muni-user-settings-http.service';
import { Country } from '@core/models/country.model';
import { API } from '@core/constants/api';
import { LoggerService } from '@core/logger/logger.service';
import { OfflineLogsBuffer } from '@core/utils/offline-logs-buffer.service';
import { JwtTokenV2Provider } from '@core/authentication/jwt/providers/jwt.token.v2.provider';
import { WebsocketGatewayJwtTokenProvider } from '@core/authentication/jwt/providers/websocket.gateway.jwt.token.provider';
import { PomJwtTokenProvider } from '@core/authentication/jwt/providers/pom.jwt.token.provider';
import { UserLoginDataService } from '@core/user-login-data/user-login-data.service';
import { StorageService } from '@core/services/storage.service';

@Injectable()
export class LoginEffect {

    constructor(
        private actions$: Actions,
        private authService: AuthService,
        private router: Router,
        private userContextService: UserContextHttpService,
        private permissionService: PermissionService,
        private userSettingsService: UserSettingsService,
        private muniUserSettingsHttpService: MuniUserSettingsHttpService,
        private pomJwtTokenProvider: PomJwtTokenProvider,
        private pendoService: PendoService,
        private websocketGatewayJwtTokenProvider: WebsocketGatewayJwtTokenProvider,
        private jwtTokenV2Provider: JwtTokenV2Provider,
        private store: Store<fromRoot.State>,
        private buysideProductIntegration: BuysideProductIntegrationService,
        private http: HttpClient,
        private jwtLifetimeProcessor: JwtLifetimeProcessor,
        private offlineLogsBuffer: OfflineLogsBuffer,
        private loggerService: LoggerService,
        private userLoginDataService: UserLoginDataService,
        private storageService: StorageService
    ) { }


    public login$ = createEffect(() => this.actions$.pipe(
        ofType(AuthApiActions.AuthApiActionTypes.Login),
        map((action: any) => action.payload),
        switchMap((payload) =>
            of(payload.userCredentials).pipe(
                // Set BuySide Xsrf Token
                switchMap((data: any) =>
                    this.authService.login(data).pipe(
                        catchError((error) => of({...error, success: false}))
                    ),
                ),
                // Set xsrf token
                switchMap((res: any) => {
                    if (res.success === false) {
                        return of(res);
                    } else {
                        return this.userContextService.getContext().pipe(
                            tap((res) =>
                            {
                                if (res.xsrf_token) {
                                    this.authService.setXsrfToken(res.xsrf_token);
                                } else {
                                    this.storageService.removeItem(xsrfTokenStorageKey);
                                }

                                return res;
                            }),
                            catchError((error) => of({...error, success: false}))
                        );
                    }
                }),
                // Set User Context and get BuySide Auth cookie
                switchMap((res: any) => {
                    if (res.success === false) {
                        return of(res);
                    } else {
                        return combineLatest([
                                forkJoin([
                                    this.http.get<{countries: Country[]}>(API.countries)
                                ]),
                                of(res)
                            ])
                            .pipe(
                                // eslint-disable-next-line max-len
                                tap(([[countriesResponse], loginResult]:
                                         [[{ countries: Country[] }], any]) => {
                                    this.buysideProductIntegration.setContextToStorage(res);
                                    const context = this.userContextService.buildUserContext(res);
                                    const permissions = this.permissionService.buildPermissions(context);
                                    const settings = this.userSettingsService.buildSettings(context);

                                    this.store.dispatch(new PermissionsActions.Update({ permissions }));
                                    this.store.dispatch(new UserSettingsActions.Update({ settings }));
                                    this.store.dispatch(new CountriesActions.Update(countriesResponse.countries));

                                    const timestamp = Date.now();
                                    this.userLoginDataService.setLoginTimestamp(timestamp);
                                    this.store.dispatch(new UserLoginDataActions.Loaded({ loginTimestamp: timestamp }));

                                    this.pendoService.initialize(context);
                                }),
                            map(data => {
                                return { loginResult: data[1] };
                            }),
                            catchError((error) => of({...error, success: false}))
                        );
                    }
                }),
                catchError((error) => of({...error, success: false})),
                map((res: any) => {
                    return (res.success === false) ?
                        new AuthApiActions.LoginFailed(res) :
                        new AuthApiActions.LoginSuccess({isUserAssignedToCompanies: res.loginResult.context.Companies.length > 0});
                })
            )
        )
    ));

    public loginSuccess$ = createEffect(() => this.actions$.pipe(
        ofType(
            AuthApiActions.AuthApiActionTypes.LoginSuccess
        ),
        // Set POM, v2, WebSocketGateway JWT
        switchMap((res: any) => {
            if (!res.payload.isUserAssignedToCompanies) {
                return of(res);
            }

            return forkJoin([
                from(this.jwtLifetimeProcessor.initializeTokenAsync(<JwtTokenAcquisitionInfo>{
                    tokenType: JwtTokenType.Pom,
                    tokenStorageKey: pomJwtTokenStorageKey,
                    tokenProvider: this.pomJwtTokenProvider
                })),
                from(this.jwtLifetimeProcessor.initializeTokenAsync(<JwtTokenAcquisitionInfo>{
                    tokenType: JwtTokenType.WebsocketGateway,
                    tokenStorageKey: websocketGatewayTokenStorageKey,
                    tokenProvider: this.websocketGatewayJwtTokenProvider
                })),
                from(this.jwtLifetimeProcessor.initializeTokenAsync(<JwtTokenAcquisitionInfo>{
                    tokenType: JwtTokenType.JwtTokenV2,
                    tokenStorageKey: jwtTokenV2StorageKey,
                    tokenProvider: this.jwtTokenV2Provider
                }))
            ]);
        }),
        // Set muni user settings if user has muni permissions
        withLatestFrom(
            this.store.pipe(select(fromRoot.getIsMuniPermissions)),
            this.store.pipe(select(fromRoot.getMuniBffUrl)),
            this.store.pipe(select(fromRoot.getHasMmdDataPermission))
        ),
        switchMap(([res, isMuniUser, muniBffUrl, hasMmdDataPermission]) => {
            if (!res[0] || !isMuniUser) {
                return of(res);
            } else {
                return this.muniUserSettingsHttpService.getUserSettings(muniBffUrl)
                       .pipe(
                            tap((result) => {
                                this.store.dispatch(
                                    new MuniUserSettingsActions.LoadSuccess({ muniSettings: result, hasMmdDataPermission }));
                            })
                        );
            }
        }),
        withLatestFrom(
            this.store.pipe(select(fromRoot.getConfigurationState))
        ),
        tap(([_, configuration]) => {
            this.store.dispatch(new LoggerActions.AddLoggedInTimestampAction({ loggedInAt: new Date(), type: 'Logged In' }));
            this.store.dispatch(new AuthApiActions.CheckAppVersion());
            this.store.dispatch(new AuthApiActions.TokenFetchSuccess());

            this.router.navigate(['/login/confirmation']);

            const logs: any[] = this.offlineLogsBuffer.getAll<any>(true);

            if (logs.length) {
                this.loggerService.logMessages(configuration.clientLoggingApiUrl, logs).subscribe(
                    () => console.log('[Buyside Platform] Offline logs are sent'),
                    () => this.offlineLogsBuffer.clearBuffer()
                );
            }
        }),
        catchError((error) => of({...error, success: false}))
    ), { dispatch: false });
}
