import { HttpErrorResponse } from '@angular/common/http';
import { PasswordCrieteriaBackendValidationErrors } from 'src/app/modules/shared/models/password.criteria.model';
import { WarningType } from '../../modules/login/models/warning-types.model';

export enum changePasswordErrorCodes {
    UserNewPasswordSameAsOld = 'UserNewPasswordSameAsOld',
    UserChangePasswordFailed = 'UserChangePasswordFailed',
}

export enum verifyResetPasswordTokenErrorCodes {
    InvalidArgumentException = 'InvalidArgumentException',
    UserNotFound = 'UserNotFound',
    UserResetPasswordTokenNotValid = 'UserResetPasswordTokenNotValid',
}

export enum resetPasswordErrorCodes {
    InvalidArgumentException = 'InvalidArgumentException',
    UserNotFound = 'UserNotFound',
    UserPasswordResetFailed = 'UserPasswordResetFailed',
    UserNewPasswordSameAsOld = 'UserNewPasswordSameAsOld',
}

export class AuthHelperService {
    public static getLoginFailedWarning(error: HttpErrorResponse): WarningType {
        switch (error.status) {
            case 403:
                return !!error.error && !AuthHelperService.isIpAddressWhitelisted(error)
                    ? WarningType.IpNotWhitelisted
                    : WarningType.InvalidCredentials;
            case 405:
                return WarningType.AccountLocked;
            default:
                return WarningType.LoginFailed;
        }
    }

    public static getChangePasswordWarning(error: HttpErrorResponse): WarningType {
        if (error.status === 403) {
            return !!error.error && !AuthHelperService.isIpAddressWhitelisted(error)
                ? WarningType.IpNotWhitelisted
                : WarningType.ResetPasswordFailed;
        }

        switch (error.error.ErrorCode) {
            case changePasswordErrorCodes.UserNewPasswordSameAsOld:
                return WarningType.UserNewPasswordSameAsOld;
            case changePasswordErrorCodes.UserChangePasswordFailed:
                if (error.error && error.error.Details && error.error.Details.Errors && error.error.Details.Errors.length > 0) {
                    if (PasswordCrieteriaBackendValidationErrors.includes(error.error.Details.Errors[0])) {
                        return WarningType.PasswordCrieteriaNotFullfiled;
                    }
                    if (error.error.Details.Errors[0] in WarningType) {
                        return WarningType[error.error.Details.Errors[0]];
                    }
                    return WarningType.ChangePasswordFailed;
                }
                return WarningType.ChangePasswordFailed;
            default:
                return WarningType.ChangePasswordFailed;
        }
    }

    public static getVerifyResetPasswordTokenWarning(error: HttpErrorResponse): WarningType {
        if (error.status === 403) {
            return !!error.error && !AuthHelperService.isIpAddressWhitelisted(error)
                ? WarningType.IpNotWhitelisted
                : WarningType.ResetPasswordFailed;
        }

        switch (error.error.ErrorCode) {
            case verifyResetPasswordTokenErrorCodes.InvalidArgumentException:
            case verifyResetPasswordTokenErrorCodes.UserNotFound:
                return WarningType.ResetPasswordFailed;
            case verifyResetPasswordTokenErrorCodes.UserResetPasswordTokenNotValid:
                return WarningType.ResetPasswordLinkExpired;
            default:
                return WarningType.ResetPasswordFailed;
        }
    }

    public static getResetPasswordWarning(error: HttpErrorResponse): WarningType {
        if (error.status === 403) {
            return !!error.error && !AuthHelperService.isIpAddressWhitelisted(error)
                ? WarningType.IpNotWhitelisted
                : WarningType.ResetPasswordFailed;
        }

        switch (error.error.ErrorCode) {
            case resetPasswordErrorCodes.UserNewPasswordSameAsOld:
                return WarningType.UserNewPasswordSameAsOld;
            case resetPasswordErrorCodes.UserPasswordResetFailed:
                if (error.error && error.error.Details && error.error.Details.Errors && error.error.Details.Errors.length > 0) {
                    if (PasswordCrieteriaBackendValidationErrors.includes(error.error.Details.Errors[0])) {
                        return WarningType.PasswordCrieteriaNotFullfiled;
                    }
                    if (error.error.Details.Errors[0] in WarningType) {
                        return WarningType[error.error.Details.Errors[0]];
                    }
                    return WarningType.ResetPasswordFailed;
                }
                return WarningType.ResetPasswordFailed;
            case resetPasswordErrorCodes.InvalidArgumentException:
            case resetPasswordErrorCodes.UserNotFound:
                return WarningType.ResetPasswordFailed;
            case resetPasswordErrorCodes.UserPasswordResetFailed:
                return WarningType.ResetPasswordLinkExpired;
            case resetPasswordErrorCodes.UserNewPasswordSameAsOld:
                return WarningType.PasswordIsUsedPreviously;
            default:
                return WarningType.ResetPasswordFailed;
        }
    }

    private static isIpAddressWhitelisted(error: HttpErrorResponse): boolean {
        return error.error.Reason !== 'IpAddressIsRestricted';
    }

    public static isResetPasswordValidationError(error: HttpErrorResponse): boolean {
        return error.error.ErrorCode === resetPasswordErrorCodes.UserNewPasswordSameAsOld;
    }
}
