import { Permissions } from '@core/permissions/permissions.model';

export class PermissionsHelperService {

    public static canViewFixedIncomeAssets(permissions: Permissions): boolean {
        return permissions.canViewFixedIncomeDealMonitor ||
               permissions.canViewDealQuery ||
               permissions.canViewActivityStream ||
               permissions.canViewCompliance;
    }

    public static hasEquityPermissions(permissions: Permissions): boolean {
        return permissions.canViewEquityDealMonitor ||
               permissions.canViewEquityDealQuery ||
               permissions.canViewEquityPublicDeals;
    }

    public static hasMuniPermissions(permissions: Permissions): boolean {
        return permissions.canViewMunicipalDealMonitor
            || permissions.canViewMunicipalStreetCalendar;
    }
}
