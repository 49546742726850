/* eslint-disable max-len */
import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import * as fromRoot from '../../reducers';
import { combineLatest } from 'rxjs';
import { JwtTokenType } from '@core/constants/constants';
import { JwtLifetimeProcessor } from '@core/authentication/jwt/jwt.lifetime.processor';
import { LoggerActions } from '@core/@state/actions';
import { ClientLogLevel } from '@core/logger/log.model';
import { BspWebsocketGatewayService, WebsocketGatewayAuthType } from '@core/websockets/bsp-websocket-gateway.service';

export interface Connection {
    connection: any;
    startDateTime: Date;
}

@Injectable({
    providedIn: 'root'
})
export class WebsocketMonitorService {

    private connections: Connection[] = [];
    private HEALTH_CHECK_INTERVAL_MS = 60000;
    private monitoringInterval;
    private token: string;

    constructor(
        private store: Store<fromRoot.State>,
        private jwtLifetimeService: JwtLifetimeProcessor,
        private bspWebsocketGatewayService: BspWebsocketGatewayService
    ) {}

    public init(): void {
        combineLatest([
            this.store.pipe(select(fromRoot.getIsLoggedIn)),
            this.store.pipe(select(fromRoot.getIsUserProfileConfirmed))
        ]).subscribe(([isLoggedIn, isConfirmed]) => {
            if (isLoggedIn && isConfirmed) {
                this.startMonitoring();

                return;
            }

            this.stopMonitoring();
        });

        this.jwtLifetimeService.subscribe(JwtTokenType.WebsocketGateway, (token) => {
            this.token = token;
        });

        this.bspWebsocketGatewayService.connection$.subscribe(connection => {
            this.add(connection);
        });
    }

    public add(connection: WebSocket): void {
        this.connections.push({
            connection,
            startDateTime: new Date()
        });
    }

    private startMonitoring(): void {
        if (this.monitoringInterval) {
            return;
        }

        this.monitoringInterval = setInterval(() => {
            if (!this.connections.length) {
                return;
            }

            const readyState = this.connections[this.connections.length - 1].connection.connection.readyState;

            if (readyState !== WebSocket.OPEN) {
                this.store.dispatch(new LoggerActions.LogMessages(
                    {
                        logs: [{
                            entryDate: new Date(),
                            message: `[WEBSOCKET] [MONITOR] Could not establish connection for 60 seconds. Current ReadyState: ${readyState}. Retrying...`,
                            level: ClientLogLevel.Info,
                            extraInfo: null
                        }],
                        addExtraInfo: true
                    })
                );

                this.bspWebsocketGatewayService.connect(WebsocketGatewayAuthType.Alerts, this.token);
            }
        }, this.HEALTH_CHECK_INTERVAL_MS);
    }

    private stopMonitoring(): void {
        this.connections = [];
        clearInterval(this.monitoringInterval);
    }

}
