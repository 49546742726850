import { Injectable } from '@angular/core';

import { Store } from '@ngrx/store';
import { of, EMPTY, asyncScheduler } from 'rxjs';
import { pluck, switchMap, catchError, withLatestFrom, filter, throttleTime, delay } from 'rxjs/operators';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { LoggerActions } from '../actions';
import { LoggerService } from '../../logger/logger.service';
import { ClientExtendedExtraInfo, ClientLogEntry, ClientLogExtraInfo, HttpResponseLog } from '../../logger/log.model';
import * as fromLogs from '../../@state/reducers/logs.reducer';
import { LoggerHelperService } from '@core/services/logger-helper.service';
import * as fromRoot from '../../../reducers';
import { BspConfiguration } from '../../configuration/bsp-configuration';
import { environment } from '@environments/environment';

@Injectable()
export class LoggerEffect {

    private static THROTTLE_TIME_MS = environment.e2e ? 0 : 3000;
    private static MAX_DELAY_TIME_MS = environment.e2e ? 0 : 5000;

    constructor(
        private store: Store<fromRoot.State>,
        private actions$: Actions,
        private loggerService: LoggerService,
        private loggerHelperService: LoggerHelperService
    ) {}


    public log$ = createEffect(() => this.actions$.pipe(
        ofType(LoggerActions.LogActionTypes.LogMessages),
        pluck('payload'),
        withLatestFrom(this.store.select(fromRoot.getConfigurationState), this.store.select(fromLogs.getLogsState), this.store.select(fromRoot.getIsLoggedIn)),
        filter(([_, logs, logState, isLoggedIn]:
                    [{ logs: ClientLogEntry<ClientLogExtraInfo>[], addExtraInfo }, any, fromLogs.State, boolean ]) => isLoggedIn),
        throttleTime(LoggerEffect.THROTTLE_TIME_MS, asyncScheduler, { leading: true, trailing: true }),
        delay(Math.trunc(Math.random() * LoggerEffect.MAX_DELAY_TIME_MS)),
        switchMap(([{ logs, addExtraInfo }, configuration, logState, authState]:
                       [{ logs: ClientLogEntry<ClientLogExtraInfo>[], addExtraInfo }, BspConfiguration, fromLogs.State, boolean ]) => {
            const extendedLogs: ClientLogEntry<ClientExtendedExtraInfo>[] = logs.map(log => ({
                    ...log,
                    extraInfo: addExtraInfo ?
                        this.loggerHelperService.aggregateExtraInfo(log.extraInfo, logState) :
                        <ClientExtendedExtraInfo>log.extraInfo
                }));

            return this.loggerService.logMessages(configuration.clientLoggingApiUrl, extendedLogs).pipe(
                switchMap(() => of(EMPTY)),
                catchError((error) => of({...error}))
            );
        })
    ), { dispatch: false });


    public logHttpResponse$ = createEffect(() => this.actions$.pipe(
        ofType(LoggerActions.LogActionTypes.LogHttpResponse),
        pluck('payload'),
        withLatestFrom(this.store.select(fromRoot.getIsLoggedIn)),
        filter(([httpResponseLog, isLoggedIn]: [HttpResponseLog, boolean]) => isLoggedIn),
        throttleTime(LoggerEffect.THROTTLE_TIME_MS, asyncScheduler, { leading: true, trailing: true }),
        delay(Math.trunc(Math.random() * LoggerEffect.MAX_DELAY_TIME_MS)),
        switchMap(([httpResponseLog, isLoggedIn]: [HttpResponseLog, boolean]) =>
            this.loggerService.logHttpResponse(httpResponseLog).pipe(
                switchMap(() => of(EMPTY)),
                catchError((error) => of({...error}))
        ))
    ), { dispatch: false });
}
