import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable} from 'rxjs';
import { UserContextProfileCompany, UserContextProfileContainer, UserContextProfileModel } from '@core/usercontext/user-context-http.model';
import { UserPhonesUpdateModel, UserProfileUpdateModel } from '../models/user-profile.model';
import { API } from '@core/constants/api';

@Injectable({
    providedIn: 'root'
})
export class UserProfileService {

    constructor(
        private http: HttpClient
    ) { }

    public updateProfile(profile: UserProfileUpdateModel): Observable<UserContextProfileModel<UserContextProfileContainer, UserContextProfileCompany>> {
        return this.http
            .put<UserContextProfileModel<UserContextProfileContainer, UserContextProfileCompany>>(API.userProfile.profile, profile);
    }

    public updateEmails(email: { Email: string }): Observable<UserContextProfileModel<UserContextProfileContainer, UserContextProfileCompany>> {
        return this.http
            .put<UserContextProfileModel<UserContextProfileContainer, UserContextProfileCompany>>(API.userProfile.emails, email);
    }

    public updatePhoneNumbers(phonesList: UserPhonesUpdateModel): Observable<UserContextProfileModel<UserContextProfileContainer, UserContextProfileCompany>> {
        return this.http
            .put<UserContextProfileModel<UserContextProfileContainer, UserContextProfileCompany>>(API.userProfile.phoneNumbers, phonesList);
    }

}
