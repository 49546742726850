export enum HomePage {
    municipal = 'municipal',
    fixedincome = 'fixedincome',
    equity = 'equity',
    equitydealquery = 'equitydealquery',
    equitypublicdeals = 'equitypublicdeals',
    query = 'query',
    activitystream = 'activitystream',
    compliance = 'compliance',
    default = 'default'
}

export const HOME_PAGES_DICTIONARY = {
    Default: 'Default Page',
    FixedIncome: 'Fixed Income',
    Query: 'Deal Query',
    ActivityStream: 'Activity Stream',
    Municipal: 'Municipal',
    Equity: 'Equity',
    EquityDealQuery: 'Equity Deal Query',
    EquityPublicDeals: 'Equity Public Deals'
};
