import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-modal',
    styleUrls: ['./modal.component.scss'],
    templateUrl: './modal.component.html'
})
export class ModalComponent {

    @Input() public header: string;
    @Input() public title: string;
    @Input() public text: string;

}
