import { Injectable } from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import { UserProfileService } from '../../../modules/user-profile/services/user-profile.service';
import { UserProfileActions, UserSettingsActions } from '../actions';
import { of } from 'rxjs';
import { pluck, switchMap, catchError, map } from 'rxjs/operators';
import {
    UserPhonesUpdateModel,
    UserProfileUpdateModel
} from '../../../modules/user-profile/models/user-profile.model';
import { UserSettingsService } from '@core/user-settings/user-settings.service';
import { UserProfile } from '@core/user-settings/user-settings.model';
import { UserProfileHelperService } from '../../../modules/user-profile/services/user-profile-helper.service';
import { PROFILE_UPDATE_WARNING } from '../../../modules/user-profile/models/user-profile-warnings.model';

@Injectable()
export class UserProfileEffect {
    constructor(
        private actions$: Actions,
        private userProfileService: UserProfileService,
        private userSettingsService: UserSettingsService
    ) {}


    public updateUserProfile$ = createEffect(() => this.actions$.pipe(
        ofType(UserProfileActions.UserProfileActionTypes.UpdateUserProfile),
        pluck('payload'),
        switchMap((profile: UserProfileUpdateModel) => {
            return this.userProfileService.updateProfile(profile).pipe(
                map(res => this.userSettingsService.getProfile(res)),
                switchMap((mappedProfile: UserProfile) => of(new UserProfileActions.UpdateProfileSuccess(mappedProfile))),
                catchError(() => of(new UserProfileActions.UpdateProfileFailed( { errorType: PROFILE_UPDATE_WARNING } )))
            );
        })
    ));


    public updateUserEmails$ = createEffect(() => this.actions$.pipe(
        ofType(UserProfileActions.UserProfileActionTypes.UpdateUserEmails),
        pluck('payload'),
        switchMap((email: { Email: string }) => {
            return this.userProfileService.updateEmails(email).pipe(
                map(res => this.userSettingsService.getProfile(res)),
                switchMap((mappedProfile: UserProfile) => of(new UserProfileActions.UpdateProfileSuccess(mappedProfile))),
                catchError((err) => {
                    const errorType = UserProfileHelperService.getEmailUpdateWarning(err);

                    return  of(new UserProfileActions.UpdateProfileFailed({ errorType }));
                })
            );
        })
    ));


    public updateUserPhoneNumbers$ = createEffect(() => this.actions$.pipe(
        ofType(UserProfileActions.UserProfileActionTypes.UpdateUserPhoneNumbers),
        pluck('payload'),
        switchMap((phonesList: UserPhonesUpdateModel) => {
            return this.userProfileService.updatePhoneNumbers(phonesList).pipe(
                map(res => this.userSettingsService.getProfile(res)),
                switchMap((mappedProfile: UserProfile) => of(new UserProfileActions.UpdateProfileSuccess(mappedProfile))),
                catchError((err) => {
                    const errorType = UserProfileHelperService.getPhoneNumberUpdateWarning(err);

                    return of(new UserProfileActions.UpdateProfileFailed({ errorType }));
                })
            );
        })
    ));


    public updateProfileSuccess$ = createEffect(() => this.actions$.pipe(
        ofType(
            UserProfileActions.UserProfileActionTypes.UpdateProfileSuccess
            ),
        pluck('payload'),
        switchMap((profile: UserProfile) => of(new UserSettingsActions.UpdateProfile(profile)))
    ));
}
