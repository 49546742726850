import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';

import { ClientExtendedExtraInfo, ClientLogEntry, HttpResponseLog } from './log.model';
import { noLogHeader, noErrorHandlingForHeader } from '../constants/http-headers';
import { API } from '@core/constants/api';

@Injectable({
    providedIn: 'root'
})
export class LoggerService {
    constructor(private httpClient: HttpClient) {
    }

    private get headers(): HttpHeaders {
        return new HttpHeaders()
            .set(noLogHeader, noLogHeader)
            .set(noErrorHandlingForHeader, '*');
    }

    public logHttpResponse(httpResponse: HttpResponseLog): Observable<{}> {
        return this.httpClient.post(API.logs.httpResponse, httpResponse, { headers: this.headers });
    }

    public logMessages(baseUrl: string, logEntries: ClientLogEntry<ClientExtendedExtraInfo>[]): Observable<{}> {
        return this.httpClient.post(`${baseUrl}/log`, logEntries, { headers: this.headers });
    }
}
