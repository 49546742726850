import { Injectable } from '@angular/core';
import { StorageService } from '@core/services/storage.service';
import { isNullOrUndefined } from '@core/utils/utils';

const LoginTimestampKey = 'login_timestamp';

@Injectable({
    providedIn: 'root'
})
export class UserLoginDataService {

    constructor(private storageService: StorageService) {
    }

    public getLoginTimestamp(): number | undefined {
        const loginTimestampStr = this.storageService.getItem(LoginTimestampKey);
        if (isNullOrUndefined(loginTimestampStr)) {
            return undefined;
        }

        const loginTimestamp = Number.parseInt(loginTimestampStr, 10);
        if (!Number.isSafeInteger(loginTimestamp)) {
            return undefined;
        }

        return loginTimestamp;
    }

    public setLoginTimestamp(timestamp: number): void {
        if (isNullOrUndefined(timestamp)) {
            return;
        }

        this.storageService.setItem(LoginTimestampKey, timestamp.toString());
    }
}
