<header class="c-header">
    <div class="c-header-left">
        <div class="c-header-item c-bd-0">
            <a [routerLink]="homePageRoute$ | async"
               class="c-header-item-link">
                <svg xmlns="http://www.w3.org/2000/svg" width="42" height="25" viewBox="0 0 42 25">
                    <g id="Group_5345" data-name="Group 5345" transform="translate(-210.83 -85.326)">
                        <g id="Group_5343" data-name="Group 5343" transform="translate(210.83 85.326)">
                            <path id="Wordmark_5_" d="M59.573,80.8a4.443,4.443,0,0,1-1.277,3.2A6.6,6.6,0,0,1,53.7,85.648a6.866,6.866,0,0,1-4.848-1.577,4.856,4.856,0,0,1-1.6-3.572h2.858a2.7,2.7,0,0,0,.8,1.884,3.617,3.617,0,0,0,2.643.822,3.6,3.6,0,0,0,2.6-.822,1.891,1.891,0,0,0,.011-2.77c-.955-.721-2.318-.672-3.452-.779a6.781,6.781,0,0,1-3.383-1.016,4.034,4.034,0,0,1-1.656-2.87,4.332,4.332,0,0,1,1.314-3.584,6.98,6.98,0,0,1,8.79-.13A4.665,4.665,0,0,1,59.335,74.5H56.477a2.484,2.484,0,0,0-.671-1.516,3.915,3.915,0,0,0-4.828.022,1.741,1.741,0,0,0,.319,2.74,4.969,4.969,0,0,0,1.742.356c.641.071,1.283.124,1.922.207a5.806,5.806,0,0,1,3.325,1.367A4.24,4.24,0,0,1,59.573,80.8Zm12.087,4.59-1.082-1.212A6.455,6.455,0,0,1,63.8,85.265,4.559,4.559,0,0,1,61.392,79.6,5.7,5.7,0,0,1,63.866,76.9a4.7,4.7,0,0,1-1.615-3.862,3.744,3.744,0,0,1,2.373-3.029,5.047,5.047,0,0,1,4.322.349,3.679,3.679,0,0,1,1.7,3.49c-.132,1.756-1.46,2.908-2.888,3.745l2.663,2.9a4.834,4.834,0,0,0,.39-1.884c.051-.627.044-1.255.044-1.884h3.962v2.143H73.045a6.785,6.785,0,0,1-.931,3.442l2.836,3.074Zm-6.214-6.8a3.312,3.312,0,0,0-1.739,1.777,2.377,2.377,0,0,0,.556,2.264,3.517,3.517,0,0,0,4.691-.166Zm2.685-5a1.57,1.57,0,0,0-.9-1.5,1.992,1.992,0,0,0-1.959.2,1.593,1.593,0,0,0-.483,1.761,4.79,4.79,0,0,0,1.371,1.832C67.1,75.361,68.131,74.81,68.131,73.592Zm20.292,1.342a4.8,4.8,0,0,1-1.53,3.713,5.9,5.9,0,0,1-3.882,1.223H79.764v5.5H76.993V70.02h6.018a5.961,5.961,0,0,1,3.882,1.215A4.747,4.747,0,0,1,88.423,74.934Zm-2.814.022a2.519,2.519,0,0,0-.768-1.912,2.891,2.891,0,0,0-1.938-.578H79.764v4.958H82.9a2.916,2.916,0,0,0,1.938-.569A2.483,2.483,0,0,0,85.609,74.956Z" transform="translate(-47.254 -62.399)" fill="#d6002a"/>
                            <rect id="Bar_3_" width="42" height="2.771" transform="translate(0)" fill="#1a1a1a"/>
                        </g>
                    </g>
                </svg>
            </a>
        </div>

        <div class="c-header-item c-bd-right-0">
          <app-product-switcher></app-product-switcher>
        </div>
    </div>

    <div class="c-header-right">
        <div *ngIf="(currentAssetClass$ | async) === assetClassModel.Municipal" class="c-header-item">
                <app-northstar-alerts [token]="pomToken$" [appCode]="notificationsAppCode$ | async">
                </app-northstar-alerts>
        </div>

        <div class="c-header-item">
          <app-user-menu></app-user-menu>
        </div>

    </div>
</header>
<app-header-tabs></app-header-tabs>
