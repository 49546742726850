import { AuthHelperService } from '@core/authentication/auth.helper.service';
import { WarningType } from '../../../modules/login/models/warning-types.model';
import { AuthApiActions } from '../actions';

export interface State {
    isLoggedIn: boolean;
    isSessionTimedOut: boolean;
    isUserProfileConfirmed: boolean;
    isLoading: boolean;
    warningType: WarningType;
    correlationId: string;
    profileConfirmation: {
        countries: string[];
        isLoading: boolean;
    };
    resetPassword: {
        warningType: WarningType;
        isLoading: boolean;
        initialUserName: string;
    };
    changePassword: {
        warningType: WarningType;
        isLoading: boolean;
        isSuccess: boolean;
    };
    termsOfUse: {
        id: string,
        text: string,
        isLoading: boolean
    };
}

export const initialState: State = {
    isLoggedIn: false,
    isSessionTimedOut: false,
    isUserProfileConfirmed: false,
    isLoading: false,
    warningType: null,
    correlationId: null,
    profileConfirmation: {
        countries: [],
        isLoading: false
    },
    resetPassword: {
        warningType: null,
        isLoading: false,
        initialUserName: null
    },
    changePassword: {
        warningType: null,
        isLoading: false,
        isSuccess: false
    },
    termsOfUse: {
        id: null,
        text: null,
        isLoading: false
    }
};

export function authReducer(
    state: State = initialState,
    action: AuthApiActions.AuthApiActionsUnion
): State {
    switch (action.type) {
        case AuthApiActions.AuthApiActionTypes.Login:
            return {
                ...state,
                isSessionTimedOut: false,
                isLoading: true,
                warningType: null,
                correlationId: action.payload.correlationId
            };

        case AuthApiActions.AuthApiActionTypes.Logout:
            return {
                ...state,
                ...initialState
            };

        case AuthApiActions.AuthApiActionTypes.LoginSuccess:
            return {
                ...state,
                isLoggedIn: true,
                isUserProfileConfirmed: false,
                warningType: null
            };

        case AuthApiActions.AuthApiActionTypes.TokenFetchSuccess:
            return {
                ...state,
                isLoading: false
            };

        case AuthApiActions.AuthApiActionTypes.TokenFetchFailed:
            return {
                ...state,
                isLoading: false
            };

        case AuthApiActions.AuthApiActionTypes.LoginFailed:
            return {
                ...state,
                isLoggedIn: false,
                isUserProfileConfirmed: false,
                isLoading: false,
                warningType: AuthHelperService.getLoginFailedWarning(action.payload)
            };

        case AuthApiActions.AuthApiActionTypes.ResetPasswordRequest:
            return {
                ...state,
                warningType: action.payload ? null : WarningType.ResetPasswordNoEmail,
                resetPassword: {
                    ...state.resetPassword,
                    initialUserName: action.payload
                }
            };

        case AuthApiActions.AuthApiActionTypes.ResetPasswordRequestSuccess:
            return {
                ...state,
                warningType: WarningType.ResetPassword
            };

        case AuthApiActions.AuthApiActionTypes.ResetPasswordRequestFailed:
            return {
                ...state,
                warningType: WarningType.ResetPasswordFailed,
            };

        case AuthApiActions.AuthApiActionTypes.ResetPasswordStart:
            return {
                ...state,
                warningType: null,
                isLoggedIn: false,
                isUserProfileConfirmed: false,
                resetPassword: {
                    ...state.resetPassword,
                    warningType: null
                }
            };

        case AuthApiActions.AuthApiActionTypes.VerifyResetPasswordToken:
            return {
                ...state,
                resetPassword: {
                    ...state.resetPassword,
                    isLoading: true
                }
            };

        case AuthApiActions.AuthApiActionTypes.VerifyResetPasswordTokenSuccess:
            return {
                ...state,
                resetPassword: {
                    ...state.resetPassword,
                    isLoading: false
                }
            };

        case AuthApiActions.AuthApiActionTypes.VerifyResetPasswordTokenFailed:
            return {
                ...state,
                resetPassword: {
                    ...state.resetPassword,
                    isLoading: false
                },
                warningType: AuthHelperService.getVerifyResetPasswordTokenWarning(action.payload),
            };

        case AuthApiActions.AuthApiActionTypes.ResetPassword:
            return {
                ...state,
                correlationId: action.payload.correlationId,
                resetPassword: {
                    ...state.resetPassword,
                    warningType: null,
                    isLoading: true
                },
            };

        case AuthApiActions.AuthApiActionTypes.ResetPasswordSuccess:
            return {
                ...state,
                resetPassword: {
                    ...state.resetPassword,
                    warningType: null,
                    isLoading: false
                },
                warningType: null
            };

        case AuthApiActions.AuthApiActionTypes.ResetPasswordFailed:
            return {
                ...state,
                resetPassword: {
                    ...state.resetPassword,
                    isLoading: false,
                    warningType: AuthHelperService.getResetPasswordWarning(action.payload),
                },
            };

        case AuthApiActions.AuthApiActionTypes.ResetPasswordInvalid:
            return {
                ...state,
                resetPassword: {
                    ...state.resetPassword,
                    warningType: AuthHelperService.getResetPasswordWarning(action.payload),
                    isLoading: false
                }
            };

        case AuthApiActions.AuthApiActionTypes.RestoreSession:
        return {
                ...state,
                isUserProfileConfirmed: action.payload.isUserProfileConfirmed,
                isLoggedIn: true,
                isLoading: false,
                warningType: null
            };

        case AuthApiActions.AuthApiActionTypes.TerminateSession:
            return {
                ...state,
                ...initialState
            };

        case AuthApiActions.AuthApiActionTypes.ConfirmProfile:
            return {
                ...state,
                profileConfirmation: {
                    ...state.profileConfirmation,
                    isLoading: true
                }
            };

        case AuthApiActions.AuthApiActionTypes.ConfirmProfileSuccess:
            return {
                ...state,
                isUserProfileConfirmed: true,
                profileConfirmation: {
                    ...state.profileConfirmation,
                    isLoading: false
                }
            };

        case AuthApiActions.AuthApiActionTypes.ConfirmProfileFailed:
            return {
                ...state,
                profileConfirmation: {
                    ...state.profileConfirmation,
                    isLoading: false
                }
            };

        case AuthApiActions.AuthApiActionTypes.ChangePassword:
            return {
                ...state,
                changePassword: {
                    ...state.changePassword,
                    isLoading: true
                }
            };

        case AuthApiActions.AuthApiActionTypes.ChangePasswordSuccess:
                return {
                    ...state,
                    changePassword: {
                        ...state.changePassword,
                        isLoading: false,
                        isSuccess: true
                    }
                };

        case AuthApiActions.AuthApiActionTypes.ChangePasswordFailed:
            return {
                ...state,
                changePassword: {
                    ...state.changePassword,
                    warningType: AuthHelperService.getChangePasswordWarning(action.payload),
                    isLoading: false
                }
            };

        case AuthApiActions.AuthApiActionTypes.ChangePasswordStateReset:
            return {
                ...state,
                changePassword: {
                    ...state.changePassword,
                    warningType: null,
                    isSuccess: false
                }
            };

        case AuthApiActions.AuthApiActionTypes.TermsOfUseLoad:
            return {
                ...state,
                termsOfUse: {
                    ...state.termsOfUse,
                    isLoading: true
                }
            };

        case AuthApiActions.AuthApiActionTypes.TermsOfUseLoadSuccess:
            return {
                ...state,
                termsOfUse: {
                    ...state.termsOfUse,
                    id: action.payload.Id,
                    text: action.payload.Text,
                    isLoading: false
                }
            };

        case AuthApiActions.AuthApiActionTypes.TermsOfUseLoadFailed:
            return {
                ...state,
                termsOfUse: {
                    ...state.termsOfUse,
                    isLoading: false
                }
            };

        default:
            return state;
    }
}
