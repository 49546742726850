import { Action } from '@ngrx/store';
import { ClientLogEntry, ClientLogExtraInfo, HttpResponseLog } from '../../logger/log.model';

export enum LogActionTypes {
    LogMessages                 = '[Log] [Shared] Log client messages',
    LogHttpResponse             = '[Log] Log http response',
    AddElementClicked           = '[Logs] Add Client Action',
    AddLoggedInTimestampAction  = '[Logs] Add Logged In Timestamp',
    AddHttpResponseStatus       = '[Logs] Add HTTP Response Status'
}

export class LogMessages implements Action {
    public readonly type = LogActionTypes.LogMessages;

    constructor(public payload: {
        logs: ClientLogEntry<ClientLogExtraInfo>[],
        addExtraInfo: boolean
    }) {}
}

export class LogHttpResponse implements Action {
    public readonly type = LogActionTypes.LogHttpResponse;

    constructor(public payload: HttpResponseLog) {}
}

export class AddElementClicked implements Action {
    public readonly type = LogActionTypes.AddElementClicked;

    constructor(public payload: { elementClicked: string }) {}
}

export class AddLoggedInTimestampAction implements Action {
    public readonly type = LogActionTypes.AddLoggedInTimestampAction;

    constructor(public payload: { loggedInAt: Date, type: 'Logged In' | 'Session Restored' }) {}
}

export class AddHttpResponseStatus implements Action {
    public readonly type = LogActionTypes.AddHttpResponseStatus;

    constructor(public payload: { path: string }) {}
}

export type LogActionsUnion =
    | LogMessages
    | LogHttpResponse
    | AddElementClicked
    | AddLoggedInTimestampAction
    | AddHttpResponseStatus;
