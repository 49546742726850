import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { select, Store } from '@ngrx/store';
import * as fromRoot from '../../reducers';
import { UserSettings } from '@core/user-settings/user-settings.model';
import { Observable } from 'rxjs';
import { API } from '@core/constants/api';

@Injectable({
    providedIn: 'root'
})
export class HttpUserSettingsService {

    private userSettings: UserSettings;

    constructor(
        private http: HttpClient,
        private store: Store<fromRoot.State>
    ) {
        this.store.pipe(select(fromRoot.getUserSettingsState))
            .subscribe(userSettings => this.userSettings = userSettings);
    }

    public syncUserSetting(): Observable<any> {
        const settingsStringified = JSON.stringify({
            value: JSON.stringify({
                hideEmptyFields: this.userSettings.hideEmptyFields,
                showChanges: this.userSettings.highlightChanges
            }),
            type: 'object'
        });

        const body = {
            SettingKey: 'app_fixedIncomeDealDetailsSettings',
            SettingValue: settingsStringified
        };

        return this.http.put(API.userSettings.sync, body);
    }

}
