import { Injectable } from '@angular/core';
import { StorageService } from '@core/services/storage.service';
import { Store, select } from '@ngrx/store';
import * as fromRoot from '../../reducers';
import { UserContextHttpModel } from '@core/usercontext/user-context-http.model';
import { UserSettingsService } from '@core/user-settings/user-settings.service';

@Injectable({
    providedIn: 'root'
})
export class BuysideProductIntegrationService {

    private localStoragePrefix: string;
    private readonly DEAL_MONITOR_SETTINGS_UI_KEY = 'fixedIncomeDealMonitor_UI';
    private readonly DEAL_MONITOR_SETTINGS_FILTERS_KEY = 'fixedIncomeDealMonitor_filters';
    private readonly DEAL_MONITOR_SETTINGS_STARRED_TRANCHES_KEY = 'fixedIncomeDealMonitor_starredTranches';
    private readonly DEAL_MONITOR_SETTINGS_HIDDEN_TRANCHES_KEY = 'fixedIncomeDealMonitor_hiddenTranches';
    private readonly DEAL_QUERY2_SETTINGS_KEY = 'dealQueryV2Settings';

    constructor(
        private store: Store<fromRoot.State>,
        private storage: StorageService,
        private userSettingsService: UserSettingsService
    ) {
        this.store.pipe(select(fromRoot.getConfigurationState))
            .subscribe(configuration => this.localStoragePrefix = configuration ? configuration.localStoragePrefix : null );
    }

    public setContextToStorage(contextResponse: UserContextHttpModel): void {
        this.setDmSettingsIfAny(contextResponse);
        this.setDQV2SettingsIfAny(contextResponse);
    }

    private setDmSettingsIfAny(userContext: UserContextHttpModel): void {
        const dmSettingsUIKey = this.localStoragePrefix + this.DEAL_MONITOR_SETTINGS_UI_KEY;
        const dmSettingsFiltersKey = this.localStoragePrefix + this.DEAL_MONITOR_SETTINGS_FILTERS_KEY;
        const dmSettingsStarredTranchesKey = this.localStoragePrefix + this.DEAL_MONITOR_SETTINGS_STARRED_TRANCHES_KEY;
        const dmSettingsHiddenTranchesKey = this.localStoragePrefix + this.DEAL_MONITOR_SETTINGS_HIDDEN_TRANCHES_KEY;

        const dmSettingsUI =
            this.userSettingsService.getDealMonitorSettings(dmSettingsUIKey, userContext.context.UserSettings);
        const dmSettingsFilters =
            this.userSettingsService.getDealMonitorSettings(dmSettingsFiltersKey, userContext.context.UserSettings);
        const dmSettingsStarredTranches =
            this.userSettingsService.getDealMonitorSettings(dmSettingsStarredTranchesKey, userContext.context.UserSettings);
        const dmSettingsHiddenTranches =
            this.userSettingsService.getDealMonitorSettings(dmSettingsHiddenTranchesKey, userContext.context.UserSettings);

        this.updateDmSettingsInStorage(dmSettingsUIKey, dmSettingsUI);
        this.updateDmSettingsInStorage(dmSettingsFiltersKey, dmSettingsFilters);
        this.updateDmSettingsInStorage(dmSettingsStarredTranchesKey, dmSettingsStarredTranches);
        this.updateDmSettingsInStorage(dmSettingsHiddenTranchesKey, dmSettingsHiddenTranches);
    }

    private updateDmSettingsInStorage(settingKey: string, settingsValue: string): void {
        !!settingsValue ? this.storage.setItem(settingKey, settingsValue) : this.storage.removeItem(settingKey);
    }

    private setDQV2SettingsIfAny(userContext: UserContextHttpModel): void {
        const dqV2SettingsKey = this.localStoragePrefix + this.DEAL_QUERY2_SETTINGS_KEY;
        const dqV2Settings = this.userSettingsService.getDealQueryV2Settings(userContext.context.UserSettings);
        if (!!dqV2Settings) {
            this.storage.setItem(dqV2SettingsKey, this.userSettingsService.getDealQueryV2Settings(userContext.context.UserSettings));
        } else {
            this.storage.removeItem(dqV2SettingsKey);
        }
    }

}
