import { BspConfiguration } from '@core/configuration/bsp-configuration';
import { ConfigurationApiActions } from '../../actions';

export type State = BspConfiguration;

export const initialState: State = null;

export function configurationReducer(
    state: State = initialState,
    action: ConfigurationApiActions.ConfigurationActionsUnion
): State {
    switch (action.type) {
        case ConfigurationApiActions.ConfigurationActionTypes.Update:
            return {
                ...action.payload.configuration,
            };

        default:
            return state;
    }
}
