import { ActionReducer, combineReducers, createFeatureSelector, createSelector } from '@ngrx/store';

import * as fromUserLoginData from './shared/user-login-data.reducer';
import * as fromPermissions from './shared/permissions.reducer';
import * as fromConfiguration from './shared/configuration.reducer';
import * as fromUserSettings from './shared/user-settings.reducer';
import * as fromMuniUserSettings from './shared/muni-user-settings.reducer';
import * as fromEquityUserSettings from './shared/equity-user-settings.reducer';
import * as fromRoutingHistory from './shared/routing-history.reducer';
import * as fromWebsocketConnection from './shared/websocket-connection.reducer';
import * as fromCountries from './shared/countries.reducer';

import { PermissionsHelperService } from '../../permissions/permissions-helper.service';

import { UserSettings } from '../../user-settings/user-settings.model';
import { Permissions } from '../../permissions/permissions.model';
import { BspConfiguration } from '../../configuration/bsp-configuration';
import { MuniUserSettings } from '../../user-settings/muni-user-settings.model';

export interface State {
    permissions: fromPermissions.State;
    configuration: fromConfiguration.State;
    userSettings: fromUserSettings.State;
    muniUserSettings: fromMuniUserSettings.State;
    equityUserSettings: fromEquityUserSettings.State;
    routingHistory: fromRoutingHistory.State;
    websocketConnection: fromWebsocketConnection.State;
    countries: fromCountries.State;
    userLoginData: fromUserLoginData.UserLoginData;
}

const initialState: State = {
    configuration: fromConfiguration.initialState,
    permissions: fromPermissions.initialState,
    userSettings: fromUserSettings.initialState,
    muniUserSettings: fromMuniUserSettings.initialState,
    equityUserSettings: fromEquityUserSettings.initialState,
    routingHistory: fromRoutingHistory.initialState,
    websocketConnection: fromWebsocketConnection.initialState,
    countries: fromCountries.initialState,
    userLoginData: fromUserLoginData.initialState
};

export const sharedReducer: ActionReducer<State> = combineReducers({
    configuration: fromConfiguration.configurationReducer,
    userSettings: fromUserSettings.userSettingsReducer,
    muniUserSettings: fromMuniUserSettings.muniUserSettingsReducer,
    equityUserSettings: fromEquityUserSettings.equityUserSettingsReducer,
    permissions: fromPermissions.permissionsReducer,
    routingHistory: fromRoutingHistory.routingHistoryReducer,
    websocketConnection: fromWebsocketConnection.websocketConnectionReducer,
    countries: fromCountries.countriesReducer,
    userLoginData: fromUserLoginData.userLoginDataReducer
}, initialState);

export const getSettingsState = createFeatureSelector<UserSettings>('userSettings');
export const getMuniSettingsState = createFeatureSelector<MuniUserSettings>('muniUserSettings');
export const getConfigurationState = createFeatureSelector<BspConfiguration>('configuration');
export const getCountriesState = createFeatureSelector<fromCountries.State>('countries');
export const getPermissionsState = createFeatureSelector<Permissions>('permissions');
export const getRoutingHistoryState = createFeatureSelector<fromRoutingHistory.State>('routingHistory');
export const getUserLoginDataState = createFeatureSelector<fromUserLoginData.UserLoginData>('userLoginData');

export const getCanViewFixedIncomeAssets = createSelector(getPermissionsState, permissions => {
    return PermissionsHelperService.canViewFixedIncomeAssets(permissions);
});

export const getCanViewEquityAssets = createSelector(getPermissionsState, permissions => {
    return permissions ? permissions.canViewEquityDealMonitor : null;
});

export const getUserName = createSelector(getSettingsState, userSettings => {
    if (!userSettings) {
        return null;
    }

    return `${userSettings.profile.firstName} ${userSettings.profile.lastName}`;
});

export const getUserEmail = createSelector(getSettingsState, userSettings => {
    if (!userSettings) {
        return null;
    }

    return userSettings.profile.email;
});

export const getContainerId = createSelector(getSettingsState, userSettings => {
    if (!userSettings) {
        return null;
    }

    return userSettings.containers.length ? userSettings.containers[0].id : null;
});

export const getUserProfile = createSelector(getSettingsState, userSettings => {
    if (!userSettings) {
        return null;
    }

    return userSettings.profile;
});

export const getIsAccessingFromUS = createSelector(getSettingsState, userSettings => {
    if (!userSettings) {
        return null;
    }

    return userSettings.isAccessingFromUS;
});
