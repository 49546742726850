import { EmailPurpose, EquitySupport } from './../../admin-client/models/support/equity-support.model';
import { UserSettings } from '@core/user-settings/user-settings.model';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

import { select, Store } from '@ngrx/store';
import * as fromRoot from '../../../reducers';

import { BspConfiguration } from '@core/configuration/bsp-configuration';
import { API } from '@core/constants/api';
import { EquitySupportContact } from '../../admin-client/models/support/equity-support-contact.model';


@Injectable({
  providedIn: 'root'
})
export class SupportService {
    constructor(
        private http: HttpClient,
        private store: Store<fromRoot.State>
    ) {}

    public sendEquityMessage(message: string, userSetting: UserSettings): Observable<any> {
        return this.store.pipe(
            select(fromRoot.getConfigurationState),
            map((config: BspConfiguration) => config.equityBffUrl),
            switchMap(equityBffUrl => this.http.post(`${equityBffUrl}/${API.support.equity}`,
            this.constructEquitySupportPayload(message,userSetting))),
        );
    }

    private constructEquitySupportPayload(message: string, userSetting: UserSettings)
    {
        return {
            emailPurpose:EmailPurpose.SupportRequest,
            emailModel: {
                firstName: userSetting.profile.firstName,
                lastName: userSetting.profile.lastName,
                primaryEmail: userSetting.profile.email,
                phone: userSetting.profile.phoneNumber,
                message,
            } as EquitySupportContact,
        } as EquitySupport;
    }
    public sendBuysideMessage(message: string): Observable<any> {
        return this.http.post(API.support.buyside, {
            Message: message
        });
    }

    public sendMuniMessage(message: string): Observable<any> {
        return this.store.pipe(
            select(fromRoot.getConfigurationState),
            map((config: BspConfiguration) => config.muniBffUrl),
            switchMap(muniBffUrl => this.http.post(`${muniBffUrl}/${API.support.muni}`, { message }))
        );
    }
}
