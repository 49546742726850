import { Action } from '@ngrx/store';

export enum ConfigActions {
    SetToken = '[northstar-alerts] Set Token',
    SetAppCode = '[northstar-alerts] Set App Code'
}

/* tslint:disable:max-classes-per-file */

export class SetAlertsTokenAction implements Action {
    public readonly type = ConfigActions.SetToken;

    constructor(public payload: string) {}
}

export class SetAlertsAppCodeAction implements Action {
  public readonly type = ConfigActions.SetAppCode;

  constructor(public payload: string) {}
}

export type ConfigActionsUnion = SetAlertsTokenAction | SetAlertsAppCodeAction;
