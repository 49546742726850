import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { of } from 'rxjs';
import { concatMap, map, catchError, withLatestFrom } from 'rxjs/operators';
import { getConfigStoreState, State } from '../reducer';
import { AlertsService } from '../../services/alerts.service';
import {
    TransportsActions,
    TransportsLoadFailAction,
    TransportsLoadSuccessAction,
} from './actions';

@Injectable()
export class TransportsEffects {
    public loadTransports$ = createEffect(() =>
        this.actions$.pipe(
            ofType(TransportsActions.Load),
            withLatestFrom(this.store.pipe(select(getConfigStoreState))),
            concatMap(([, config]) => {
                return this.alertsService.getTransports(config).pipe(
                    map((payload) => new TransportsLoadSuccessAction(payload)),
                    catchError((error) => of(new TransportsLoadFailAction(error)))
                );
            })
        )
    );

    constructor(
        private actions$: Actions,
        private alertsService: AlertsService,
        private store: Store<State>
    ) {
    }
}
