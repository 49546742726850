import { Inject, Injectable } from '@angular/core';
import { WINDOW } from './window.service';

@Injectable()
export class ConfigurationService {

  constructor(@Inject(WINDOW) private window: Window) { }

  get hostUrl() {
    return (this.window as any)?.hostUrl;
  }
}
