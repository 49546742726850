import { Action } from '@ngrx/store';
import { Message } from '../../types';

export enum MessagesActions {
    Load = '[northstar-alerts] Load Messages',
    LoadSuccess = '[northstar-alerts] Load Messages Success',
    LoadFail = '[northstar-alerts] Load Messages Error',
    Read = '[northstar-alerts] Read Messages',
    ReadSuccess = '[northstar-alerts] Read Messages Success',
    ReadFail = '[northstar-alerts] Read Messages Error',
    ReadAll = '[northstar-alerts] Read All Messages',
    ReadAllSuccess = '[northstar-alerts] Read All Messages Success',
    ReadAllFail = '[northstar-alerts] Read All Messages Error',
    Add = '[northstar-alerts] Add New Message'
}

/* tslint:disable:max-classes-per-file */

export class MessagesLoadAction implements Action {
    public readonly type = MessagesActions.Load;

    constructor(public payload: { size?: number; from?: number }) {
    }
}

export class MessagesLoadSuccessAction implements Action {
    public readonly type = MessagesActions.LoadSuccess;

    constructor(public payload: { messages: Message[]; from: number }) {
    }
}

export class MessagesLoadFailAction implements Action {
    public readonly type = MessagesActions.LoadFail;

    constructor(public payload: string) {
    }
}

export class MessagesReadAction implements Action {
    public readonly type = MessagesActions.Read;

    constructor(public payload: { deliveryIds: number[]; read: boolean }) {
    }
}

export class MessagesReadSuccessAction implements Action {
    public readonly type = MessagesActions.ReadSuccess;
}

export class MessagesReadFailAction implements Action {
    public readonly type = MessagesActions.ReadFail;

    constructor(
        public payload: { deliveryIds: number[]; read: boolean; error: string }
    ) {
    }
}

export class MessagesReadAllAction implements Action {
    public readonly type = MessagesActions.ReadAll;
}

export class MessagesReadAllSuccessAction implements Action {
    public readonly type = MessagesActions.ReadAllSuccess;
}

export class MessagesReadAllFailAction implements Action {
    public readonly type = MessagesActions.ReadAllFail;

    constructor(public payload: string) {
    }
}

export class MessagesAddAction implements Action {
    public readonly type = MessagesActions.Add;

    constructor(public payload: Message) {
    }
}

export type MessagesActionsUnion =
    | MessagesLoadAction
    | MessagesLoadSuccessAction
    | MessagesLoadFailAction
    | MessagesReadAction
    | MessagesReadSuccessAction
    | MessagesReadFailAction
    | MessagesReadAllAction
    | MessagesReadAllSuccessAction
    | MessagesReadAllFailAction
    | MessagesAddAction;
