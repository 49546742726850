import { UserLoginDataActionsUnion, UserLoginDataActionTypes } from '@core/@state/actions/user-login-data.actions';

export interface UserLoginData {
    loginTimestamp: number;
}

export type State = UserLoginData;

export const initialState: State = {
    loginTimestamp: null
};

export function userLoginDataReducer(
    state: State = initialState,
    action: UserLoginDataActionsUnion
): State {
    switch (action.type) {
        case UserLoginDataActionTypes.Loaded:
            return {
                ...state,
                loginTimestamp: action.payload.loginTimestamp
            };

        default:
            return state;
    }
}
