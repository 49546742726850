import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { IJwtTokenProvider, JwtTokenRetry } from '../jwt.lifetime.processor';
import { retry, map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { noErrorHandlingForHeader } from '@core/constants/http-headers';
import { API } from '@core/constants/api';

@Injectable({
    providedIn: 'root'
})
export class PomJwtTokenProvider implements IJwtTokenProvider {
    constructor(
        private http: HttpClient
    ) {}

    public readonly retryTimeout: JwtTokenRetry = JwtTokenRetry.NoRetry;

    private readonly retryCount = 3;

    public getToken(): Observable<string> {

        return this.http.post(API.tokens.jwtToken, null, { headers: this._headers }).pipe(map(newToken => JSON.stringify(newToken)));
    }

    public async fetchToken(): Promise<string> {
        const fetchRetryCount = this.retryTimeout === JwtTokenRetry.NoRetry ? this.retryCount : 0;

        return await this.http
            .post(API.tokens.jwtToken, null, { headers: this._headers })
            .pipe(retry(fetchRetryCount))
            .toPromise()
            .then(newToken => JSON.stringify(newToken));
    }

    private get _headers(): HttpHeaders {

        return new HttpHeaders()
            .set(noErrorHandlingForHeader, '*')
            .set('Cache-control', 'no-cache')
            .set('Pragma', 'no-cache')
            .set('Expires', '0');

    }
}
