import { Action, Store, select } from '@ngrx/store';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { DesktopNotificationActionTypes, DesktopNotificationArrived } from '../actions/desktop-notification.actions';
import { EMPTY, Observable, } from 'rxjs';
import { switchMap, withLatestFrom } from 'rxjs/operators';

import { AssetClassModel } from '@core/models/asset-class';
import { DesktopNotificationService } from '../../desktop-notifications/desktop-notifications.service';
import { Injectable } from '@angular/core';
import { State } from '../../../reducers';
import { DateTimeFormatDefined } from '@northstar/buyside-components';

@Injectable()
export class DesktopNotificationEffect {

    public desktopNotificationArrivedEffect$: Observable<Action> = createEffect(() => this.actions$
    .pipe(
        ofType<DesktopNotificationArrived>(DesktopNotificationActionTypes.DesktopNotificationArrived),
        withLatestFrom(
            this.store.pipe(select(s => s.shared.userSettings)),
            this.store.pipe(select(s => s.shared.equityUserSettings)),
            this.store.pipe(select(s => s.shared.configuration))
        ),
        switchMap(([action, userSettings, equityUserSettings, { equityOrderServiceEnabled }]) => {
            if (action.payload.data.assetClass && action.payload.data.assetClass === AssetClassModel.Equity) {
                const equityDateFormat = new DateTimeFormatDefined(
                    equityUserSettings.dateFormat,
                    equityUserSettings.timeFormat,
                    equityUserSettings.timezone);

                this.desktopNotificationService.show(action.payload, equityDateFormat, false, equityOrderServiceEnabled);
            } else {
                const fiDateFormat = new DateTimeFormatDefined(
                    userSettings.dateFormat,
                    userSettings.timeFormat,
                    userSettings.timeZone);

                this.desktopNotificationService.show(action.payload, fiDateFormat, userSettings.singleInAppEmailNotifications, equityOrderServiceEnabled);
            }
            return EMPTY;
        })
    ), { dispatch: false });

    constructor(
        private store: Store<State>,
        private desktopNotificationService: DesktopNotificationService,
        private actions$: Actions) {
    }
}
