/* eslint-disable */
function noop() {}

export function Sockette(url, opts) {
    opts = opts || {};

    let ws: WebSocket, $ = <any>{};

    const retryTimeoutsMs = { 1: 1000, 2: 5000, 3: 10000, 4: 30000, poll: 60000 };
    const max = opts.maxAttempts || Infinity;
    let retryAttempt = 0;
    let retryTimer;

    let preventReconnect = false;

    $.open = function () {
        ws = new WebSocket(url, opts.protocols || []);

        ws.onmessage = opts.onmessage || noop;

        ws.onopen = function (e: any) {
            retryAttempt = 0;
            (opts.onopen || noop)(e);
        };

        ws.onclose = function (e: any) {
            $.reconnect(e);
            (opts.onclose || noop)(e);
        };

        ws.onerror = function (e: any) {
            $.reconnect(e);
            (opts.onerror || noop)(e);
        };

        $.connection = ws;
    };

    $.reconnect = function (e) {
        if (retryTimer || preventReconnect) {
            return;
        }

        if (retryAttempt < max) {
            retryAttempt++;

            retryTimer = setTimeout(function () {
                retryTimer = clearTimeout(retryTimer);
                (opts.onreconnect || noop)(e);
                $.open();
            }, retryTimeoutsMs[retryAttempt] || retryTimeoutsMs.poll)
        } else {
            (opts.onmaximum || noop)(e);
        }
    };

    $.json = function (x) {
        ws.send(JSON.stringify(x));
    };

    $.send = function (x) {
        if (ws && (ws.readyState === ws.CLOSING || ws.readyState == ws.CONNECTING)) {
            return;
        }

        ws.send(x);
    };

    $.close = function (x, y) {
        retryAttempt = 0;
        retryTimer = clearTimeout(retryTimer);
        preventReconnect = true;
        ws.close(x || 1e3, y);
    };

    $.open();

    return $;
}
