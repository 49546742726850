export interface MuniUserSettingHttpModel {
    id: string;
    settingType: SettingType;
    userPreference: any;
}

export enum SettingType {
    Filter = 'Filter',
    UserSetting = 'UserSetting'
}
