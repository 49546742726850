import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { environment } from '@environments/environment';
import { WebsocketConnectionActions } from '@core/@state/actions';
import * as fromRoot from '../../../reducers';
import * as LayoutActions from '@core/@state/actions/layout.actions';
import { BspWebsocketGatewayService, WebsocketGatewayAuthType } from '@core/websockets/bsp-websocket-gateway.service';
import { WebsocketEvent } from '@core/@state/effects/websocket-connection.effect';
import { LoggerActions } from '@core/@state/actions';
import { ClientLogLevel } from '@core/logger/log.model';

export enum ConnectionStatus {
    Starting = 'Connection is starting',
    Successful = 'Connection is successful',
    Lost = 'Connection is lost',
    Closed = 'Connection is closed'
}

@Injectable({providedIn: 'root'})
export class PlatformWebSocketsConnector {
    private WS_CONNECTION_NOT_AVAILABLE_TIMEOUT_MS = 30000;
    private APP_NOT_AVAILABLE_TIMEOUT_MS = 120000;

    private isConnected: boolean;
    private shouldShowAlertOnWSConnectionError: boolean;
    private latestJwtToken: string;

    constructor(
        private websocketGatewayService: BspWebsocketGatewayService,
        private store: Store<fromRoot.State>
    ) {
            this.websocketGatewayService.error$.subscribe(
                (event: any) => {
                    console.warn(`WEBSOCKETS :: ${ConnectionStatus.Lost}`, event);
                });

            this.websocketGatewayService.starting$.subscribe(
                event => {
                    console.log(`WEBSOCKETS :: ${ConnectionStatus.Successful}`, event);
                    this._dispatchStatusChangeAction(true, ConnectionStatus.Successful, event);

                    this.isConnected = true;
                    this.shouldShowAlertOnWSConnectionError = true;

                    this.watchBrowserWentOffline();
                    this.watchAppIsNotRunning();
                });

            this.websocketGatewayService.close$.subscribe(
                event => {
                    this._dispatchStatusChangeAction(false, ConnectionStatus.Closed, event);

                    this.isConnected = false;

                    this.watchWebsocketConnectionErrors();
                });
    }

    public connect(jwtAuthToken: string): void {
        if (environment.e2e) {
            return;
        }

        console.log(`WEBSOCKETS :: ${ConnectionStatus.Starting}`);
        this._dispatchStatusChangeAction(null, ConnectionStatus.Starting, null);

        this.latestJwtToken = jwtAuthToken;

        this.websocketGatewayService.connect(WebsocketGatewayAuthType.Alerts, this.latestJwtToken);
    }

    public disconnect(): void {
        this.websocketGatewayService.disconnect();
    }

    private _dispatchStatusChangeAction(isConnected: boolean, connectionStatus: ConnectionStatus, event: WebsocketEvent): void {
        this.store.dispatch(new WebsocketConnectionActions.ConnectionChanged({
            isConnected,
            connectionStatus,
            event
        }));
    }

    private watchWebsocketConnectionErrors(): void {
        setTimeout(() => {
            if (this.isConnected || !this.shouldShowAlertOnWSConnectionError) {
                return;
            }

            this.store.dispatch(new LayoutActions.ShowDisconnectAlert());
            this.logConnectionErrors('Websocket Connection Error');

            this.shouldShowAlertOnWSConnectionError = false;
        }, this.WS_CONNECTION_NOT_AVAILABLE_TIMEOUT_MS);
    }

    private watchBrowserWentOffline(): void {
        window.addEventListener('offline', () => {
            this.store.dispatch(new LayoutActions.ShowDisconnectAlert());
            this.logConnectionErrors('navigator.isOnline = false');
        });
    }

    private watchAppIsNotRunning(): void {
        let lastTime = new Date().getTime();

        const updateTime = () => {
            const currentTime = new Date().getTime();

            // Additional time offset to compensate browser timers throttling:
            // https://developer.chrome.com/blog/timer-throttling-in-chrome-88/
            // plus compensation of time which browser takes to execute script
            const BROWSER_THROTTLE_COMPENSATION_TIME_MS = 3000;

            if (currentTime > (lastTime + this.APP_NOT_AVAILABLE_TIMEOUT_MS + BROWSER_THROTTLE_COMPENSATION_TIME_MS)) {
                this.store.dispatch(new LayoutActions.ShowDisconnectAlert());
                this.logConnectionErrors('App was not running');
            }

            lastTime = currentTime;
        }

        setTimeout(() => {
            updateTime();

            setTimeout(() => updateTime(), this.APP_NOT_AVAILABLE_TIMEOUT_MS);
        }, this.APP_NOT_AVAILABLE_TIMEOUT_MS);
    }

    private logConnectionErrors(reason: string): void {
        this.store.dispatch(new LoggerActions.LogMessages(
            {
                logs: [{
                    entryDate: new Date(),
                    message: `[BSP Platform] [Connection] Connection was interrupted. Reason: ${reason}`,
                    level: ClientLogLevel.Warn,
                    extraInfo: null
                }],
                addExtraInfo: true
            })
        );
    }

}
