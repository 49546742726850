import { CredentialsRequestInterceptor } from './interceptors/credentials.interceptor';
import { ApiInterceptor } from '@core/interceptors/api.interceptor';
import { NgModule, Optional, SkipSelf, ErrorHandler } from '@angular/core';
import { HTTP_INTERCEPTORS,  HttpClientModule } from '@angular/common/http';
import { CommonModule } from '@angular/common';
import { CupcakeNotifyModule, CupcakeNotifyService, CupcakeModalModule } from '@ipreo/cupcake-components';

import { AuthInterceptor } from './interceptors/auth.interceptor';
import { ErrorInterceptor } from './interceptors/error.interceptor';
import { XsrfTokenInterceptor } from './interceptors/xsrf-token.interceptor';
import { CorrelationIdInterceptor } from './interceptors/correlation-id.interceptor';
import { JwtInterceptor } from './interceptors/jwt.interceptor';
import { LoggingInterceptor } from './interceptors/logging.interceptor';

import { JWT_MODULE_CONFIGURED } from './jwt-token.config';
import { windowProvider, windowRefProvider, WINDOW } from '@core/services/window.service';
import { PendoService } from '@core/services/pendo.service';
import { NotificationService } from './notifications/notification.service';
import { NativeNotificationService } from './notifications/native-desktop-notification.service';
import { CupcakeNotificationService } from './notifications/cupcake-notification.service';
import { DesktopNotificationService } from './desktop-notifications/desktop-notifications.service';
import { CacheService } from './desktop-notifications/cache-service/cache.service';
import { ImMemoryCacheService } from './desktop-notifications/cache-service/in-memory-cache.service';
import { LocalStorageCacheService } from './desktop-notifications/cache-service/local-storage-cache.service';
import { StorageService } from './services/storage.service';
import { AssetClassService } from './services/asset-class.service';
import { NumberFormatService } from './services/format/number-format-service';
import { GlobalErrorHandler } from './global-error-handler/global-error-handler.service';
import { TimeoutInterceptor } from './interceptors/timeout.interceptor';
import { ProfilingInterceptor } from './interceptors/profiling.interceptor';
import { VersionCheckService } from '@core/configuration/version-check.service';
import * as listeners from '@core/live-update/websockets/listeners';
import { EffectsModule } from '@ngrx/effects';
import { SharedModule as ComponentsShared } from '@northstar/buyside-components';
import { DesktopNotificationEffect } from './@state/effects/desktop-notification.effect';
import { BspWebsocketGatewayService } from '@core/websockets/bsp-websocket-gateway.service';
import { WebsocketGatewayService } from '@ipreo/northstar';
import { EquityUserSettingsService } from './user-settings/equity-user-settings.service';
import { AcksStorageService } from '@core/desktop-notifications/acks-storage.service';
import { ConfigurationService } from './services/configuration.service';
import { JwtStorageService } from '@core/authentication/jwt/jwt-storage.service';

@NgModule({
    /*
        Core module is loaded on app start.
        Please, do not add unnecessary dependencies to imports
    */
    imports: [
        CommonModule,
        HttpClientModule,
        JWT_MODULE_CONFIGURED,
        CupcakeNotifyModule,
        CupcakeModalModule,
        ComponentsShared,
        EffectsModule.forFeature([
            DesktopNotificationEffect
        ]),
    ],
    providers: [
        { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: CorrelationIdInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: XsrfTokenInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: LoggingInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: TimeoutInterceptor,  multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: ProfilingInterceptor,  multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: ApiInterceptor,  multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: CredentialsRequestInterceptor,  multi: true },
        windowRefProvider,
        windowProvider,
        PendoService,
        StorageService,
        {
            provide: NotificationService,
            useFactory: (cupcakeNotifyService, window) => 'Notification' in window
                ? new NativeNotificationService(window)
                : new CupcakeNotificationService(cupcakeNotifyService, window),
            deps: [ CupcakeNotifyService, WINDOW ]
        },
        {
            provide: CacheService,
            useFactory: (notificationService, storageService) => notificationService instanceof CupcakeNotificationService
                ? new ImMemoryCacheService()
                : new LocalStorageCacheService(storageService),
            deps: [ NotificationService, StorageService ]
        },
        DesktopNotificationService,
        AssetClassService,
        NumberFormatService,
        { provide: ErrorHandler, useClass: GlobalErrorHandler },
        VersionCheckService,
        BspWebsocketGatewayService,
        {
            provide: WebsocketGatewayService, useExisting: BspWebsocketGatewayService
        },
        listeners.WebsocketDesktopNotificationListener,
        listeners.WebsocketLiveUpdateListener,
        listeners.WebsocketTestMessageListener,
        listeners.WebsocketEquityLiveUpdateListener,
        EquityUserSettingsService,
        AcksStorageService,
        ConfigurationService,
        JwtStorageService
    ],
    declarations: []
})
export class CoreModule {
    constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
        if (parentModule) {
            throw new Error(
                'CoreModule is already loaded. Import it in the AppModule only');
        }
    }
}

