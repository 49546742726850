import { Injectable, Injector, ErrorHandler } from '@angular/core';
import { Store } from '@ngrx/store';

import { ClientLogLevel, ClientLogEntry, ClientLogExtraInfo } from '../logger/log.model';
import { generateGUID } from '../utils/utils';
import { State } from '../../reducers';
import { LoggerActions } from '@core/@state/actions';
import { Subject } from 'rxjs';
import { bufferTime, map, filter } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class GlobalErrorHandler extends ErrorHandler {
    private errors: Subject<Error> = new Subject<Error>();

    constructor(
        private injector: Injector
    ) {
        super();
        this._createScheduler();
    }

    public handleError(error: Error): void {
        this._logToServer(error);

        super.handleError(error);
    }

    private _logToServer(error: Error): void {
        this.errors.next(error);
    }

    private _createScheduler(): void {
        this.errors.pipe(
            map((error: Error) => this._createClientEntry(error)),
            bufferTime(5000),
            filter(errors => errors.length > 0),
        ).subscribe(errors => this._sendToServer(errors));
    }

    private _sendToServer(logs: ClientLogEntry<ClientLogExtraInfo>[]): void {
        const store = this.injector.get(Store, null) as Store<State>;

        if (store === null) {
            console.warn(`Wasn't able to log error to server`);

            return;
        }

        store.dispatch(new LoggerActions.LogMessages({ logs, addExtraInfo: true }));
    }

    private _createClientEntry(error: Error): ClientLogEntry<ClientLogExtraInfo> {
        return <ClientLogEntry<ClientLogExtraInfo>>{
            logId: generateGUID(),
            entryDate: new Date(),
            level: ClientLogLevel.Error,
            message: `Error: ${error.name}\r\n${error.message}`,
            extraInfo: {
                stackTrace: error.stack
            }
        };
    }
}
