import { Injectable } from '@angular/core';
import { StorageService } from '@core/services/storage.service';

@Injectable({
    providedIn: 'root'
})
export class OfflineLogsBuffer {

    private readonly LOCAL_STORAGE_KEY = 'platform_offline_logs';
    private readonly MAX_BUFFER_SIZE = 5;

    constructor(
        private storage: StorageService
    ) {}

    public add<T>(entry: T | T[]): void {
        if (!entry) {
            return;
        }

        let logs = this.storage.getObject<T[]>(this.LOCAL_STORAGE_KEY);

        if (!logs) {
            logs = [];
        }

        entry = Array.isArray(entry) ? entry : [entry];

        entry.forEach(e => logs.push(e));

        if (logs.length >= this.MAX_BUFFER_SIZE) {
            logs = logs.slice(-this.MAX_BUFFER_SIZE);
        }

        this.persistLogs(logs);
    }

    public getAll<T>(doClearBuffer: boolean = false): T[] {
        const buffer = this.storage.getObject<T[]>(this.LOCAL_STORAGE_KEY) || [];

        if (doClearBuffer) {
            this.clearBuffer();
        }

        return buffer;
    }

    public clearBuffer(): void {
        this.persistLogs([]);
    }

    private persistLogs<T>(logs: T[]): void {
        try {
            this.storage.setObject(this.LOCAL_STORAGE_KEY, logs);
        } catch (error) {
            console.error('[LogsCircularBuffer] Cannot save logs in Local Storage', error);
        }
    }

}
