import { Injectable } from '@angular/core';
import { Router, CanActivate } from '@angular/router';

import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { Permissions } from '../permissions/permissions.model';

import { select, Store } from '@ngrx/store';
import * as fromRoot from '../../reducers';

export abstract class PermissionedGuard implements CanActivate {
    protected abstract isPermissioned(permissions: Permissions): boolean;

    constructor(protected store: Store<fromRoot.State>, protected router: Router) {
    }

    public canActivate(): Observable<boolean> {
        return this.store.pipe(
           select(fromRoot.getPermissionsState),
           map(permissions => this.isPermissioned(permissions)),
           tap(isPermissioned => {
               if (!isPermissioned) {
                   this.router.navigate(['/']);
               }
           })
        );
    }
}

@Injectable({
    providedIn: 'root'
})
export class DealMonitorGuard extends PermissionedGuard {
    constructor(protected store: Store<fromRoot.State>, protected router: Router) {
        super(store, router);
    }

    protected isPermissioned(permissions: Permissions): boolean {
        return permissions.canViewFixedIncomeDealMonitor;
    }
}

@Injectable({
    providedIn: 'root'
})
export class ActivityStreamGuard extends PermissionedGuard {
    constructor(protected store: Store<fromRoot.State>, protected router: Router) {
        super(store, router);
    }

    protected isPermissioned(permissions: Permissions): boolean {
        return permissions.canViewActivityStream;
    }
}

@Injectable({
    providedIn: 'root'
})
export class DealQueryGuard extends PermissionedGuard {
    constructor(protected store: Store<fromRoot.State>, protected router: Router) {
        super(store, router);
    }

    protected isPermissioned(permissions: Permissions): boolean {
        return permissions.canViewDealQuery;
    }
}

@Injectable({
    providedIn: 'root'
})
export class ComplianceGuard extends PermissionedGuard {
    constructor(protected store: Store<fromRoot.State>, protected router: Router) {
        super(store, router);
    }

    protected isPermissioned(permissions: Permissions): boolean {
        return permissions.canViewCompliance;
    }
}

@Injectable({
    providedIn: 'root'
})
export class EquityDealMonitorGuard extends PermissionedGuard {
    constructor(protected store: Store<fromRoot.State>, protected router: Router) {
        super(store, router);
    }

    protected isPermissioned(permissions: Permissions): boolean {
        return permissions.canViewEquityDealMonitor;
    }
}

@Injectable({
    providedIn: 'root'
})
export class EquityDealQueryGuard extends PermissionedGuard {
    constructor(protected store: Store<fromRoot.State>, protected router: Router) {
        super(store, router);
    }

    protected isPermissioned(permissions: Permissions): boolean {
        return permissions.canViewEquityDealQuery;
    }
}

@Injectable({
    providedIn: 'root'
})
export class EquityPublicDealsGuard extends PermissionedGuard {
    constructor(protected store: Store<fromRoot.State>, protected router: Router) {
        super(store, router);
    }

    protected isPermissioned(permissions: Permissions): boolean {
        return permissions.canViewEquityPublicDeals;
    }
}
