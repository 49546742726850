import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';

import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';

import { select, Store } from '@ngrx/store';
import * as fromRoot from '../../reducers';

@Injectable({
    providedIn: 'root',
})
export class ProfileConfirmedGuard implements CanActivate {

    constructor(private store: Store<fromRoot.State>, private router: Router) {
    }

    public canActivate(): Observable<boolean> {
        return this.store.pipe(
            select(fromRoot.getAuthState),
            map(auth => auth.isUserProfileConfirmed),
            tap((isConfirmed) => {
                if (!isConfirmed) {
                    this.router.navigate(['/login/confirmation']);
                }
            })
        );
    }
}
