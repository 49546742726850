import { LayoutActions } from '../actions';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { AssetClassModel } from '@core/models/asset-class';

export interface State {
    showDisconnectAlert: boolean;
    assetClass: AssetClassModel;
    isNavigating: boolean;
}

export const initialState: State = {
    showDisconnectAlert: false,
    assetClass: null,
    isNavigating: false
};

export function layoutReducer(
    state: State = initialState,
    action: LayoutActions.LayoutActionsUnion
): State {
    switch (action.type) {
        case LayoutActions.LayoutActionTypes.ShowDisconnectAlert:
            return {
                ...state,
                showDisconnectAlert: true
            };

        case LayoutActions.LayoutActionTypes.HideDisconnectAlert:
            return {
                ...state,
                showDisconnectAlert: false
            };

        case LayoutActions.LayoutActionTypes.SetAssetClass:
            return {
                ...state,
                assetClass: action.payload
            };

        case LayoutActions.LayoutActionTypes.StartNavigation:
            return {
                ...state,
                isNavigating: true
            };

        case LayoutActions.LayoutActionTypes.EndNavigation:
            return {
                ...state,
                isNavigating: false
            };

        default:
            return state;
    }
}

export const getLayoutState = createFeatureSelector<State>('layout');
export const getCurrentAssetClass = createSelector(getLayoutState, layout => layout.assetClass);
export const getIsNavigating = createSelector(getLayoutState, layout => layout.isNavigating);
export const getShowDisconnectAlert = createSelector(getLayoutState, layout => layout.showDisconnectAlert);
