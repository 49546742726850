<ng-container [ngSwitch]="warning">
    <ng-container *ngSwitchCase="null"></ng-container>
    <app-alert [type]="AlertType.Error" *ngSwitchCase="WarningType.PasswordIsUsedPreviously">
        <span class="change-password-warning__e2e--match-previous">
            New Password cannot match any of your previous five passwords
        </span>
    </app-alert>
    <app-alert [type]="AlertType.Error" *ngSwitchCase="WarningType.PasswordRequirementMissed">
        <span class="change-password-warning__e2e--complexity-error">
            Password does not meet complexity requirements
        </span>
    </app-alert>
    <app-alert [type]="AlertType.Error" *ngSwitchCase="WarningType.CurrentPasswordDoNotMatch">
        <span class="change-password-warning__e2e--not-match">
            Does not match current password
        </span>
    </app-alert>
    <app-alert [type]="AlertType.Error" *ngSwitchCase="WarningType.ResetPasswordFailed">
        <span class="change-password-warning__e2e--reset-failed">
            Password reset failed
        </span>
    </app-alert>
    <app-alert [type]="AlertType.Error" *ngSwitchCase="WarningType.PasswordMatchesPrevious">
        <span class="change-password-warning__e2e--match-old">
            New password and Old password are the same
        </span>
    </app-alert>
    <app-alert [type]="AlertType.Error" *ngSwitchCase="WarningType.PasswordCrieteriaNotFullfiled">
        <span class="change-password-warning__e2e--expired">
            {{ WarningType.PasswordCrieteriaNotFullfiled}}
        </span>
    </app-alert>
    <app-alert [type]="AlertType.Error" *ngSwitchCase="WarningType.PasswordContainsUserName">
        <span class="change-password-warning__e2e--expired">
            {{ WarningType.PasswordContainsUserName}}
        </span>
    </app-alert>
    <app-alert [type]="AlertType.Error" *ngSwitchCase="WarningType.PasswordContainsUserIdOrEmail">
        <span class="change-password-warning__e2e--expired">
            {{ WarningType.PasswordContainsUserIdOrEmail}}
        </span>
    </app-alert>
    <app-alert [type]="AlertType.Error" *ngSwitchCase="WarningType.UserNewPasswordSameAsOld">
        <span class="change-password-warning__e2e--expired">
            {{ WarningType.UserNewPasswordSameAsOld}}
        </span>
    </app-alert>
    <app-alert [type]="AlertType.Error" *ngSwitchCase="WarningType.PasswordRepeatedCharacters">
        <span class="change-password-warning__e2e--expired">
            {{ WarningType.PasswordRepeatedCharacters}}
        </span>
    </app-alert>
    <app-alert [type]="AlertType.Error" *ngSwitchCase="WarningType.PasswordMismatch">
        <span class="change-password-warning__e2e--expired">
            {{ WarningType.PasswordMismatch }}
        </span>
    </app-alert>
    <app-alert [type]="AlertType.Error" *ngSwitchCase="WarningType.SeqCharsInPasswordValidator">
        {{ WarningType.SeqCharsInPasswordValidator }}
    </app-alert>
    <app-alert [type]="AlertType.Error" *ngSwitchDefault>
        <span class="change-password-warning__e2e--expired">
            {{ WarningType.ChangePasswordFailed }}
        </span>
    </app-alert>
</ng-container>
