import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';

import { JwtHelperService } from '@auth0/angular-jwt';
import { Subscription, ReplaySubject } from 'rxjs';

import { JwtTokenType, TimeoutHandle, TimeoutState, TokenState } from '../../constants/constants';
import { JwtStorageService } from '@core/authentication/jwt/jwt-storage.service';

export interface JwtTokenAcquisitionInfo {
    tokenType: JwtTokenType;
    tokenStorageKey: string;
    tokenProvider: IJwtTokenProvider;
}

export interface JwtTokenLifetimeContext {
    refreshTimeoutHandle: TimeoutHandle;
    eventEmitter: ReplaySubject<string>;
}

export enum JwtTokenRetry {
    NoRetry = 'no retry'
}
export type JwtTokenRetryTimeout = number | JwtTokenRetry.NoRetry;

export interface IJwtTokenProvider {
    retryTimeout: JwtTokenRetryTimeout;
    fetchToken():  Promise<string>;
}

@Injectable({
    providedIn: 'root'
})
export class JwtLifetimeProcessor {

    private readonly TOKEN_REFRESH_TIME = 0.9;
    private readonly TOKEN_MIN_VALID_TIME = 60 * 3;

    private tokenContexts: {[type in JwtTokenType]?: JwtTokenLifetimeContext} = {};

    constructor(
        private jwtHelperService: JwtHelperService,
        private jwtStorageService: JwtStorageService
    ) {}

    public async initializeTokenAsync(tokenInfo: JwtTokenAcquisitionInfo): Promise<string> {
        if (this.tokenContexts[tokenInfo.tokenType]) {
            this.disposeExistingTokenContext(tokenInfo.tokenType);
        }

        this.createTokenContext(tokenInfo.tokenType);

        let token = this.jwtStorageService.get(tokenInfo.tokenStorageKey);
        if (!token) {
            token = TokenState.Absent;
        } else if (this.isTokenExpired(token)) {
            this.handleTokenExpired(tokenInfo);
            token = TokenState.Absent;
        }

        if (token === TokenState.Absent) {
            token = await this.fetchToken(tokenInfo);

            return token;
        }

        const refreshTimeout = this.getExpectedRefreshTokenTime(token);
        this.scheduleNextUpdate(refreshTimeout, tokenInfo);
        this.tokenContexts[tokenInfo.tokenType].eventEmitter.next(token);

        return token;
    }

    public subscribe = (tokenType: JwtTokenType, callback: (newToken: string) => void): Subscription => {
        if (!this.tokenContexts[tokenType]) {
            this.createTokenContext(tokenType);
        }

        return this.tokenContexts[tokenType].eventEmitter.subscribe(callback);
    }

    public unsubscribe(tokenType: JwtTokenType): void {
        if (!this.tokenContexts[tokenType]) {
            return;
        }

        this.disposeExistingTokenContext(tokenType);
    }

    private async fetchToken(tokenInfo: JwtTokenAcquisitionInfo): Promise<any> {
        this.clearTokenRefreshTimeout(this.tokenContexts[tokenInfo.tokenType]);

        let newToken;

        try {
            newToken = await tokenInfo.tokenProvider.fetchToken();
            this.handleTokenAcquired(newToken, tokenInfo);

            return newToken;
        } catch (err) {
            console.warn('WebsocketGateway JWT token acquisition attempt has failed', err.error);

            if (tokenInfo.tokenProvider.retryTimeout === JwtTokenRetry.NoRetry) {
                return;
            }
            this.scheduleNextUpdate(tokenInfo.tokenProvider.retryTimeout, tokenInfo);

            return null;
        }
    }

    public isTokenExpired(token: string): boolean {
        try {
            return token && (this.jwtHelperService.isTokenExpired(token, this.TOKEN_MIN_VALID_TIME));
        } catch (error) {
            return true;
        }
    }

    public getExpectedRefreshTokenTime = (token): number => {
        const expirationTime = this.jwtHelperService.getTokenExpirationDate(token);

        return ((expirationTime.getTime() - Date.now()) * this.TOKEN_REFRESH_TIME);
    }

    private disposeExistingTokenContext = (tokenType: JwtTokenType) => {
        const tokenContext = this.tokenContexts[tokenType];

        this.clearTokenRefreshTimeout(tokenContext);
        tokenContext.eventEmitter.complete();
        delete this.tokenContexts[tokenType];
    }

    private handleTokenExpired = (tokenInfo: JwtTokenAcquisitionInfo) => {
        const tokenContext = this.tokenContexts[tokenInfo.tokenType];
        this.jwtStorageService.delete(tokenInfo.tokenStorageKey);
        this.clearTokenRefreshTimeout(tokenContext);
    }

    private clearTokenRefreshTimeout = (tokenContext: JwtTokenLifetimeContext) => {
        if (tokenContext.refreshTimeoutHandle === TimeoutState.TimeoutCleared) {
            return;
        }

        clearTimeout(tokenContext.refreshTimeoutHandle);
        tokenContext.refreshTimeoutHandle = TimeoutState.TimeoutCleared;
    }

    private handleTokenAcquired = (newToken: string, tokenInfo: JwtTokenAcquisitionInfo) => {
        const tokenContext = this.tokenContexts[tokenInfo.tokenType];

        this.jwtStorageService.set(tokenInfo.tokenStorageKey, newToken);

        const refreshTimeout = this.getExpectedRefreshTokenTime(newToken);
        this.scheduleNextUpdate(refreshTimeout, tokenInfo);

        tokenContext.eventEmitter.next(newToken);
    }

    private createTokenContext = (tokenType: JwtTokenType): void  => {
        this.tokenContexts[tokenType] = <JwtTokenLifetimeContext>{
            refreshTimeoutHandle: TimeoutState.TimeoutCleared,
            eventEmitter: new ReplaySubject<string>(1)
        };
    }

    private scheduleNextUpdate = (updateTimeout: number, tokenInfo: JwtTokenAcquisitionInfo): void => {
        if (environment.e2e) {
            return;
        }

        const tokenContext = this.tokenContexts[tokenInfo.tokenType];

        if (!tokenContext || tokenContext.refreshTimeoutHandle !== TimeoutState.TimeoutCleared) {
            return;
        }

        tokenContext.refreshTimeoutHandle = window.setTimeout(async () => {
            await this.fetchToken(tokenInfo);
        }, updateTimeout);
    }

}

