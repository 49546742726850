import {HttpErrorResponse, HttpRequest} from '@angular/common/http';
import { Observable, throwError, timer } from 'rxjs';
import {mergeMap, tap} from 'rxjs/operators';
import {correlationIdRequestHeader} from '@core/constants/http-headers';

const RETRY_TIMEOUTS = [ 0, 2000, 3000, 5000 ];
const SERVER_ERROR_CODES = [ 502, 503, 504 ];

const INCLUDED_STATUS_CODES = {
    get: code => code === 0 || code > 401 && code !== 404 && code !== 409,
    post: code => SERVER_ERROR_CODES.indexOf(code) !== -1,
    put: code => SERVER_ERROR_CODES.indexOf(code) !== -1,
    delete: code => SERVER_ERROR_CODES.indexOf(code) !== -1
};

export const retryStrategy = ({
    request,
    retryTimeouts = RETRY_TIMEOUTS,
    includedStatusCodes = INCLUDED_STATUS_CODES,
    retryAttemptsMap
}: {
    request: HttpRequest<any>,
    retryTimeouts?: number[],
    includedStatusCodes?: { [httpMethod: string]: (code: number) => boolean },
    retryAttemptsMap: { [key: string]: number }
}) => (attempts: Observable<any>) => {
    return attempts.pipe(
        mergeMap((error: HttpErrorResponse, i: number) => {
            const retryAttempt = i + 1;

            if (
                retryAttempt === 1 &&
                ((error as any).name === 'TimeoutError' ||  (error.error instanceof ProgressEvent))
            ) {
                const retryIn = retryTimeouts[i];
                console.log(`Attempt ${retryAttempt}: retrying '${error.url}' in ${retryIn}ms`);
                retryAttemptsMap[request.headers.get(correlationIdRequestHeader)] = retryAttempt;
                return timer(retryIn);
            }

            if (retryAttempt > retryTimeouts.length ||
                !includedStatusCodes[request.method.toLowerCase()] ||
                !includedStatusCodes[request.method.toLowerCase()](error.status)) {

                return throwError(error);
            }

            const retryIn = retryTimeouts[i];
            console.log(`Attempt ${retryAttempt}: retrying '${error.url}' in ${retryIn}ms`);
            retryAttemptsMap[request.headers.get(correlationIdRequestHeader)] = retryAttempt;
            return timer(retryIn);
        })
    );
};
