import { AssetClassModel } from './../models/asset-class';
import { Injectable, Inject, InjectionToken } from '@angular/core';
import { NotificationService } from '../notifications/notification.service';
import { AdditionalData, DesktopNotification } from './desktop-notification';
import { WINDOW } from '../services/window.service';
import { CacheService } from './cache-service/cache.service';
import { DateFormatService, DateTimeFormatDefined, TimeZoneFormat } from '@northstar/buyside-components';
import { DealUpdateType } from './deal-update-type';
import { AcksStorageService } from '@core/desktop-notifications/acks-storage.service';

@Injectable({
    providedIn: 'root'
})
export class DesktopNotificationService {

    constructor(
        @Inject(WINDOW) private window: Window,
        private notificationService: NotificationService,
        private cacheService: CacheService,
        private dateFormatService: DateFormatService,
        private acksStorageService: AcksStorageService
    ) {
    }

    public show(notification: DesktopNotification, dateTimeFormat: DateTimeFormatDefined, singleInAppEmailNotifications: boolean, equityOrderServiceEnabled: boolean ): void {
        const delay = Math.random() * 1000;
        const notificationTransformed = this.applyDataTransformation(notification, dateTimeFormat);
        this.scheduleShowIn(delay, () => {
            if (!this.validateInStorage(this.cacheService, notification.tag)) {
                return;
            }

            if (!this.validateAcknowledgment(notification, singleInAppEmailNotifications)) {
                return;
            }

            this.notificationService.showNotification(notificationTransformed.title, <NotificationOptions>notificationTransformed,
                () => this.openNewTab(notificationTransformed.data, equityOrderServiceEnabled));
        });
    }

    private validateInStorage(storageService: {isValueInCache, push},tag: string): boolean {
        if (storageService.isValueInCache(tag)) {
            return false;
        }

        storageService.push(tag);

        return true;
    }

    private scheduleShowIn(delay: number, action: (...args: any[]) => void): void {
        this.window.setTimeout(action, delay);
    }

    private openNewTab(data: AdditionalData, equityOrderServiceEnabled: boolean): void {
        const url = this.getNewTabUrl(data, equityOrderServiceEnabled);
        const newWindow = this.window.open(url);
        newWindow.focus();
    }

    private applyDataTransformation(notification: DesktopNotification, dateTimeFormatDefined: DateTimeFormatDefined): DesktopNotification {
        if (!notification.data.dealVersionDate && notification.body.indexOf('<DealVersionDate>') === -1) {
            return notification;
        }

        const dealVersionDateString =
            this.dateFormatService.format(notification.data.dealVersionDate, dateTimeFormatDefined, TimeZoneFormat.Localized, true);
        const updatedBody = notification.body.replace('<DealVersionDate>', dealVersionDateString);

        return { ...notification, body: updatedBody };
    }

    private validateAcknowledgment({ data }: DesktopNotification, singleInAppEmailNotifications: boolean): boolean {
        if (!singleInAppEmailNotifications) {
            return true;
        }

        switch (data.dealUpdateType) {
            case DealUpdateType.Message:
                return this.validateInStorage(this.acksStorageService, `${DealUpdateType.Message}_${data.dealVersionId}`);
            case DealUpdateType.CurrentLevel:
                return this.validateInStorage(this.acksStorageService, `${DealUpdateType.CurrentLevel}_${data.trancheParentIds.join('_')}`);
            default:
                return true;
        }
    }

    private getNewTabUrl(data: AdditionalData, equityOrderServiceEnabled: boolean): string {
        switch (data.assetClass) {
            case AssetClassModel.Equity:
                return `#/equity/deal-monitor/deal/${equityOrderServiceEnabled ? data.dealId : data.equityDealId}/details`;
            case AssetClassModel.FixedIncome:
                let url = `#/deal/${data.dealId}/${data.messageId}`;
                if (data.dealUpdateType === DealUpdateType.InternalOrder) {
                    url += `/internalaggregation`;
                }
                return url;
            default:
                throw new Error(`Unsupported asset class. Actual class = ${data.assetClass}`);
        }
    }
}
