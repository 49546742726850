import { Action } from '@ngrx/store';
import { MessageType } from '../../types';

export enum MessageTypesActions {
    Load = '[northstar-alerts] Load Message Types',
    LoadSuccess = '[northstar-alerts] Load Messages Types Success',
    LoadFail = '[northstar-alerts] Load Messages Types Error'
}

/* tslint:disable:max-classes-per-file */

export class MessageTypesLoadAction implements Action {
    public readonly type = MessageTypesActions.Load;
}

export class MessageTypesLoadSuccessAction implements Action {
    public readonly type = MessageTypesActions.LoadSuccess;

    constructor(public payload: MessageType[]) {
    }
}

export class MessageTypesLoadFailAction implements Action {
    public readonly type = MessageTypesActions.LoadFail;

    constructor(public payload: string) {
    }
}

export type MessageTypesActionsUnion =
    | MessageTypesLoadAction
    | MessageTypesLoadSuccessAction
    | MessageTypesLoadFailAction;
