import { Injectable } from '@angular/core';

import { of } from 'rxjs';
import { pluck, switchMap, catchError, withLatestFrom } from 'rxjs/operators';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { SupportActions } from '../actions';

import { SupportService } from '../../../modules/support/services/support.service';
import { SendEquityMessage } from '../actions/support.actions';
import { select, Store } from '@ngrx/store';
import { State } from 'src/app/reducers';

@Injectable()
export class SupportEffect {

    constructor(
        private actions$: Actions,
        private supportService: SupportService,
        private store: Store<State>,
    ) {}

    public sendEquityMessage$ = createEffect(() => this.actions$
    .pipe(
        ofType<SendEquityMessage>(SupportActions.SupportActionTypes.SendEquityMessage),
        withLatestFrom(
            this.store.pipe(select(s => s.shared.userSettings))
        ),
        switchMap(([action, userSettings]) => {
            return this.supportService.sendEquityMessage(action.payload, userSettings).pipe(
                switchMap(() => of(new SupportActions.SendMessageSuccess())),
                catchError(() => of(new SupportActions.SendMessageFailed()))
            );
        })
    ));


    public sendMuniMessage$ = createEffect(() => this.actions$.pipe(
        ofType(SupportActions.SupportActionTypes.SendMuniMessage),
        pluck('payload'),
        switchMap((message: string) => {
            return this.supportService.sendMuniMessage(message).pipe(
                switchMap(() => of(new SupportActions.SendMessageSuccess())),
                catchError(() => of(new SupportActions.SendMessageFailed()))
            );
        })
    ));


    public sendBuysideMessage$ = createEffect(() => this.actions$.pipe(
        ofType(SupportActions.SupportActionTypes.SendBuysideMessage),
        pluck('payload'),
        switchMap((message: string) => {
            return this.supportService.sendBuysideMessage(message).pipe(
                switchMap(() => of(new SupportActions.SendMessageSuccess())),
                catchError(() => of(new SupportActions.SendMessageFailed()))
            );
        })
    ));

}
