import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import {
    UserContextHttpModel,
    UserContextModel,
    UserContextHttpProfileCompany,
    UserContextHttpProfileContainer,
    UserContextProfileCompany,
    UserContextProfileContainer
} from './user-context-http.model';
import { Observable } from 'rxjs';
import { API } from '@core/constants/api';
import { noErrorHandlingForHeader } from '@core/constants/http-headers';

@Injectable({
    providedIn: 'root'
})
export class UserContextHttpService {

    constructor(
        private http: HttpClient
    ) {}

    public getContext(): Observable<UserContextHttpModel> {
        return this.http.get<UserContextHttpModel>(API.session);
    }

    public async load(): Promise<UserContextHttpModel> {
        const headers = new HttpHeaders({
            [noErrorHandlingForHeader]: '*'
        });

        return await this.http.get<UserContextHttpModel>(API.session, { headers })
            .toPromise();
    }

    public buildUserContext(userContextHttpModel: UserContextHttpModel): UserContextModel {
        const httpContext = userContextHttpModel.context;

        return <UserContextModel>{
            Profile: {
                ...httpContext.Profile,
                Containers: httpContext.Containers.map(container => {
                    const httpProfileContainer: UserContextHttpProfileContainer = httpContext.Profile.Containers
                        .find(profileContainer => profileContainer.Id === container.Id);

                    return <UserContextProfileContainer>{
                        ...httpProfileContainer,
                        Name: container.Name,
                        IsTestContainer: container.IsTestContainer,
                        Status: container.Status
                    };
                }),
                Companies: httpContext.Companies.map(company => {
                    const httpProfileCompany: UserContextHttpProfileCompany = httpContext.Profile.Companies
                        .find(profileCompany => profileCompany.CompanyId === company.Id);

                    return <UserContextProfileCompany>{
                        ...httpProfileCompany,
                        Name: company.Name,
                        OrionId: company.OrionId,
                        ActiveUsers: company.ActiveUsers,
                        ContainerId: company.ContainerId,
                        ParentCompanyId: company.ParentCompanyId
                    };
                })
            },
            Claims: httpContext.Claims,
            Settings: httpContext.Settings,
            AccessToken: httpContext.AccessToken,
            UserSettings: httpContext.UserSettings,
            IsAccessingFromUS: httpContext.IsAccessingFromUS,
            IsUserProfileConfirmed: httpContext.IsUserProfileConfirmed
        };
    }
}
