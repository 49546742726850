import { Injectable } from '@angular/core';
import { Observable, throwError, of, Subscription } from 'rxjs';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { mergeMap } from 'rxjs/operators';
import { select, Store } from '@ngrx/store';
import * as fromRoot from '../../../reducers';
import {
    MessagesStatusResponse,
    MessagesResponse,
    ErrorResponse,
    MessagesStatusSuccessResponse,
    Message,
    MessageType,
    MessageTypeResponse,
    MessageTypePreference,
    MessageTypePreferenceResponse,
    MessageTypePreferenceRequest,
    Transport,
    TransportResponse,
} from '../types';
import { ConfigState } from '../state/config/state';

const API_HOSTS = {
    ci: 'https://buyside-alert-store.dev.ia.ipreo.com',
    staging: 'https://buyside-alert-store.stg.ia.ipreo.com',
    prod: 'https://buyside-alert-store.ia.ipreo.com',
    perf: 'https://buyside-alert-store.perf.ia.ipreo.com',
    qx: 'https://buyside-alert-store.qx.ia.ipreo.com',
    uat: 'https://buyside-alert-store.uat.ia.ipreo.com',
    hotfix: 'https://buyside-alert-store.hotfix.ia.ipreo.com'
};

@Injectable()
export class AlertsService {
    private baseUrl: string;
    private configurationSubscription: Subscription;

    constructor(
        private http: HttpClient,
        private store: Store<fromRoot.State>
    ) {
        this.configurationSubscription = this.store.pipe(select(fromRoot.getEnvironment))
            .subscribe(environment => {
                this.baseUrl = API_HOSTS[environment];
            });
    }

    public getStatus(
        config: ConfigState
    ): Observable<MessagesStatusSuccessResponse> {
        return this.get<MessagesStatusResponse>('/messages/status', config).pipe(
            mergeMap((res: HttpResponse<MessagesStatusResponse>) => {
                if (res.status !== 200) {
                    const errorResponse = res.body as ErrorResponse;
                    return throwError(errorResponse.developerMessage);
                }

                return of(res.body as MessagesStatusSuccessResponse);
            })
        );
    }

    public getMessages(
        options: { size?: number; from?: number },
        config: ConfigState
    ): Observable<Message[]> {
        const params = {
            size: `${options.size || 20}`,
            from: `${options.from || 0}`,
        };

        return this.get<MessagesResponse>('/messages', config, params).pipe(
            mergeMap((res: HttpResponse<MessagesResponse>) => {
                if (res.status !== 200) {
                    const errorResponse = res.body as ErrorResponse;
                    return throwError(errorResponse.developerMessage);
                }

                return of(res.body as Message[]);
            })
        );
    }

    public getMessageTypes(config: ConfigState): Observable<MessageType[]> {
        return this.get<MessageTypeResponse>('/message-types', config).pipe(
            mergeMap((res: HttpResponse<MessageTypeResponse>) => {
                if (res.status !== 200) {
                    const errorResponse = res.body as ErrorResponse;
                    return throwError(errorResponse.developerMessage);
                }

                return of(res.body as MessageType[]);
            })
        );
    }

    public getPreferences(
        config: ConfigState
    ): Observable<MessageTypePreference[]> {
        return this.get<MessageTypePreferenceResponse>('/preferences', config).pipe(
            mergeMap((res: HttpResponse<MessageTypePreferenceResponse>) => {
                if (res.status !== 200) {
                    const errorResponse = res.body as ErrorResponse;
                    return throwError(errorResponse.developerMessage);
                }

                return of(res.body as MessageTypePreference[]);
            })
        );
    }

    public getTransports(config: ConfigState): Observable<Transport[]> {
        return this.get<TransportResponse>('/transports', config).pipe(
            mergeMap((res: HttpResponse<TransportResponse>) => {
                if (res.status !== 200) {
                    const errorResponse = res.body as ErrorResponse;
                    return throwError(errorResponse.developerMessage);
                }

                return of(res.body as Transport[]);
            })
        );
    }

    public setPreference(
        request: MessageTypePreferenceRequest,
        config: ConfigState
    ): Observable<void> {
        const body = {
            appCode: config.appCode,
            createPreferenceIdentifiers: [request],
        };

        return this.http
            .post(`${this.baseUrl}/preferences`, body, {
                observe: 'response',
                headers: {
                    Authorization: `Bearer ${config.token}`,
                },
            })
            .pipe(
                mergeMap((res: HttpResponse<ErrorResponse>) => {
                    if (res.status !== 200) {
                        const errorResponse = res.body;
                        return throwError(errorResponse.developerMessage);
                    }
                    return of(undefined);
                })
            );
    }

    public readMessages(
        deliveryIds: number[],
        read: boolean,
        config: ConfigState
    ): Observable<void> {
        const body = {
            appCode: config.appCode,
            alerts: deliveryIds.map((deliveryId) => ({
                deliveryId,
                isRead: read,
            })),
        };

        return this.http
            .put(`${this.baseUrl}/messages/deliveries/read`, body, {
                observe: 'response',
                headers: {
                    Authorization: `Bearer ${config.token}`,
                },
            })
            .pipe(
                mergeMap((res: HttpResponse<ErrorResponse>) => {
                    if (res.status !== 200) {
                        const errorResponse = res.body;
                        return throwError(errorResponse.developerMessage);
                    }
                    return of(undefined);
                })
            );
    }

    public readAllMessages(config: ConfigState): Observable<void> {
        const body = {
            appCode: config.appCode,
        };

        return this.http
            .put(`${this.baseUrl}/messages/deliveries/read-all`, body, {
                observe: 'response',
                headers: {
                    Authorization: `Bearer ${config.token}`,
                },
            })
            .pipe(
                mergeMap((res: HttpResponse<ErrorResponse>) => {
                    if (res.status !== 200) {
                        const errorResponse = res.body;
                        return throwError(errorResponse.developerMessage);
                    }
                    return of(undefined);
                })
            );
    }

    private get<T>(
        path: string,
        config: ConfigState,
        params: { [key: string]: string } = {}
    ) {
        return this.http.get<T>(`${this.baseUrl}${path}`, {
            observe: 'response',
            headers: {
                Authorization: `Bearer ${config.token}`,
            },
            params: {
                ...params,
                appCode: config.appCode,
            },
        });
    }
}
