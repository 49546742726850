export interface MuniUserSettingsModel {
    defaultMarginalTaxRate: MuniUserSettingModel<number>;
    defaultBenchmarkCurve: MuniUserSettingModel<BenchmarkCurve>;
    defaultFlexColumn: MuniUserSettingModel<FlexColumn>;
    defaultCalendarFollowAssignmentShortcut: MuniUserSettingModel<boolean>;
    defaultDealManagerView: MuniUserSettingModel<DealManagerViewSettingModel>;
    defaultCalendarRatingsView: MuniUserSettingModel<CalendarRatingsView>;
}

export interface MuniUserSettingModel<T> {
    id: string;
    value: T;
}

export interface DealManagerViewSettingModel {
    type: DealManagerView;
    options: DealManagerInternalViewOptions;
}

export interface DealManagerInternalViewOptions {
    trackingOptions: boolean;
    assignments: boolean;
}

export enum BenchmarkCurve {
    MmdInterpolated = 'MMD Interpolated',
    MmdCurrent = 'MMD Current',
    MmdAaaInsured = 'MMD AAA Insured',
    MmdAa = 'MMD AA',
    MmdA = 'MMD A',
    MmdBaa = 'MMD BAA',
    MmdPreRe = 'MMD Pre-Re',
    MacInterpolated = 'MAC Interpolated',
    MacCurrent = 'MAC Current'
}

export enum FlexColumn {
    Documents = 'Documents',
    Sector = 'Sector',
    Purpose = 'Purpose',
    ERP = 'Extraordinary Redemption Provision',
    BankQualified = 'Bank Qualified',
    SourceOfRepayment = 'Source of Repayment',
    IssueType = 'Issue Type',
    FirstCallDate = 'First Call Date',
    FirstCouponDate = 'First Coupon Date',
    DatedDate = 'Dated Date'
}

export enum DealManagerView {
    Structure = 'Structure',
    Internal = 'Internal'
}

export enum CalendarRatingsView {
    Deal = 'Deal',
    Underlying = 'Underlying'
}
