import { Injectable } from '@angular/core';
import { Permissions } from '@core/permissions/permissions.model';
import { Store } from '@ngrx/store';
import * as fromRoot from '../../../../reducers';
import { WebsocketBaseListener } from './websocket-base-listener';
import { BspConfiguration } from '../../../configuration/bsp-configuration';
import { DesktopNotificationArrived } from '@core/@state/actions/desktop-notification.actions';
import { BspWebsocketGatewayService } from '@core/websockets/bsp-websocket-gateway.service';

@Injectable({providedIn: 'root'})
export class WebsocketDesktopNotificationListener extends WebsocketBaseListener {

    constructor(
        websocketGatewayService: BspWebsocketGatewayService,
        store: Store<fromRoot.State>) {
        super(websocketGatewayService, store);
    }

    protected getChannelName(configuration: BspConfiguration): string {
        return configuration.desktopNotificationChannelName;
    }

    protected isPermitted(permissions: Permissions): boolean {
        return permissions.canViewFixedIncomeDealMonitor
            || permissions.canViewDealQuery
            || permissions.canViewActivityStream
            || permissions.canViewOrders
            || permissions.canViewEquityDealMonitor
            || permissions.canViewEquityDealQuery;
    }

    protected onUpdate(payload: any): void {
        console.log(`[DesktopNotification received] Desktop notification [type=${payload.data.dealUpdateType}]`, payload);
        this.store.dispatch(new DesktopNotificationArrived(payload));
    }
}
