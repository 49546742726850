import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpHeaders } from '@angular/common/http';

import { BehaviorSubject, Observable, of, throwError } from 'rxjs';
import { JWT_OPTIONS, JwtHelperService } from '@auth0/angular-jwt';
import { jwtTokenRequestHeader, noErrorHandlingForHeader } from '../constants/http-headers';
import { JwtLifetimeProcessor } from '@core/authentication/jwt/jwt.lifetime.processor';
import { catchError, filter, take, switchMap } from 'rxjs/operators';
import { select, Store } from '@ngrx/store';
import * as fromRoot from '../../reducers';
import { StorageService } from '@core/services/storage.service';
import { pomJwtTokenStorageKey, jwtTokenV2StorageKey } from '@core/constants/constants';
import { API } from '@core/constants/api';
import { JwtStorageService } from '@core/authentication/jwt/jwt-storage.service';

interface IJwtTokenState {
    isRefreshing: boolean;
    refreshTokenSubject: BehaviorSubject<any>;
}

interface IJwtTokensState {
    pomToken: IJwtTokenState;
    jwtTokenV2: IJwtTokenState;
}

@Injectable()
export class JwtInterceptor implements HttpInterceptor {

    private jwtTokensState: IJwtTokensState;
    private isLoggedIn: boolean;

    constructor(
        private jwtHelperService: JwtHelperService,
        private jwtLifetimeProcessor: JwtLifetimeProcessor,
        private http: HttpClient,
        private store: Store<fromRoot.State>,
        private storageService: StorageService,
        private jwtStorageService: JwtStorageService,
        @Inject(JWT_OPTIONS) private jwtConfig: any
    ) {
        this.jwtTokensState = {
            pomToken: {
                isRefreshing: false,
                refreshTokenSubject: new BehaviorSubject<any>(null)
            },
            jwtTokenV2: {
                isRefreshing: false,
                refreshTokenSubject: new BehaviorSubject<any>(null)
            }
        };

        this.store.pipe(select(fromRoot.getIsLoggedIn)).subscribe(isLoggedIn => this.isLoggedIn = isLoggedIn);
    }

    public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        /**
         * If Authorization header has been set by Component which made request
         * Platform should not overwrite Auth header (component which makes request
         * has an option to avoid attaching Auth header by setting Authorization: 'none')
         */
        if (request.headers.get(jwtTokenRequestHeader) === 'none' || request.url.includes('odata')) {
            let authReq: HttpRequest<any>;

            authReq = request.clone({
                headers: request.headers.delete(jwtTokenRequestHeader)
            });

            return next.handle(authReq);
        }

        let isPomAuthErrorRetried = false;

        return this.checkToken(request, next).pipe(
            catchError(err => {
                if (err.status === 401 && err.url.includes('pom-bff') && !isPomAuthErrorRetried) {
                    isPomAuthErrorRetried = true;

                    this.jwtStorageService.delete(pomJwtTokenStorageKey);

                    return this.checkToken(request, next);
                }

                return throwError(err);
            })
        );
    }

    private checkToken(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        let token: string;

        /**
         * Do not check and re-fetch JWT once user is not logged in,
         * or for requests which do not require JWT
         */
        if (
            !this.isLoggedIn ||
            request.url.indexOf('JwtToken') !== -1 ||
            request.url.indexOf('deal-info') === -1 &&
            request.url.indexOf('orders-info') === -1 &&
            request.url.indexOf('bsp-muni-bff') === -1 &&
            request.url.indexOf('pom-bff') === -1 &&
            !this.isJwtTokenV2Needed(request.url) &&
            !/FixedIncomeDeal\/v2\/\d+$/.test(request.url) &&
            request.headers.get(jwtTokenRequestHeader) !== 'BSP-JWT'
        ) {
            return next.handle(request);
        }

        token = this.jwtConfig.tokenGetter(request);

        if (token && !this.jwtLifetimeProcessor.isTokenExpired(token)) {
            request = this.addToken(request, token);

            return next.handle(request);
        } else {
            return this.updateToken(request, next);
        }
    }

    private addToken(request: HttpRequest<any>, token: string): HttpRequest<any> {
        return request.clone({
            setHeaders: {
                Authorization: `Bearer ${token}`
            }
        });
    }

    private updateToken(request: HttpRequest<any>, next: HttpHandler): Observable<any> {
        const isJwtTokenV2 = this.isJwtTokenV2Needed(request.url);
        const tokenState = isJwtTokenV2
            ? this.jwtTokensState.jwtTokenV2
            : this.jwtTokensState.pomToken;

        if (!tokenState.isRefreshing) {
            tokenState.isRefreshing = true;
            tokenState.refreshTokenSubject.next(null);

            return this.http.post(isJwtTokenV2 ? API.tokens.jwtTokenV2 : API.tokens.jwtToken, null, { headers: this._headers }).pipe(
                switchMap((token: string) => {
                    tokenState.isRefreshing = false;
                    tokenState.refreshTokenSubject.next(token);

                    isJwtTokenV2
                        ? this.jwtStorageService.set(jwtTokenV2StorageKey, token)
                        : this.jwtStorageService.set(pomJwtTokenStorageKey, JSON.stringify(token));

                    return next.handle(this.addToken(request, token));
                }),
                catchError((error: HttpErrorResponse) => {
                    return of(error);
                }));
        } else {
            return tokenState.refreshTokenSubject.pipe(
                filter(token => token != null),
                take(1),
                switchMap(jwt => {
                    return next.handle(this.addToken(request, jwt));
                }));
        }
    }

    private get _headers(): HttpHeaders {
        return new HttpHeaders()
            .set(noErrorHandlingForHeader, '*');
    }

    private isJwtTokenV2Needed(url: string): boolean {
        return url.indexOf('equity-bff') !== -1
            || url.indexOf('client-logging') !== -1;
    }

}
