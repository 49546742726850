import { Action } from '@ngrx/store';
import { Country } from '@core/models/country.model';

export enum CountriesActionTypes {
    Update = '[Countries] Update'
}

export class Update implements Action {
    public readonly type = CountriesActionTypes.Update;

    constructor(public payload: Country[]) {}
}

export type CountriesActionsUnion =
    | Update;
