<form name="changePasswordForm" #changePasswordForm="ngForm" novalidate role="form" autocomplete="off">
    <div *ngIf="isModal" class="c-text-center c-m-bottom-md">
        <img src="{{ assetsURL }}assets/images/snp-global-logo.svg" width="80" class="modal-logo" />
        <h1 class="c-header-sm change-password__title">Change password</h1>
        <span class="c-text">Your password has expired. Please enter your current password, and
            create a new password.</span>
    </div>

    <app-change-password-warnings [warning]="warningType$ | async"></app-change-password-warnings>

    <div class="c-form-group">
        <div class="c-row">
            <div class="c-col">
                <label class="c-input-label c-text-bold">
                    Current Password:
                    <span class="c-text-danger">*</span>
                </label>
            </div>
        </div>
        <input type="password" name="currentPassword" autocomplete="new-password" [(ngModel)]="userData.currentPassword"
            #currentPassword="ngModel" class="c-input" (ngModelChange)="onCurrentPasswordChange($event)" [ngClass]="{
                'c-input-error':
                    currentPassword.invalid &&
                    (currentPassword.dirty || currentPassword.touched)
            }" required />
        <div *ngIf="
                currentPassword.invalid &&
                (currentPassword.dirty || currentPassword.touched)
            " class="change-password__validation--error">
            <span
                class="c-hint-danger change-password__validation--error change-password__validation__e2e--password-required"
                *ngIf="currentPassword.hasError('required')" [ngClass]="{
                    'c-hint': isModal,
                    'change-password__validation--settings-error': !isModal
                }">
                Current Password is required
            </span>
        </div>
    </div>
    <!-- /.c-form-group -->

    <div class="c-form-group" [ngClass]="{ 'c-m-bottom-md': !isModal }">
        <div class="c-row">
            <div class="c-col">
                <label class="c-input-label c-text-bold">
                    New Password:
                    <span class="c-text-danger">*</span>
                </label>
            </div>
        </div>
        <input type="password" name="newPassword" autocomplete="new-password" [(ngModel)]="userData.newPassword"
            #newPassword="ngModel" class="c-input" (ngModelChange)="onPasswordChange($event)" [ngClass]="{
                'c-input-error':
                    newPassword.invalid &&
                    (newPassword.dirty || newPassword.touched)
            }" required />
        <div *ngIf="
                newPassword.invalid &&
                (newPassword.dirty || newPassword.touched)
            " class="change-password__validation--error">
            <span
                class="c-hint c-hint-danger change-password__validation--error change-password__validation__e2e--new-password-required"
                *ngIf="newPassword.hasError('required')" [ngClass]="{
                    'c-hint': isModal,
                    'change-password__validation--settings-error': !isModal
                }">
                New Password is required
            </span>
            <span
                class="c-hint c-hint-danger change-password__validation--error change-password__validation__e2e--complexity-error"
                [ngClass]="{
                    'c-hint': isModal,
                    'change-password__validation--settings-error': !isModal
                }">
                <span
                    *ngIf="newPassword.hasError('PasswordCrieteriaNotFullfiled')">{{warningTypes.PasswordCrieteriaNotFullfiled}}</span>
                <span *ngIf="newPassword.hasError('PasswordRepeatedCharacters')">{{ warningTypes.PasswordRepeatedCharacters }}</span>
                <span *ngIf="newPassword.hasError('PasswordContainsUserName')">
                    {{ warningTypes.PasswordContainsUserName }}</span>
                <span *ngIf="newPassword.hasError('PasswordContainsUserIdOrEmail')">
                        {{ warningTypes.PasswordContainsUserIdOrEmail }}</span>
                <span *ngIf="newPassword.hasError('PasswordContainsRestrictedSpecialChar')">
                    {{ warningTypes.PasswordContainsRestrictedSpecialChar }}</span>
            </span>
            <span
                class="c-hint c-hint-danger change-password__validation--error change-password__validation__e2e--password-identity-error"
                *ngIf="newPassword.hasError('currentPasswordMatch')" [ngClass]="{
                    'c-hint': isModal,
                    'change-password__validation--settings-error': !isModal
                }">
                New password and Old password are the same
            </span>
        </div>
    </div>
    <!-- /.c-form-group -->
    <div class="c-form-group">
        <div class="c-row">
            <div class="c-col">
                <app-password-strength [passwordStrengthCriteria]="passwordStrengthCriteria"></app-password-strength>
            </div>
        </div>
    </div>
    <!-- /.c-form-group -->
    <div class="c-form-group">
        <div class="c-row">
            <div class="c-col">
                <label class="c-input-label c-text-bold">
                    Confirm New password:
                    <span class="c-text-danger">*</span>
                </label>
            </div>
        </div>
        <input type="password" name="confirmNewPassword" autocomplete="new-password"
            [(ngModel)]="userData.confirmNewPassword" #confirmNewPassword="ngModel" class="c-input"
            (ngModelChange)="onConfirmPasswordChange($event)" [ngClass]="{
                'c-input-error':
                    confirmNewPassword.invalid &&
                    (confirmNewPassword.dirty || confirmNewPassword.touched)
            }" required />
        <div *ngIf="
                confirmNewPassword.invalid &&
                (confirmNewPassword.dirty || confirmNewPassword.touched)
            " class="change-password__validation--error">
            <span
                class="c-hint c-hint-danger change-password__validation--error change-password__validation__e2e--confirmation-required"
                *ngIf="confirmNewPassword.hasError('required')" [ngClass]="{
                    'c-hint': isModal,
                    'change-password__validation--settings-error': !isModal
                }">
                Password confirmation is required
            </span>
            <span
                class="c-hint c-hint-danger change-password__validation--error change-password__validation__e2e--password-not-match"
                *ngIf="confirmNewPassword.hasError('notMatch')" [ngClass]="{
                    'c-hint': isModal,
                    'change-password__validation--settings-error': !isModal
                }">
                Password does not match
            </span>
        </div>
    </div>
    <!-- /.c-form-group -->
    <div class="c-form-group change-password__button-group" [ngClass]="{
            'change-password__button-group--user-settings': !isModal
        }">
        <button *ngIf="!(isLoading$ | async); else inProgress"
            class="c-btn c-btn-block c-btn-primary change-password__button"
            [ngClass]="{ 'change-password__button--user-settings': !isModal }" (click)="onSubmit()"
            [disabled]="changePasswordForm.invalid">
            Change Password
        </button>
        <ng-template #inProgress>
            <div class="c-progress c-progress-primary c-btn-primary change-password__button" [ngClass]="{
                    'change-password__button--user-settings': !isModal,
                    'c-m-bottom-0': !isModal
                }">
                <div class="c-progress-meter-animated change-password__button"></div>
            </div>
        </ng-template>
        <a *ngIf="!isModal" class="c-a" (click)="cancelPasswordChange()">Cancel</a>
    </div>
</form>
