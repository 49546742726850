import {Injectable} from '@angular/core';
import {HttpEvent, HttpInterceptor, HttpHandler, HttpRequest} from '@angular/common/http';
import {Observable} from 'rxjs/Observable';
import { ConfigurationService } from '@core/services/configuration.service';
import { isNullOrUndefined } from '@core/utils/utils';

@Injectable()
export class ApiInterceptor implements HttpInterceptor {

    constructor(private configService: ConfigurationService) {
    }

    public intercept(
        req: HttpRequest<any>,
        next: HttpHandler
    ): Observable<HttpEvent<any>> {
        if(this.shouldChangeHostUrl(req.url)){
            const apiReq = req.clone({ url: `${this.configService.hostUrl}/${req.url}` });
            return next.handle(apiReq);
        }else{
            return next.handle(req);
        }
    }

    private shouldChangeHostUrl(requestUrl: string): boolean {
        return !isNullOrUndefined(this.configService.hostUrl)
            && !requestUrl.startsWith('http')
            && !requestUrl.includes('version.json')
    }
}
