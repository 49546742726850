import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';

import { AutoUnsubscribe } from 'ngx-auto-unsubscribe';
import { NotificationDisplayOption } from '../../models/notification-options.model';

@AutoUnsubscribe()
@Component({
    selector: 'app-notification-selector',
    templateUrl: './notification-selector.component.html'
})
export class NotificationSelectorComponent implements OnInit, OnDestroy {

    @Input() public options: NotificationDisplayOption;
    @Input() public value: string;
    @Input() public disabled: boolean;

    @Output() public valueChange = new EventEmitter();

    constructor() {}

    public ngOnInit(): void {}

    public onChange(event: Event): void {
        const value = (event.target as HTMLInputElement).value;
        this.valueChange.emit(value);
    }

    public ngOnDestroy(): void {}
}
