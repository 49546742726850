import { CountriesActions } from '../../actions';
import { Country } from '@core/models/country.model';

export interface State {
    countries: Country[];
}

export const initialState: State = {
    countries: []
};

export function countriesReducer(
    state: State = initialState,
    action: CountriesActions.CountriesActionsUnion
): State {
    switch (action.type) {
        case CountriesActions.CountriesActionTypes.Update:
            return {
                countries: action.payload
            };

        default:
            return state;
    }
}
