import { Component, Input, OnDestroy } from '@angular/core';
import { AutoUnsubscribe } from 'ngx-auto-unsubscribe';
import { PasswordCriteria, PasswordStrengthMessage } from '../../../shared/models/password.criteria.model';

@AutoUnsubscribe()
@Component({
  selector: 'app-password-strength',
  templateUrl: './password-strength.component.html',
  styleUrls: ['./password-strength.component.scss']
})
export class PasswordStrengthComponent implements OnDestroy {

    @Input() public passwordStrengthCriteria: PasswordCriteria;
    public passwordStrengthMsg = PasswordStrengthMessage;

    constructor() {}
    public ngOnDestroy(): void {}
}
