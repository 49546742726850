import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { CoreComponentsModule } from '@core/core-components/core-components.module';
import { SharedModule } from '../shared/shared.module';
import { PoliciesRoutingModule } from './policies.routing.module';
import { AcceptTermsComponent } from './components/accept-terms/accept-terms.component';
import { DisclaimersComponent } from './components/disclaimers/disclaimers.component';
import { RegulatoryDisclosuresComponent } from './components/regulatory-disclosures/regulatory-disclosures.component';

 @NgModule({
    imports: [
        CommonModule,
        FormsModule,
        PoliciesRoutingModule,
        SharedModule,
        CoreComponentsModule
    ],
    declarations: [
        AcceptTermsComponent,
        DisclaimersComponent,
        RegulatoryDisclosuresComponent
    ]
})
export class PoliciesModule {}
