import { MuniUserSettingsActions } from '../../actions';
import { MuniUserSettingsHelper } from '@core/user-settings/muni-user-settings.helper';
import { MuniUserSettings } from '@core/user-settings/muni-user-settings.model';

export type State = MuniUserSettings;

export const initialState: State = null;

export function muniUserSettingsReducer(
    state: State = initialState,
    action: MuniUserSettingsActions.MuniSettingsActionsUnion
): State {
    switch (action.type) {
        case MuniUserSettingsActions.MuniSettingsActionTypes.LoadSuccess:
            return MuniUserSettingsHelper.buildMuniSettings(action.payload.muniSettings, action.payload.hasMmdDataPermission);

        case MuniUserSettingsActions.MuniSettingsActionTypes.Update:
            return {
                ...state,
                settings: MuniUserSettingsHelper.updateMuniSettings(state.settings, action.payload)
            };

        default:
            return state;
    }
}
