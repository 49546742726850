import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { AuthService } from '../../authentication/auth.service';
import { Router } from '@angular/router';
import { AuthApiActions } from '../actions';
import { catchError, first, map, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { Store, select } from '@ngrx/store';
import * as fromRoot from '../../../reducers';
import { RoutingHelperService } from '@core/services/routing-helper.service';
import { of } from 'rxjs';
import { PendoService } from '../../services/pendo.service';

@Injectable()
export class ConfirmProfileEffect {

    constructor(
        private actions$: Actions,
        private authService: AuthService,
        private router: Router,
        private store: Store<fromRoot.State>,
        private pendoService: PendoService
    ) {}


    public profileConfirmation$ = createEffect(() => this.actions$.pipe(
        ofType(AuthApiActions.AuthApiActionTypes.ConfirmProfile),
        switchMap((action: any) => this.authService.confirmProfile(action.payload)
            .pipe(
                map((res) => new AuthApiActions.ConfirmProfileSuccess(res)),
                catchError((error: any) => of(new AuthApiActions.ConfirmProfileFailed(error)))
            )
        )
    ));


    public profileConfirmationSuccess$ = createEffect(() => this.actions$.pipe(
        ofType(
            AuthApiActions.AuthApiActionTypes.ConfirmProfileSuccess
        ),
        tap(() => {
            this.pendoService.trackEvent('bsp-profile-confirmed');
        }),
        switchMap(() => this.store.pipe(
            select(fromRoot.getPermissionsState),
            withLatestFrom(this.store.pipe(select(fromRoot.getHomePage))),
            first()
        )),
        tap(([permissions, homePage]) => {
            const allowedHomePage = RoutingHelperService.getHomePageRoute(permissions, homePage);

            this.router.navigate([allowedHomePage]);
        })
    ), { dispatch: false });
}
