import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { of } from 'rxjs';
import {
    concatMap,
    switchMap,
    map,
    catchError,
    withLatestFrom,
} from 'rxjs/operators';
import { getConfigStoreState, State } from '../reducer';
import { AlertsService } from '../../services/alerts.service';
import {
    PreferencesActions,
    PreferencesLoadAction,
    PreferencesLoadFailAction,
    PreferencesLoadSuccessAction,
    PreferencesUpdateAction,
    PreferencesUpdateFailAction,
} from './actions';
import { MessagesLoadAction } from '../messages/actions';
import { MessagesStatusLoadAction } from '../messages-status/actions';
import { MESSAGE_LOAD_LIMIT } from '../../tokens';

@Injectable()
export class PreferencesEffects {
    public loadPreferences$ = createEffect(() =>
        this.actions$.pipe(
            ofType(PreferencesActions.Load),
            withLatestFrom(this.store.pipe(select(getConfigStoreState))),
            concatMap(([, config]) => {
                return this.alertsService.getPreferences(config).pipe(
                    map((payload) => new PreferencesLoadSuccessAction(payload)),
                    catchError((error) => of(new PreferencesLoadFailAction(error)))
                );
            })
        )
    );

    public preferenceUpdate$ = createEffect(() =>
        this.actions$.pipe(
            ofType(PreferencesActions.Update),
            withLatestFrom(this.store.pipe(select(getConfigStoreState))),
            concatMap(([action, config]) => {
                const payload = (action as PreferencesUpdateAction).payload;
                return this.alertsService.setPreference(payload, config).pipe(
                    switchMap(() => [
                        new PreferencesLoadAction(),
                        new MessagesStatusLoadAction(),
                        new MessagesLoadAction({ from: MESSAGE_LOAD_LIMIT }),
                    ]),
                    catchError((error) =>
                        of(new PreferencesUpdateFailAction({ request: payload, error }))
                    )
                );
            })
        )
    );

    constructor(
        private actions$: Actions,
        private alertsService: AlertsService,
        private store: Store<State>
    ) {
    }
}
