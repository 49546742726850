/* eslint-disable max-len */
import { NumberFormat } from './user-settings.model';

export class UserSettingsOptions {

    public static get USER_SETTINGS_OPTIONS(): any {
        return {
            defaultLanguage: 'en-US',

            languages: {
                EnUS: 'en-US'
            },

            currencies: {
                USD: { name: 'USD ($)', exrate: 1.000, CURRENCY_SYM: '$' }
            },

            numberFormats: {
                Format0: <NumberFormat>{
                    name: '1,000,000.99',
                    key: 'Format0',
                    format: {
                        DECIMAL_SEP: '.',
                        GROUP_SEP: ',',
                        PATTERNS: [
                            {
                                gSize: 3,
                                lgSize: 3,
                                maxFrac: 3,
                                minFrac: 0,
                                minInt: 1,
                                negPre: '-',
                                negSuf: '',
                                posPre: '',
                                posSuf: ''
                            },
                            {
                                gSize: 3,
                                lgSize: 3,
                                maxFrac: 2,
                                minFrac: 2,
                                minInt: 1,
                                negPre: '\u00a4-',
                                negSuf: '',
                                posPre: '\u00a4',
                                posSuf: ''
                            }
                        ]
                    },
                    BigNumberFormat: {
                        decimalSeparator: '.',
                        groupSeparator: ',',
                        groupSize: 3
                    }
                },
                Format1: <NumberFormat>{
                    name: '1 000 000,99',
                    key: 'Format1',
                    format: {
                        DECIMAL_SEP: ',',
                        GROUP_SEP: '\u00a0',
                        PATTERNS: [
                            {
                                gSize: 3,
                                lgSize: 3,
                                maxFrac: 3,
                                minFrac: 0,
                                minInt: 1,
                                negPre: '-',
                                negSuf: '',
                                posPre: '',
                                posSuf: ''
                            },
                            {
                                gSize: 3,
                                lgSize: 3,
                                maxFrac: 2,
                                minFrac: 2,
                                minInt: 1,
                                negPre: '-',
                                negSuf: '\u00a0\u00a4',
                                posPre: '',
                                posSuf: '\u00a0\u00a4'
                            }
                        ]
                    },
                    BigNumberFormat: {
                        decimalSeparator: ',',
                        groupSeparator: '\u00a0',
                        groupSize: 3
                    }
                },
                Format2: <NumberFormat>{
                    name: '1000000,99',
                    key: 'Format2',
                    format: {
                        DECIMAL_SEP: ',',
                        GROUP_SEP: '',
                        PATTERNS: [
                            {
                                gSize: 3,
                                lgSize: 3,
                                maxFrac: 3,
                                minFrac: 0,
                                minInt: 1,
                                negPre: '-',
                                negSuf: '',
                                posPre: '',
                                posSuf: ''
                            },
                            {
                                gSize: 3,
                                lgSize: 3,
                                maxFrac: 2,
                                minFrac: 2,
                                minInt: 1,
                                negPre: '-',
                                negSuf: '\u00a0\u00a4',
                                posPre: '',
                                posSuf: '\u00a0\u00a4'
                            }
                        ]
                    },
                    BigNumberFormat: {
                        decimalSeparator: ',',
                        groupSeparator: '',
                        groupSize: 3
                    }
                }
            },
            dateFormats: {
                // http://docs.telerik.com/kendo-ui/framework/globalization/dateformatting
                Format0: { key: 'Format0', example: '10 Jan 15', format: 'dd MMM yy', kendoFormat: 'dd MMM yy', momentFormat: 'DD MMM YY' },
                Format1: { key: 'Format1', example: '10 Jan 2015', format: 'dd MMM yyyy', kendoFormat: 'dd MMM yyyy', momentFormat: 'DD MMM YYYY' },
                Format2: { key: 'Format2', example: '10 January 2015', format: 'dd MMMM yyyy', kendoFormat: 'dd MMMM yyyy', momentFormat: 'DD MMMM YYYY' },
                Format3: { key: 'Format3', example: '01/10/15', format: 'MM/dd/yy', kendoFormat: 'MM/dd/yy', momentFormat: 'MM/DD/YY' },
                Format4: { key: 'Format4', example: '10/01/15', format: 'dd/MM/yy', kendoFormat: 'dd/MM/yy', momentFormat: 'DD/MM/YY' },
                Format5: { key: 'Format5', example: '10.01.15', format: 'dd.MM.yy', kendoFormat: 'dd.MM.yy', momentFormat: 'DD.MM.YY' },
                Format6: { key: 'Format6', example: '01/10/2015', format: 'MM/dd/yyyy', kendoFormat: 'MM/dd/yyyy', momentFormat: 'MM/DD/YYYY' },
                Format7: { key: 'Format7', example: '10/01/2015', format: 'dd/MM/yyyy', kendoFormat: 'dd/MM/yyyy', momentFormat: 'DD/MM/YYYY' },
                Format8: { key: 'Format8', example: '10.01.2015', format: 'dd.MM.yyyy', kendoFormat: 'dd.MM.yyyy', momentFormat: 'DD.MM.YYYY' }
            },
            timeFormats: {
                Format0: { key: 'Format0', example: '18:00', format: 'HH:mm', kendoFormat: 'HH:mm', momentFormat: 'HH:mm' },
                Format1: { key: 'Format1', example: '12:00 PM', format: 'hh:mm a', kendoFormat: 'hh:mm tt', momentFormat: 'hh:mm A' },
                Format2: { key: 'Format2', example: '18:00:00', format: 'HH:mm:ss', kendoFormat: 'HH:mm:ss', momentFormat: 'HH:mm:ss' },
                Format3: { key: 'Format3', example: '12:00:00 PM', format: 'hh:mm:ss a', kendoFormat: 'hh:mm:ss tt', momentFormat: 'hh:mm:ss A' }
            },

            dateTranslations: {
                'en-US': {
                    AMPMS: ['AM', 'PM'],
                    DAY: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],
                    MONTH: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
                    SHORTDAY: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
                    SHORTMONTH: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
                },
                'pl-PL': {
                    AMPMS: ['AM', 'PM'],
                    DAY: ['niedziela', 'poniedzia\u0142ek', 'wtorek', '\u015broda', 'czwartek', 'pi\u0105tek', 'sobota'],
                    MONTH: ['stycznia', 'lutego', 'marca', 'kwietnia', 'maja', 'czerwca', 'lipca', 'sierpnia', 'wrze\u015bnia', 'pa\u017adziernika', 'listopada', 'grudnia'],
                    SHORTDAY: ['niedz.', 'pon.', 'wt.', '\u015br.', 'czw.', 'pt.', 'sob.'],
                    SHORTMONTH: ['sty', 'lut', 'mar', 'kwi', 'maj', 'cze', 'lip', 'sie', 'wrz', 'pa\u017a', 'lis', 'gru']
                }
            }
        };
    }

}
