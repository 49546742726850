import { Permissions } from '@core/permissions/permissions.model';
import { PermissionsActions } from '../../actions';

export type State = Permissions;

export const initialState: State = {
    canViewAccountX: false,
    canViewChinaBondDeals: false,
    canViewAdminTab: false,
    canViewFixedIncomeDealMonitor: false,
    canViewEquityDealMonitor: false,
    canViewMunicipalDealMonitor: false,
    canViewMunicipalStreetCalendar: false,
    hasMunicipalMmdData: false,
    canViewDealQuery: false,
    canViewActivityStream: false,
    canViewCompliance: false,
    canViewEquityDealQuery: false,
    canViewEquityPublicDeals: false,
    canViewOrders: false,
    canViewEquityOrders: false,
    canViewOrdersForCompanies: [],
    canEditOrdersForCompanies: [],
    canViewEquityOrdersForCompanies: [],
    canViewTrancheSizeLimitOrder: false,
    canViewAnalytics: false,
    primaryOrderManagement: false,
    manualOrdersOnly: false,
    pomInternalOrderEntry: false,
    pomAggregatedOrderManagement: false,
    internalDealCreation: false,
    releaseFixAllocations: false,
    internalCommentsAndAlertingOrionPermission: false,
    companiesWithPOMEnabled: [],
    thinkFolio: false,
    distributionOrders: false,
    canViewDistributionOrdersForCompanies: [],
    underwritingOrders: false,
    canViewUnderwritingOrdersForCompanies: [],
    operationalEntities: false,
    companiesWithInternalDealCreatePermission: [],
    companiesWithTrancheSizeLimitPermission: [],
    companiesWithReleaseFixExecutionsFirm: [],
    companiesWithReleaseFixExecutionsFund: [],
    softFirmOrders: false,
    companiesWithThirdPartyIntegration: [],
    companiesWithOperationalEntitiesPermission: [],
    canViewEquityAnalytics : false
};

export function permissionsReducer(
    state: State = initialState,
    action: PermissionsActions.PermissionsActionsUnion
): State {
    switch (action.type) {
        case PermissionsActions.PermissionsActionTypes.Update:
            return {
                ...action.payload.permissions,
            };
        case PermissionsActions.PermissionsActionTypes.Clear:
            return initialState;
        default:
            return state;
    }
}
