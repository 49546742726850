import { ChangeDetectionStrategy, Component, OnInit, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { Store } from '@ngrx/store';
import { combineLatest, Observable, Subscription } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { AutoUnsubscribe } from 'ngx-auto-unsubscribe';

import * as fromRoot from '../../../../reducers';
import * as fromLayout from '@core/@state/reducers/layout.reducer';
import { Permissions } from '@core/permissions/permissions.model';
import { AssetClassModel, AssetPage, ASSET_CLASSES } from '@core/models/asset-class';
import { RouterLinks, RoutingHelperService } from '@core/services/routing-helper.service';
import { BspConfiguration } from '@core/configuration/bsp-configuration';
import { NavigationStart, Router} from '@angular/router';

@AutoUnsubscribe()
@Component({
    selector: 'app-header-tabs',
    templateUrl: './header-tabs.component.html',
    styleUrls: ['./header-tabs.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class HeaderTabsComponent implements OnInit, OnDestroy {
    private currentAssetClassSubscription: Subscription;
    private userContainerIdSubscription: Subscription;
    private userContainerId: string;

    public currentAssetClass: AssetClassModel;
    public currentRoute = '';
    public routes: RouterLinks;
    public assetPages$: Observable<AssetPage[]>;
    public permissions$: Observable<Permissions>;

    constructor(
        private store: Store<fromRoot.State>,
        private router: Router,
        private changeDetectorRef: ChangeDetectorRef
    ) {}

    public ngOnInit(): void {
        this.permissions$ = this.store.select(fromRoot.getPermissionsState);
        this.routes = RoutingHelperService.ROUTER_LINKS;

        this.assetPages$ = combineLatest([
            this.store.select(fromLayout.getCurrentAssetClass),
            this.store.select(fromRoot.getPermissionsState),
            this.store.select(fromRoot.getConfigurationState)
        ]).pipe(
            map(([currentAssesClass, permissions, configuration]) => this.getAssetPages(currentAssesClass, permissions, configuration))
        );

        this.currentAssetClassSubscription = this.store.select(fromLayout.getCurrentAssetClass)
            .subscribe(currentAssesClass => this.currentAssetClass = currentAssesClass);

        this.userContainerIdSubscription = this.store.select(fromRoot.getUserSettingsState)
            .subscribe(settings => this.userContainerId = settings.containers[0].id);

        this.currentRoute = this.router.url;
        this.router.events.pipe(
            filter(event => event instanceof NavigationStart)
        ).subscribe((state: NavigationStart) => {
            this.currentRoute = state.url;

            this.changeDetectorRef.detectChanges();
        });
    }

    public doNavigate(route: string): void {
        if (route === '/admin' || route === '/company-connections') {
            this.router.navigateByUrl(`/admin/${this.userContainerId}/companies`);

            return;
        }

        this.router.navigateByUrl(route);
    }

    public isRouterLinkActive(assetPageRoute: string): boolean {
        if (this.currentRoute === '/fixedincome/analytics') {
            return assetPageRoute.includes('/analytics');
        }

        if (this.currentRoute === '/equtiy/analytics') {
            return assetPageRoute.includes('/analytics');
        }

        return this.currentRoute.includes(assetPageRoute);
    }

    public ngOnDestroy(): void {}

    private getAssetPages(assetModel: AssetClassModel, permissions: Permissions, configuration: BspConfiguration): AssetPage[] {
        if (assetModel === null) {
            return [];
        }

        return [...ASSET_CLASSES].find(ac => ac.model === assetModel)
            .assetPages
            .filter(c => c.isPermissioned(permissions, configuration))
            .map((item) => {
                if (item.route === '/admin' || item.route === '/company-connections') { item.route = `/admin/${this.userContainerId}/companies`; }         return item;
        });
    }
}
