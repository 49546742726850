<div class="c-alert"
     [class.c-alert-danger]="type === AlertType.Error"
     [class.c-alert-warning]="type === AlertType.Warning"
     [class.c-alert-primary]="type === AlertType.Info">
    <i class="fa c-alert-icon" aria-hidden="true" [ngClass]="type === AlertType.Warning ? 'fa-exclamation-triangle' : 'fa-exclamation-circle'"></i>
    <div class="c-alert-content">
        <strong>
            <ng-content></ng-content>
        </strong>
    </div>
</div>
