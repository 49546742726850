import { Injectable } from '@angular/core';

@Injectable()
export class JwtStorageService {
    private tokens: { [key: string]: string } = {};

    public set(key: string, token: string): void {
        this.tokens[key] = token;
    }

    public get(key: string): string {
        if (!this.tokens[key]) {
            return null;
        }

        return this.tokens[key];
    }

    public delete(key): void {
        delete this.tokens[key];
    }
}
