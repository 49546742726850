import { OnDestroy, Injectable } from '@angular/core';
import { StorageService } from '@core/services/storage.service';
import { CacheItem } from './cache-service/cache-item';

const moment = require('moment');

const maxStorageSize = 10000;
const maxItemAgeInDays = 7;
const storageKeyPrefix = 'acknowledgment.storage';

@Injectable()
export class AcksStorageService {
    constructor(private storageService: StorageService) {
        this.initCurrentItems();
    }

    private initCurrentItems(): void {
        if (this.currentItems !== null && this.currentItems.length > 0) {
            this.cleanUpOutdatedRecords();
        } else {
            this.currentItems = [];
        }
    }

    private cleanUpOutdatedRecords(): void {
        const nowDate = moment();
        this.currentItems = this.currentItems.filter(i => this.isRecordOutdated(i.createdDateTime, nowDate));
    }

    private isRecordOutdated(recordDate: Date, nowDate: any): boolean {
        const recordDateMoment = moment(recordDate);
        return nowDate.diff(recordDateMoment, 'days') <= maxItemAgeInDays;
    }

    private set currentItems(items: CacheItem[]) {
        this.storageService.setObject(storageKeyPrefix, items);
    }

    private get currentItems(): CacheItem[] {
        return this.storageService.getObject<CacheItem[]>(storageKeyPrefix);
    }


    public isValueInCache(key: string): boolean {
        return this.currentItems.find(i => i.value === key) !== undefined;
    }

    public push(key: string): void {
        const cacheItem: CacheItem = {
            value: key,
            createdDateTime: new Date()
        };

        this.saveToStorage(cacheItem);
    }

    private saveToStorage(item: CacheItem): void {
        const currentItems = this.currentItems;
        const newLength = currentItems.push(item);

        if (newLength > maxStorageSize) {
            currentItems.splice(0, 1);
        }

        this.currentItems = currentItems;
    }
}
