import { Action } from '@ngrx/store';
import {
    UserPhonesUpdateModel,
    UserProfileUpdateModel
} from '../../../modules/user-profile/models/user-profile.model';
import { UserProfile } from '@core/user-settings/user-settings.model';

export enum UserProfileActionTypes {
    UpdateUserProfile      = '[UserProfile] Update user profile',
    UpdateProfileSuccess   = '[UserProfile] Update profile success',
    UpdateProfileFailed    = '[UserProfile] Update profile failed',
    UpdateUserEmails       = '[UserProfile] Update user emails',
    UpdateUserPhoneNumbers = '[UserProfile] Update user phone numbers',

    ResetUserProfileState  = '[UserProfile] Reset state'
}

export class UpdateUserProfile implements Action {
    public readonly type = UserProfileActionTypes.UpdateUserProfile;

    constructor(public payload: UserProfileUpdateModel) {}
}

export class UpdateProfileSuccess implements Action {
    public readonly type = UserProfileActionTypes.UpdateProfileSuccess;

    constructor(public payload: UserProfile) {}
}

export class UpdateProfileFailed implements Action {
    public readonly type = UserProfileActionTypes.UpdateProfileFailed;

    constructor(public payload: { errorType: string }) {}
}

export class UpdateUserEmails implements Action {
    public readonly type = UserProfileActionTypes.UpdateUserEmails;

    constructor(public payload: { Email: string; }) {}
}

export class UpdateUserPhoneNumbers implements Action {
    public readonly type = UserProfileActionTypes.UpdateUserPhoneNumbers;

    constructor(public payload: UserPhonesUpdateModel) {}
}

export class ResetUserProfileState implements Action {
    public readonly type = UserProfileActionTypes.ResetUserProfileState;
}

export type UserProfileActionsUnion =
    | UpdateUserProfile
    | UpdateUserEmails
    | UpdateUserPhoneNumbers
    | UpdateProfileSuccess
    | UpdateProfileFailed
    | ResetUserProfileState;

