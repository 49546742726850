import { UserSettingsHttpModel } from './user-settings-http.model';
import { LoadingStatus } from '@core/models/loading-status.model';

export interface FixedincomeUserSettingsModel {
    httpModel: UserSettingsHttpModel;
    operationalEntity: OperationEntityModel;
}

export interface OperationEntityModel {
    httpModel: OperationalEntityHttpModel[];
    loadingStatus: LoadingStatus;
}

export interface OperationalEntityHttpModel {
    companyId: number;
    companyName: string;
    userOperationalEntities: UserOperationalEntity[];
}

export enum OperationalEntityUserPermission {
    Trade = 'trade',
    View = 'view'
}

export interface UserOperationalEntity {
    id: string;
    name: string;
    permission: OperationalEntityUserPermission;
}
