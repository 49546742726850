<app-modal *ngIf="showIdlePopup"
            [header]="'Session Timeout Warning'">
    <p>This session has been inactive for {{sessionNotifyTimeout}} minutes.</p>
    <p>If there is no activity, the session will automatically end in seconds</p>
</app-modal>
<div *ngIf="showIdlePopup" class="c-modal-backdrop c-modal-fade c-modal-in" style="display: block;"></div>

<div class="app-wrapper">
    <app-header [pomToken$]="pomToken$" *ngIf="isAuthPassed"></app-header>

    <app-disconnect-alert *ngIf="showDisconnectAlert$ | async"></app-disconnect-alert>

    <div *ngIf="isNavigating$ | async" class="c-loader-full-page-backdrop c-loader-visible">
        <div class="c-full-page-loader"></div>
    </div>
    <main *ngIf="!(isNavigating$ | async)"
          [ngClass]="{
            'app-wrapper__content': isAuthPassed,
            'app-wrapper__content--disconnect-alert': isAuthPassed && (showDisconnectAlert$ | async)
          }"
          class="app-wrapper__content c-wrapper fix-ie11-flexbox">
        <router-outlet></router-outlet>
    </main>
    <app-footer *ngIf="isAuthPassed"></app-footer>
</div>
