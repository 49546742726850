import { ChangePasswordComponent } from './components/change-password/change-password.component';
import { ChangePasswordFormComponent } from './components/change-password-form/change-password-form.component';
import { ChangePasswordWarningsComponent } from './components/change-password-warnings/change-password-warnings.component';
import { ClickOutsideDirective } from './directives/click-outside.directive';
import { CommonModule } from '@angular/common';
import { CoreComponentsModule } from '@core/core-components/core-components.module';
import { FormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';
import { NotificationSelectorComponent } from './components/notification-selector/notification-selector.component';
import { PasswordCriteriaComponent } from './components/password-strength/password-criteria/password-criteria.component';
import { PasswordStrengthComponent } from './components/password-strength/password-strength.component';
import { ValidationService } from './services/validation.service';
import { LandingPageComponent } from './components/landing-page/landing-page.component';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        CoreComponentsModule,
    ],
    declarations: [
        PasswordStrengthComponent,
        PasswordCriteriaComponent,
        ChangePasswordComponent,
        ChangePasswordWarningsComponent,
        ChangePasswordFormComponent,
        ClickOutsideDirective,
        NotificationSelectorComponent,
        LandingPageComponent
    ],
    providers: [ ValidationService ],
    exports: [
        PasswordStrengthComponent,
        ChangePasswordFormComponent,
        ChangePasswordComponent,
        ClickOutsideDirective,
        NotificationSelectorComponent,
        LandingPageComponent
    ]
})

export class SharedModule { }
